import React , { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// MATERIAL ICONS
import DownloadIcon from "@material-ui/icons/CloudDownloadRounded";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import { downloadFileAPI } from "./../../APIs/ApiUtils";

export default function CustomUploadButton(props) {
  const [isDownloading, setDownloading] = useState(false);



  async function handleFileDownload() {
    setDownloading(true);
    let param = {
      filePath: props.state[props.paramValue + "Value"],
      fileName: props.fileName,
    };
    console.log("<INFO> Following Param for Download File API",param);
    await downloadFileAPI(param);

    setDownloading(false);
  }

  return (
    <FormControl
      fullwidth
      required={props.required}
      error={props.state[props.paramValue + "ErrorStatus"]}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Button
          onClick={() => props.handleFileOpen(props.paramValue)}
          startIcon={
            <UploadIcon
              fontSize="large"
              color={
                props.state[props.paramValue + "Value"]
                  ? "primary"
                  : "secondary"
              }
            />
          }
        >
          Upload
        </Button>

        {props.showDownload != undefined &&
          props.state[props.paramValue + "Value"] && (
            <div>
              {isDownloading ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  onClick={handleFileDownload}
                  startIcon={
                    <DownloadIcon
                      fontSize="large"
                      color={
                        props.state[props.paramValue + "Value"]
                          ? "primary"
                          : "secondary"
                      }
                    />
                  }
                >
                  Download
                </Button>
              )}
            </div>
          )}
      </Grid>

      <FormHelperText>
        {props.state[props.paramValue + "ErrorStatus"]
          ? props.state[props.paramValue + "ErrorMsg"]
          : ""}
      </FormHelperText>
      <DropzoneDialog
        open={props.state[props.paramValue + "MediaOpen"]}
        onSave={(files) => props.handleFileSave(props.paramValue, files)}
        filesLimit={
          props.maxFileNo != undefined ? props.maxFileNo : 1
        }
        acceptedFiles={props.acceptedFiles}
        showPreviews={true}
        maxFileSize={
          props.maxFileLimit != undefined ? props.maxFileLimit : "104837600"
        }
        onClose={() => props.handleFileClose(props.paramValue)}
      />
    </FormControl>
  );
}
