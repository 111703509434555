import React, { Fragment, Component } from "react";
import themeAPP from "../../themes";

// import needed components, functions and styles
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { Button, Grid } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import {
  deleteInvestorAPI,
  getInvestorsAPI,
  getInvestorsPaginationAPI,
} from "../../APIs/investorApis";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Trans } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default class Investors extends Component {
  constructor(props) {
    super(props);
    this.state = {
          investorsTable: [],loader: false,deleteDialog: false,isRecent: true,permissions: undefined,roles: undefined,
    };
  }

  render() {
    const components = {
      icons: { SearchIcon,PrintIcon,DownloadIcon,ViewColumnIcon,FilterIcon,},
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid xs={12} container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>Investors.pageTitle</Trans>}
                  button={
                    <div>
                      <Button variant="outlined" color="secondary" onClick={() => { this.state.isRecent ? this.handleGetInvestors() : this.handleGetInvestorsPagination(); }} >
                        {this.state.isRecent ? (<Trans>Get All</Trans>) : (<Trans>Get Recent</Trans>)}
                      </Button>
                      { /*(this.state.permissions != undefined && this.state.permissions.includes("investor_create")) &&*/ (
                        <Button style={{ marginLeft: "10px" }} variant="outlined" color="secondary" onClick={() => { this.props.history.push("/app/addinvestor"); }} >
                          <Trans>Investors.submitBtn</Trans>
                        </Button>
                      )}
                    </div> } />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "space-between" }} >
                <MuiThemeProvider theme={themeAPP.default}>
                  <MUIDataTable title={ this.state.isRecent ? "Recent Added Investors" : "All Investors" } data={this.state.investorsTable}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "profilethumbnail",
                        label: "Profile",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <Avatar src={tableMeta.rowData[1]}>
                                {/* <AssignmentIcon /> */}
                              </Avatar>
                            );
                          },
                        },
                      },
                      {
                        name:"investortype", label:"Investor Type",

                      },
                      {
                        name:"investorname", label:"Name",      

                      },
                      {
                        name:"investoremail", label:"Email",      

                      },
                      {
                        name:"investoractivated", label:"Activated",      

                      },
                      {
                        name:"investorccompany", label:"Company Name",      

                      },
                      {
                        name: "Actions",
                        options: {
                          filter: true,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRender(
                              value,
                              tableMeta,
                              updateValue,
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      tableBodyMaxHeight: "400px",
                      selectableRows: false,
                      filter: !this.state.isRecent,
                      print: !this.state.isRecent,
                      download: !this.state.isRecent,
                      elevation: 0,
                      fixedHeader: true,
                      viewColumns: !this.state.isRecent,
                      filterType: "dropdown",
                      responsive: "standard",
                      expandableRows: false,
                    }}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    let userRole = localStorage.getItem("user_role");
    var userRoleArr = userRole.split(",");
    let userPermission = localStorage.getItem("user_permission");
    var userPermissionArr = userPermission.split(",");
    this.setState({ permissions: userPermissionArr, roles: userRoleArr });

    this.handleGetInvestorsPagination();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetInvestorsPagination = async () => {
    let RC = false;
    this.setState({ loader: true, investorsTable: [] });
    let page = 1;
    let limit = 10;

    let investors = await getInvestorsPaginationAPI(limit, page);
    if (investors != undefined && investors.length > 0) {
      console.log("Investors Found Successfully",investors);
      this.setState({
        investorsTable: investors,
        loader: false,
        isRecent: true,
      });
    } else {
      console.log("Investor Not found");
      this.setState({ loader: false });
    }
  };

  handleGetInvestors = async () => {
    let RC = false;
    this.setState({ loader: true, investorsTable: [] });

    let investors = await getInvestorsAPI();
    if (investors != undefined && investors.length > 0) {
      console.log("Investors Found Successfully");
      this.setState({
        investorsTable: investors,
        loader: false,
        isRecent: false,
      });
    } else {
      console.log("Investor Not found");
      this.setState({ loader: false });
    }
  };

  deleteInvestor = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleClose();
    let rc = await deleteInvestorAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("Investor Deleted Successfully");
      this.handleGetInvestors();
      toast.success("Investor Deleted");
    } else console.log("Investor Not Deleted");
  };

  editInvestor = (value, tableMeta, updateValue) => {    
    this.props.history.push("/app/editinvestor/"+tableMeta.rowData[0]);
  };

  viewInvestor = (value, tableMeta, updateValue) => {    
    this.props.history.push("/app/investor/"+tableMeta.rowData[0]);
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <div style={{ display: "inline-flex", margin: "0px auto" }}>
        { /* this.state.permissions.includes("investor_view") && */ (
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => this.viewInvestor(value, tableMeta, updateValue)}
            style={themeAPP.default.button}
          >
            <Tooltip title="View Investor" aria-label="add">
              <Visibility />
            </Tooltip>
          </IconButton>
        )}
        { /* this.state.permissions.includes("investor_update") && */ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="upload picture"
            onClick={() => this.editInvestor(value, tableMeta, updateValue)}
          >
            <Tooltip title="Edit Investor" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        { /* this.state.permissions.includes("investor_delete") && */ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="Delete Button"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete "' +
                  tableMeta.rowData[1] +
                  " " +
                  tableMeta.rowData[2] +
                  '"',
              );
              if (retVal == true) {
                this.deleteInvestor(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          >
            <Tooltip title="Delete Current Investor" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
        <Dialog
          open={this.state.deleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting Investor
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {"Are You Sure to Delete" +
                tableMeta.rowData[1] +
                " " +
                tableMeta.rowData[2]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.deleteInvestor(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}
