import React, { Fragment,useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,useParams,
  useHistory,
} from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

import { Typography, Paper, Grid, Button, Divider,FormControl,TextField,InputLabel,Select,MenuItem,FormHelperText} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// MATERIAL UI COMPONENTS  ====================================================================
import CircularProgress from "@material-ui/core/CircularProgress";

// FOR APIS  ====================================================================
import {
  getBlogsAPI,
  getBlogAPI,
  createBlogAPI,
  editBlogAPI,
} from "../../APIs/blogApis";

import {
	getBlogCategoriesAPI
} from "../../APIs/blogcategoryApis";

import {
  isErrorExist,
  getAge,
  getImageAPI,
} from "../../APIs/ApiUtils";

// IMAGES  ====================================================================


// FOR APIS

export default function EditBlog() {

  const history = useHistory();
  const params = useParams();

  const [editorState, setEditorState] = useState(undefined);
  const [blogInfo, setBlogInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [profilePicture, setProfilePicture] = useState(undefined);
  const [profilePictureOpen, setProfilePictureOpen] = useState(false);
  const [title, setTitle] = useState(false);
  const [status, setStatus] = useState(false);
  const [excerpt, setExcerpt] = useState(false);
  const [detail, setDetail] = useState(undefined);

  const [blogcategory_id, setBlogcategory_id] = useState(undefined);
  const [blogCategoriesTable, setBlogCategoriesTable] = useState(undefined);
  const ItemValues=[
    {
      value: '',
      label: 'Select Status',
    },
    {
      value: 'ACTIVE',
      label: 'Active',
      selected: 'selected',
    },
    {
      value: 'INACTIVE',
      label: 'Inactive',
    },
    {
      value: 'PENDING',
      label: 'Pending',
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      history.push("/login");

     handleGetBlog();
     handleGetBlogCategories();

  }, []);

  function onEditorStateChangeLocal (editorState) {
    setEditorState(editorState);
    setDetail(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }

  return (
    <>
      {isFormLoading ? (
        <div className="align-center">
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} padding={0}>
              <PageTitle
                title={<Trans>EditBlog.pageTitle</Trans>}
                button={
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      history.push("/app/blogs");
                    }}
                  >
                    Back
                  </Button>
                }
              />
            </Grid>
  
            <Grid item xs={12} className="center">
              <Paper className="paper">
                <Typography
                  color="primary"
                  variant="h5"
                  size="sm"
                  className="section-title"
                >
                  Blog Info
                </Typography>
                <Divider className="divider" />
                <Grid container spacing={2} className="info-container">
                  <Grid item xs={12} className="form-field">
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        required
                        label="Title"
                        value={title}
                        placeholder="Enter blog title"
                        onChange={handletitleChanges}
                        helperText="This field is required"
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} className="excerpt-section" item spacing={2}>
                    <h6>Excerpt</h6>
                    <textarea 
                          value={excerpt}
                          onChange={handleExcerptChanges}
                          name="excerpt"
                          maxLength={2000}
                          style={{resize:"none",height:'20vh',width:'99%'}}
                        />
                  </Grid>


                  <Grid item xs={12} sm={6} className="form-field">
                    <FormControl fullWidth required>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={status}
                        onChange={handleStatusChanges}
                      >
                        {ItemValues.map((item) => (
                          <MenuItem
                            value={item.value}
                            selected={status === item.value}
                            key={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>This field is required</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} className="form-field">
                    <select
                      id="hash-status"
                      name="status"
                      value={blogcategory_id}
                      className="select-category"
                      onChange={handleCategoryChanges}
                    >
                      <option disabled value="">
                        Select Category
                      </option>
                      {blogCategoriesTable !== undefined &&
                        blogCategoriesTable.map((row) => (
                          <option
                            className="select-category"
                            value={row.id}
                            selected={row.id === blogcategory_id}
                            key={row.id}
                          >
                            {row.title}
                          </option>
                        ))}
                    </select>
                  </Grid>



                  <Grid container spacing={2} className="info-container">
                    <Grid item xs={12} className="editor-container">
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                      />
                    </Grid>
                  </Grid>
  
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    className="header-container"
                  >
                    <Grid item xs={6} sm={6} md={3} className="header-label">
                      <Typography style={{ color: "black", fontSize: 20 }}>
                        Header Image<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} className="header-upload">
                      <CustomUploadButton
                        paramValue="image"
                        state={{
                          state: {
                            imageMediaOpen: profilePictureOpen,
                            required: true,
                            paramValue: "image",
                            imageValue: blogInfo?.imageValue,
                          },
                        }}
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp", "application/pdf"]}
                        handleFileOpen={handleFileOpen}
                        handleFileClose={handleFileClose}
                        handleFileSave={handleFileSave}
                      />
                    </Grid>
                  </Grid>
  
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} className="create-blog-btn-section" style={{ marginTop: 25 }}>
                      <div>
                        <Button
                          onClick={handleEditBlog}
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="create-button"
                          disabled={isSubmitLoading}
                        >
                          {isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              Updating Blog
                            </div>
                          ) : (
                            'Update Blog'
                          )}
                        </Button>

                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 25 }}>
                      {isError && (
                        <Typography style={{ color: 'red' }}>
                          Error occurred. Please check the form.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
  
    
   async function handleGetBlog () {
    let RC = false;
    setIsFormLoading(true);
    let blogID= params.blogId;

    let blogInfo = await getBlogAPI(blogID);
    if (blogInfo != undefined) {
      console.log("Blog Found Successfully in edit response",blogInfo,      );
      let param = {
        filePath: blogInfo["blogprofileimageValue"],
        fileName: "blogprofileimage",
      };
      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      const html =  blogInfo?.detail;
      if(html!=undefined && html != "") 
      {
        const contentBlock = htmlToDraft(html);
        var editorStateStr = "";
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          editorStateStr = EditorState.createWithContent(contentState);
          setEditorState(editorStateStr);
          setDetail(html);
        }
      }
      setIsFormLoading(false);
      setProfilePicture(blob);
      setBlogcategory_id(blogInfo.blogcategory_id);
      setBlogInfo(blogInfo);
      setTitle(blogInfo.title);
      setExcerpt(blogInfo.excerpt);

    } else {
      console.log("Blog Not found");
      setIsFormLoading(false);
    }
  };

  async function  handleEditBlog (param) {
    console.log("createBlog API Started");
    let id_blog = params.blogId;
    let rc = await editBlogAPI(id_blog, param);
    if (rc == true) {
      console.log("Blog Updated Successfully");
      setIsSubmitLoading(false);
      history.push("/app/blogs");
    } else {
      setIsSubmitLoading(false);
      console.log("Blog Not Updated");
    }
    console.log("createBlog API ENDED");
  };

  function handleSubmit () {
    let param =    {
        "id": blogInfo?.id,
        "isactive": status,
        "user_id": blogInfo?.user_id,
        "title": title,
        "excerpt": excerpt,
        "isfeatured": blogInfo?.isfeatured,
        "detail": detail,
        "image": profilePicture,
        "blogcategory_id": blogcategory_id,
    };

    // let errors = ValidateFields(this);
    // console.log("Error exist of Not ", errors);

    // if (errors) {
    //   return false;
    // }
    console.log("Param value :: " , param);
    setIsFormLoading(true);
    handleEditBlog(param);
  };


  
  function handletitleChanges (e) {      
    setTitle(e.target.value);
  }

  function  handleCategoryChanges  (e) {      
      setBlogcategory_id(e.target.value);
  }

  function  handleStatusChanges  (e) {      
    setStatus(e.target.value);
  }

  function  handleExcerptChanges  (e) {      
    setExcerpt(e.target.value);
  }

  function handleFileClose (param) {
    setProfilePictureOpen(false);
  };

  function handleFileSave(param, files) {
    console.log("Current File List :: ", param, files[0]);
    // setState({
    //   ...state,
    //   [param + "Value"]: files[0],
    //   [param + "MediaOpen"]: false,
    // });
    setProfilePicture(files[0]);
    setProfilePictureOpen(false);
  };

  function handleFileOpen(param)  {
    // setState({
    //   ...state,
    //   [param + "MediaOpen"]: true,
    // });
    setProfilePictureOpen(true);
  };

  async function handleGetBlogCategories () {
    let RC = false;
    setIsFormLoading(true);
    let categories = await getBlogCategoriesAPI();
    if (categories != undefined && categories.length > 0) {
        console.log("categories Found Successfully ::", categories.length);
        setBlogCategoriesTable(categories);
        setIsFormLoading(false);
      } else {
        console.log("categories Not found");
        setIsFormLoading(false);
      }
  }
}
