import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// LOCALE  ====================================================================
import { Trans } from "react-i18next";
// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getDesignationDataArray, getImageMIMETypes, getMediaMIMETypes } from "../../helpers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../assets/style.css'
// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import { Typography, Paper, Grid, Button, Divider, FormControl, TextField, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";


// MATERIAL UI COMPONENTS  ====================================================================
import CircularProgress from "@material-ui/core/CircularProgress";

// IMAGES  ====================================================================

// FOR APIS  ====================================================================
import { getBlogsAPI, createBlogAPI } from "../../APIs/blogApis";
import { getBlogCategoriesAPI } from "../../APIs/blogcategoryApis";
import { isErrorExist, ValidateFields } from "../../APIs/ApiUtils";



class AddBlog extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      editorState: undefined,
      uploadersTable: [],
      isSubmitLoading: false,
      isError: false,
      blogcategory_idValue: undefined,
      blogCategoriesTable: undefined
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className="center">
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={1}>
              {/* ========== TITLE BAR ========== */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddBlog.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push('/app/blogs');
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className="center">
                <Paper className="paper">
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                    className="section-title"
                  >
                    Blog Info
                  </Typography>
                  <Divider className="divider" />
                  <Grid container spacing={2} className="info-container">
                    <Grid item xs={12} className="form-field">
                      <CustomTextField
                        required="required"
                        paramValue="title"
                        state={this.state}
                        onChange={this.handleParam}
                      />

                    </Grid>
                    <Grid item xs={12} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="excerpt"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid item xs={12} sm={6} className="form-field">
                      <CustomSelectField
                        paramValue="isactive"
                        required="required"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: '',
                            label: 'Select Status',
                          },
                          {
                            value: 'ACTIVE',
                            label: 'Active',
                            selected: 'selected',
                          },
                          {
                            value: 'INACTIVE',
                            label: 'Inactive',
                          },
                          {
                            value: 'PENDING',
                            label: 'Pending',
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} className="form-field">
                      <select id="" className="select-category" onChange={this.handleCategoryChanges}>
                        <option selected disabled value="">
                          Select Category
                        </option>
                        {this.state.blogCategoriesTable != undefined &&
                          this.state.blogCategoriesTable.map((row) => {
                            return <option className="select-category" value={row.id}>{row.title}</option>;
                          })}
                      </select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="info-container">
                    <Grid item xs={12} className="editor-container">
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                      />

                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" spacing={2} className="header-container">
                    <Grid item xs={6} sm={6} md={3} className="header-label">
                      <Typography style={{ color: 'black', fontSize: 20 }}>
                        Header Image<span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} className="header-upload">
                      <CustomUploadButton
                        required="required"
                        paramValue="image"
                        state={this.state}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} className="create-blog-btn-section" style={{ marginTop: 25 }}>
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="create-button"
                          disabled={this.state.isSubmitLoading}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              Creating Blog
                            </div>
                          ) : (
                            'Create Blog'
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 25 }}>
                      {this.state.isError && (
                        <Typography style={{ color: 'red' }}>
                          Error coming, Please check the form
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("title", { checkEmpty: true, minLength: 0, maxLength: 250, });
    this.setStateVariables("excerpt", { checkEmpty: false, minLength: 0, maxLength: 2000, });
    this.setStateVariables("detail", { checkEmpty: false, minLength: 0, maxLength: 2000, });
    this.setStateVariables("isactive", { checkEmpty: false }, "SelectField", "ACTIVE",);
    this.setStateVariables("image", { checkEmpty: false }, "FileField");
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetBlogCategories();
    const html = "Enter Description";
    if (html != undefined && html != "") {
      const contentBlock = htmlToDraft(html);
      var editorStateStr = "";
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorStateStr = EditorState.createWithContent(contentState);
        this.setState({
          editorState: editorStateStr,
          detailValue: html
        });
      }
    }
  };


  onEditorStateChangeLocal = (iEditorState) => {
    this.setState({
      editorState: iEditorState,
      detailValue: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    });
  }

  handleGetBlogCategories = async () => {
    let RC = false;
    this.setState({ isFormLoading: true });
    let categories = await getBlogCategoriesAPI();
    if (categories != undefined && categories.length > 0) {
      console.log("categories Found Successfully ::", categories.length);
      this.setState({
        isFormLoading: false,
        blogCategoriesTable: categories
      });
      this.setState({ isFormLoading: false });
    } else {
      console.log("categories Not found");
      this.setState({ isFormLoading: false });
    }
  }

  handleCategoryChanges = (e) => {
    this.setState({ blogcategory_idValue: e.target.value });
  }

  handleSubmitButton = async (param) => {
    console.log("createBlog API Started");
    param["user_id"] = localStorage.getItem("user_id");

    let rc = await createBlogAPI(param);
    alert(rc);
    if (rc == true) {
      console.log("Blog Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/blogs");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Blog Not Added");
    }
    console.log("createBlog API ENDED");
  };
}

export default AddBlog;
