import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "./../../components/Controls/CustomTextField";
import CustomSelectField from "./../../components/Controls/CustomSelectField";
import CustomRadioButton from "./../../components/Controls/CustomRadioButton";
import CustomUploadButton from "./../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import {getRadioMacros, getCountryList,  getStatusMacros,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";


// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";

// FOR APIS  ====================================================================
import {
  getServicesAPI,
  createServiceAPI,
} from "../../APIs/servicesApis";

import {
  getBlogCategoriesAPI,
} from "../../APIs/blogcategoryApis";

// IMAGES  ====================================================================
import userimage from "./../../images/userimage.png";

const requiredParam = [
  "service_id",
  "parent_id",
  "title",
  "status",
  "excerpt",
  "description",
  "category_id",
  "serviceimages",
  "offer",
  "price",
];

class AddService extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      servicesTable: undefined,
      categoriesTable : undefined,
      editorState : undefined,
      serviceInfo: [],
      isSubmitLoading: false,
      isError: false,
      profilePicture: "",
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddService.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/services");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                    style={{ borderBottom: "1px solid #000" }}
                  >
                    Service Info
                  </Typography>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={8}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="title"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      // item
                      // xs={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <div>
                        <Typography>Service Image</Typography>
                        <img
                          src={
                            this.state.profilePicture == " "
                              ? userimage
                              : this.state.profilePicture
                          }
                          height={150}
                          width={150}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="excerpt"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="serviceimages"
                          fileName={this.state.nameValue + "_serviceimages"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={getImageMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      align="center"
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <h5 className="text-align-left text-left" style={{textAlign:'left'}}>Service Description</h5>
                      
                        <Editor
                         editorState={this.state.editorState}
                         wrapperClassName="demo-wrapper"
                         editorClassName="demo-editor"
                         onEditorStateChange={this.onHtmlEditorStateChange}
                       />
                       {this.state.descriptionValue}
                       {
                        
                       (this.state.descriptionErrorStatus)? <div className="text-danger"><p >{this.state.descriptionErrorStatus}</p></div>:""                        
                       }
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                        <div className="mb-2 cls-required-star">Service Category</div>
                        <select id="hash-status" name="category_id" value={this.state.category_idValue}  className="cls-select-control" onChange={this.handleCategoryChanges}>
                          <option selected disabled value="">Select Category</option>
                          { 
                            this.state.categoriesTable!=undefined && this.state.categoriesTable.map( row => {
                            return (
                            <option value={row.id} selected={row.id==this.state.category_idValue} >{row.title}</option>
                            )})
                          }
                        </select>
                        {(this.state.category_idErrorStatus)? <div className="text-danger"><p >Please select catagory</p></div>:""}
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                        <div className="mb-2 cls-required-star">Parent Service</div>
                        <select id="hash-status" name="parent_id" value={this.state.parent_idValue}  className="cls-select-control" onChange={this.handleParentChanges}>
                          <option selected disabled value="">Select Parent Service</option>
                          <option value={undefined} >None</option>
                          { 
                            this.state.servicesTable!=undefined && this.state.servicesTable.map( row => {
                            return (
                            <option value={row.id} selected={row.id==this.state.parent_idValue} >{row.title}</option>
                            )})
                          }
                        </select>
                        {(this.state.parent_idErrorStatus)? <div className="text-danger"><p >Please select catagory</p></div>:""}
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="offer"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomSelectField
                        paramValue="status"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                            selected: "selected",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                          },
                          {
                            value: "INACTIVE",
                            label: "InActive",
                          },
                        ]}
                      />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="price"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                  </Grid>

                  

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Create Service
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("title", {checkEmpty: true,minLength: 2,maxLength: 120,});
    this.setStateVariables("excerpt", {checkEmpty: true,minLength: 2,maxLength: 200,});
    this.setStateVariables("parent_id", { checkEmpty: true }, "SelectField");
    this.setStateVariables("description", {checkEmpty: true,minLength: 2});
    this.setStateVariables("category_id", { checkEmpty: true }, "SelectField");
    this.setStateVariables("serviceimages", { checkEmpty: true }, "FileField");
    this.setStateVariables("offer",{checkEmpty: false,minLength: 0,},"NumberField",);
    this.setStateVariables("price",{checkEmpty: false,},"NumberField",);
    this.setStateVariables("status", { checkEmpty: true }, "SelectField");
    let editorStateStr = this.handleHTMLEditor("Enter Description Here");
    this.setState({
      editorState: editorStateStr,
      descriptionValue: "Enter Description Here"
    });
    this.handleGetBlogCategories();
    this.handleGetServices();
  }

  handleGetBlogCategories = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, categoriesTable: undefined });
    let categories = await getBlogCategoriesAPI();
    if (categories != undefined && categories.length > 0) {
        console.info("categories Found Successfully ::", categories);
        this.setState({ isFormLoading: false, categoriesTable: categories });
      } else {
        console.error("categories Not found");
        this.setState({ isFormLoading: true, categoriesTable: undefined });
      }
  }

  handleGetServices = async () => {
    let RC = false;
    this.setState({ loader: true, servicesTable: [] });
    let {status, response} = await getServicesAPI();
    if (status!=undefined && status == "S_OK") {
      this.setState({
        servicesTable: response,
        loader: false,
      });
    } else {
      console.log("Service Not found");
      this.setState({ loader: false });
    }
  };

  handleCategoryChanges = (e) => {      
    this.setState({
      category_idValue: e.target.value,
      category_idErrorStatus: false,
      category_idErrorMsg:""
    });
  }

  handleParentChanges = (e) => {      
    this.setState({
      parent_idValue: e.target.value,      
      parent_idErrorStatus: false,
      parent_idErrorMsg:""
    });
  }

  handleSubmitButton = async (param) => {
    console.log("createService API Started", param);
    let id_service = this.props.match.params.serviceId;
    let rc = await createServiceAPI(param);
    if (rc != undefined) {
      console.log("Service Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/services");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Service Not Added");
    }
    console.log("createService API ENDED");
  };


}

export default AddService;
