import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import themeAPP from "../../themes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getDesignationDataArray,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Grid,
  Button,
  CircularProgress,
  Slide
} from "@material-ui/core";
// data
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// MATERIAL UI DEPENDENCIES
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS  ====================================================================
import {
  deleteServiceAPI,
  getServicesAPI,
} from "../../APIs/servicesApis";

// IMAGES  ====================================================================



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default class Service extends Component {
  constructor() {
    super();
    this.state = {
      servicesTable: [],
      loader: false,
      deleteDialog: false,
      permissions: undefined,
      roles: undefined,      
    };
  }

  render() {
    const components = {
      // ExpandButton: function(props) {
      //   return <DeleteIcon {...props} />;
      // },
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid xs={12} container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>Services.pageTitle</Trans>}
                  button={
                    /*(this.state.permissions != undefined) &&
                    this.state.permissions.includes("service_create") && */(
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          this.props.history.push("/app/addservice");
                        }}
                      >
                        <Trans>Services.submitBtn</Trans>
                      </Button>
                    )
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={{ justifyContent: "space-between" }}
              >
                <MuiThemeProvider theme={themeAPP.default}>
                  <MUIDataTable
                    title="Services List"
                    data={this.state.servicesTable}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "profilethumbnail",
                        label: "Profile",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <Avatar src={tableMeta.rowData[1]}>
                                {/* <AssignmentIcon /> */}
                              </Avatar>
                            );
                          },
                        },
                      },
                      {
                        name: "title",
                        label: "Name",
                      },
                      {
                        name: "excerpt",
                        label: "Excerpt",
                      },
                       {
                        name: "parent_id",
                        label: "Parent",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (tableMeta.rowData[4]==null)? "None" : this.viewParentService(tableMeta.rowData[4]);
                          },
                        },
                      },
                      
                      {
                        name: "price",
                        label: "Price",
                      },
                      {
                        name: "category_id",
                        label: "Type",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return tableMeta.rowData[6];
                          },
                        },
                      },
                      {
                        name: "Actions",
                        options: {
                          filter: true,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRender(
                              value,
                              tableMeta,
                              updateValue,
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      selectableRows: false,
                      fixedHeader: true,
                      filter: true,
                      filterType: "dropdown",
                      responsive: "standard",
                      tableBodyHeight: "400px",
                      tableBodyMaxHeight: "400px",
                      // expandableRows: true,
                      // expandableRowsHeader: true,
                      // expandableRowsOnClick: false,
                      // isRowExpandable: (dataIndex, expandedRows) => {
                      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
                      // if (
                      //   expandedRows.data.length > 1 &&
                      //   expandedRows.data.filter((d) => d.dataIndex === dataIndex)
                      //     .length === 0
                      // )
                      //   return false;
                      //   return true;
                      // },
                      // rowsExpanded: [],
                      // renderExpandableRow: (rowData, rowMeta) => {
                      //   return this.handleServiceExpend(rowData, rowMeta);
                      // },
                      // onRowExpansionChange: (
                      //   curExpanded,
                      //   allExpanded,
                      //   rowsExpanded,
                      // ) => console.log(curExpanded, allExpanded, rowsExpanded),
                    }}
                    // components={components}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
        {/* <Grid item xs={12}>
            <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
              <Table data={mock.table} />
            </Widget>
          </Grid> */}
      </>
    );
  }

  componentDidMount() {
    let userRole = localStorage.getItem("user_role");
    var userRoleArr = userRole.split(",");
    let userPermission = localStorage.getItem("user_permission");
    // var userPermissionArr = userPermission.split(",");
    // this.setState({ permissions: userPermissionArr, roles: userRoleArr });

    this.handleGetServices();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  viewParentService = (iRowId) => {
    return (
      <div>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={() => {
              this.props.history.push("/app/services/"+iRowId);
          }}
        >
          { this.state.servicesTable.filter(row => {return row.id == iRowId})[0]?.title } 
        </Button>
      </div>
    );
  };

  handleGetServices = async () => {
    let RC = false;
    this.setState({ loader: true, servicesTable: [] });
    // let func_name = "getServicesAPI";
    let { status, response }  = await getServicesAPI();
    if (status == "S_OK") {
      console.log("Services Found Successfully");
      this.setState({
        servicesTable: response,
        loader: false,
      });
    } else {
      console.log("Service Not found");
      this.setState({ loader: false });
    }
  };

  deleteService = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    console.log("Current Service ID to delete :: ",tableMeta.rowData[0]);

    this.handleClose();
    let rc = await deleteServiceAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("Service Deleted Successfully");
      this.handleGetServices();
    } else console.log("Service Not Deleted");
  };

  editService = (value, tableMeta, updateValue) => {    
    this.props.history.push("/app/editservice/"+tableMeta.rowData[0]);
  };

  viewService = (value, tableMeta, updateValue) => {
    this.props.history.push("/app/editservice/"+tableMeta.rowData[0]);
  };

  handleServiceExpend = (rowData, rowMeta) => {
    const colSpan = rowData.length;
    let rowDataStr = JSON.stringify(rowData);
    return (
      <div>
        <TableRow>
          <TableCell colSpan={colSpan}>Name : {rowData[0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colSpan}>Name : {rowData[1]}</TableCell>
        </TableRow>
      </div>
    );
  };

  handleClickOpen = () => {
    this.setState({ ...this.state, deleteDialog: true });
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <div style={{ display: "inline-flex", margin: "0px auto" }}>
        { /*(this.state.permissions.includes("service_view")) &&*/ (
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => this.viewService(value, tableMeta, updateValue)}
            style={themeAPP.default.button}
          >
            <Tooltip title="View Service" aria-label="add">
              <Visibility />
            </Tooltip>
          </IconButton>
        )}
        { /*(this.state.permissions.includes("service_update")) &&*/ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="upload picture"
            onClick={() => this.editService(value, tableMeta, updateValue)}
          >
            <Tooltip title="Edit Service" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        {/*(this.state.permissions.includes("service_delete")) &&*/ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="Delete Button"
            // onClick={() => this.handleClickOpen()}
            onClick={() => {
              var retVal = window.confirm(
                'Are you sure to delete service "' + tableMeta.rowData[0] + '"',
              );
              if (retVal == true) {
                this.deleteService(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          >
            <Tooltip title="Delete Current Service" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
        <Dialog
          open={this.state.deleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting Service
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {"Are you sure to delete service" +
                tableMeta.rowData[1]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.deleteService(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}
