import React from "react";
import axios from "axios";
import API, { API_Param } from "./../API";

export { getPermissionsAPI, addPermissionAPI,editPermissionAPI, getPermissionByIdAPI };

async function getPermissionsAPI() {
  let permissions = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("permission");
    if (response.status == 200) {
      console.log("Response from  Role API :: ", response.data);
      return response.data;
    } else {
      console.log("Role API Request Failed With Status :: ", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("Role API Request Failed With Status :: ", error);

    return permissions;
  }
}

async function addPermissionAPI(fullname, displayname, description) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "permission",
      {
        name: fullname,
        display_name: displayname,
        description: description,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response;
  } catch (error) {
    console.log("Permission API Request Failed With Status :: ", error);

    return [];
  }
}

async function editPermissionAPI(permission_id, fullname, displayname, description) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.put(
      "permission/" + permission_id,
      {
        name: fullname,
        display_name: displayname,
        description: description,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response;
  } catch (error) {
    console.log("Permission API Request Failed With Status :: ", error);

    return [];
  }
}

async function getPermissionByIdAPI(permission_id) {
  let permissions = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("permission/" + permission_id);
    if (response.status == 200) {
      console.log("Response from   getPermissionsById API :: ", response.data);
      return response.data;
    } else {
      console.log(
        " getPermissionsById API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log(
      " getPermissionsById API Request Failed With Status :: ",
      error,
    );

    return permissions;
  }
}
