import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import { getMembersAPI, createMemberAPI } from "../../APIs/memberApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
} from "../../APIs/ApiUtils";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

let membertypes = [
  {
    value: "INDIVIDUAL",
    label: "INDIVIDUAL",
  },
  {
    value: "COMPANY",
    label: "COMPANY",
  },
  {
    value: "TEAM",
    label: "TEAM",
  },
];

class AddMember extends Component {
  constructor() {
    super();
    this.state = {
      uploadersTable: [],
      isSubmitLoading: false,
      isError: false,
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddMember.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/members");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Personal Info
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="membername"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberemail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="memberprofile"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="memberphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      <CustomSelectField
                        paramValue="status"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                            selected: "selected",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberaddress_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid> */}
                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }}>
                        <CustomTextField paramValue="membercompany" state={this.state} onChange={this.handleParam} />                      
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberlinkedin"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberfacebook"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="membersummary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid
                      item
                      mt={4}
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography color="primary" variant="h5" size="sm" mt={4}>
                        Documents Section
                      </Typography>
                      <Divider style={{ margin: "5px 0 15px 0" }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        User Profile Image
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      {
                        <CustomUploadButton
                          paramValue="memberprofilefile"
                          state={this.state}
                          acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        NDA Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="memberndafile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="membercontractfile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    ></Grid>
                  </Grid>


                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          // disabled={
                          //   loginValue.length === 0 ||
                          // }
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Create Member"}
                            </div>
                          ) : (
                            "Create Member"
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("memberprofile", {checkEmpty: true,minLength: 0,maxLength: 120,});    
    this.setStateVariables("membername", {checkEmpty: true,minLength: 0,maxLength: 120,});    
    this.setStateVariables("memberemail", { checkEmpty: false,minLength: 0,maxLength: 120,});
    this.setStateVariables("memberphone", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("membersummary", {checkEmpty: false, minLength: 0, maxLength: 200,});
    this.setStateVariables("status",{ checkEmpty: false },"SelectField","ACTIVE",);
    this.setStateVariables("memberaddress_id", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("membercompany",      { checkEmpty: false,minLength: 0, maxLength: 120 },    );
    this.setStateVariables("memberlinkedin", { checkEmpty: false,minLength: 0, maxLength: 120,});
    this.setStateVariables("memberfacebook", {checkEmpty: true,minLength: 0,maxLength: 120,});
    this.setStateVariables("memberprofilefile", { checkEmpty: false }, "FileField");    
    this.setStateVariables("membercontractfile",{ checkEmpty: false },"FileField",);
    this.setStateVariables("memberndafile", { checkEmpty: false }, "FileField");
  }

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false,
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "AddMember." + param + "Label";
    let transPlaceHolder = "AddMember." + param + "PlaceHolder";
    let transErrorMsg = "AddMember." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };

  handleCreateMember = async (param) => {
    console.log("createMember API Started");
    let rc = await createMemberAPI(param);
    if (rc == true) {
      console.log("Member Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/members");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Member Not Added");
    }
    console.log("createMember API ENDED");
  };

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    param["user_id"] = localStorage.getItem("user_id");
    this.handleCreateMember(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    let paramKeyError = param + "ErrorStatus";

    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [paramKeyError]: false,
      [param + "MediaOpen"]: false,
    });
    let isExist = isErrorExist(this.state);
    // if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };
}

export default AddMember;
