import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";
import { displayResponseError } from "./ApiUtils";
// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {
  getAllBookingsAPI,
  getBookingsAPI,
  getBookingAPI,
  addBookingAPI
};


async function getAllBookingsAPI() {
  let bookings = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("booking");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      // response.data.response.bookings.map((booking) => {        
      //   booking['name'] = booking.donor['name'];
      //   booking['user_id'] = booking.donor['user_id'];
      //   bookings.push(booking);
      // });
      return response.data.response.bookings;      
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return undefined;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return undefined;
  }
}

async function getBookingsAPI(param) {
  let donor = undefined;
  let token = localStorage.getItem("id_token");
  let id_donor = param.id;

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("donor/bookings/" + id_donor);
    if (response.status == 200) {
      console.log("Response from getBookings API :: ", response.data.response);
      return response.data.response.bookings;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getBookingAPI(param) {
  let token = localStorage.getItem("id_token");
  let id_booking = param.id;
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("booking/" + id_booking);
    if (response.status == 200) {
      console.log("Response from getBookings API :: ", response.data.response);
      return response.data.response.booking;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function addBookingAPI(param) {
    let token = localStorage.getItem("id_token");
    let id_booking = param.id;
  
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    try {
      let response = await API.get("booking/" + id_booking);
      if (response.status == 200) {
        console.log("Response from getBookings API :: ", response.data.response);
        return response.data.response.booking;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      toast.error("Error : " + error);
      return false;
    }
  }
