import React, { Fragment,useRef, useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";

// FOR APIS
import {
    getAllPaymentsAPI
} from "../../APIs/paymentApis";

import {
  getLocaleShortDateString,
  currencySymbol
} from "../../helpers";

// CUSTOM COMPONENTS
// import  CircularStatic from '../../components/Controls/CircularProgressBar';
import CircularProgress from "@material-ui/core/CircularProgress";


// The "main" component, our actual calendar
const Orders = () => {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();
  const [orderList, setOrderList] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  const example1 = useRef(null);

  useEffect(() => {
  	console.log("Date has changed... Let's load some fresh data")
    handleGetPayment();
  }, [])

  async function handleGetPayment()  {
    let RC = false;
    
    let persona_id = params.itemId;
    let param = { startup_id: persona_id, persona_type : "startup" };
    setIsFormLoading(true);
    setOrderList(undefined);
    let orderArrs = await getAllPaymentsAPI(param);
    if (orderArrs != undefined) {
      console.log("Payment Found Successfully", orderArrs);
        setOrderList(orderArrs);
        setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
          <CircularProgress variant="indeterminate" value={75} />
          {/* <CircularStatic value={25} /> */}
        </div>
      ) : (
          <div className="cls-payment-table">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-white">
                            <div className="card-heading clearfix border-bottom my-3 mx-3">
                                <h4 className="card-title">Payments</h4>
                            </div>
                            <div className="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                  <thead>
                                    <tr class="align-self-center">
                                      <th>Done By</th>
                                      <th>Date</th>
                                      <th>amount</th>
                                      <th>Transaction Id</th>                                      
                                      <th>Payment Type</th>                                      
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      orderList?.map((row) => {
                                      return (                                
                                        <tr>
                                        <td><img src={row?.member?.profilethumbnail} alt="" class="thumb-sm rounded-circle mx-2" />
                                        {row?.member?.membername}
                                        </td>
                                        <td>{getLocaleShortDateString(row?.created_at)}</td>                                        
                                        <td>{row?.amount}</td>      
                                        <td>{row?.transaction_id}</td> 
                                        <td><span className={(row?.type=="EXTERNAL")?"badge badge-boxed badge-soft-success":"badge badge-boxed badge-soft-warning"}>{row?.type}</span></td>
                                        <td><span className={(row?.status=="SUCCESS")?"badge badge-boxed badge-soft-success":"badge badge-boxed badge-soft-warning"}>{row?.status}</span></td>
                                        <td>
                                          <Link to={"/app/payment/"+ row.id} className="button button_primary border-bottom">
                                            <span>View Detail</span>
                                          </Link>
                                        </td>
                                    </tr>
                                    )
                                    })}
                                    {
                                        (orderList?.length == 0 ) && 
                                        <div className="text-secondary">No payments</div>
                                    }
                                  </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      )}
    </>
  )
}


export default Orders;


