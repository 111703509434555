const hin_source = {
    AddWorker_Labels:{
        firstnameLabel: 'प्रथम नाम',
        firstNamePlaceHolder: 'प्रथम नाम दर्ज'
    },
    main_menu: {
      sports: "Sports",
      my_store: "Mon magasin",
      my_orders: "Mes ordres",
      sign_in: "Connexion",
      sign_up: "s'enregistrer",
      offices: "Bureaux"
    },
    side_menu: {
      t_shirt: "कार्यकर्ता जोड़ें",
      shoes: "Chaussures",
      fleece: "Polaire",
      trousers: "Pantalon",
      shorts: "Courts métrages"
    }
  };
  
  export {hin_source};
  