import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "./../API";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  BASE_URL,
  LOGIN_URL,
  REGISTER_URL,
  FORGETPASSWORD_URL,
  RESETPASSWORD_URL,
  LOGOUT_URL,
  ISEMAILEXIST_URL,
  ISPHONEEXIST_URL,
  VERIFY_EMAIL_OTP,
  VERIFY_PHONE_OTP
} from "./../Macros";

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  registerUser,
  signOut,
  isEmailExist,
  isPhoneExist,
  forgetPassword,
  verifyOTPs,
  resetPassword,
};

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "REGISTER_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    let param = {
      email: login,
      role: "ADMIN",
      password: password,
    };

    try {
      let response = await API.post(LOGIN_URL, param);
      if (response.status == 200) {
        let permissions = [];
        let roles = [];
        console.log("Response from Login API :: ", response.data);
        response.data.response.permission.map((permission) => {
          permissions.push(permission["name"]);
        });

        response.data.response.role.map((role) => {
          roles.push(role["name"]);
        });

        localStorage.setItem("user_role", roles);
        localStorage.setItem("id_token", response.data.response.token);
        localStorage.setItem("user_id", response.data.response.user.id);
        localStorage.setItem(
          "user_name",
          response.data.response.user.name,
        );
        localStorage.setItem(
          "user_email",
          response.data.response.user.email,
        );
        localStorage.setItem(
          "user_phone",
          response.data.response.user.phone,
        );
        localStorage.setItem("role", response.data.response.user.role);
        localStorage.setItem("user_permission", permissions);
        API_Param.token = response.data.response.token;
      } else console.log("API Request Failed With Status :: ", response.status);

      setError(null);
      setIsLoading(false);
      dispatch({ type: "LOGIN_SUCCESS" });
      history.push("/app/dashboard");
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      // dispatch({ type: "LOGIN_FAILURE" });
      setError(true);
      setIsLoading(false);
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

async function registerUser(
  dispatch,
  email,
  password,
  name,
  userType,
  phone,
  history,
  setIsLoading,
  setError,
) {
  setError(false);
  setIsLoading(true);

  if (!!email && !!password && !!name) {
    let param = {
      name: name,
      email: email,
      password: password,
      user_type: userType,
      phone: phone,
      role: "ADMIN",
    };
    try {
      let response = await API.post(REGISTER_URL, param);
      if (response.status == 200) {
        console.log(
          "Response from Register API :: ",
          response.data.response.token,
        );
      } else console.log("API Request Failed With Status :: ", response.status);

      setError(null);
      setIsLoading(false);
      dispatch({ type: "REGISTER_SUCCESS" });
      history.push("/login");
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      // dispatch({ type: "LOGIN_FAILURE" });
      setError(true);
      setIsLoading(false);
    }
  } else {
    setError(true);
    setIsLoading(false);
  }
}

async function signOut(dispatch, history) {
  let token = localStorage.getItem("id_token");
  localStorage.removeItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.get(LOGOUT_URL);
    if (response.status == 200) {
      console.log("Response from Login API :: ", response.data);
      localStorage.removeItem("id_token");
      window.location.reload();
    } else {
      console.log("API Request Failed With Status :: ", response.status);
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
  }
}

async function isEmailExist(email) {
  try {
    let response = await API.get(ISEMAILEXIST_URL, {
      params: {
        email: email,
      },
    });
    if (response.status == 200)
      console.log("Response from isEmail Exist API :: ", response.data);
    else console.log("API Request Failed With Status :: ", response.status);
    return response.data.status;
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
  }
}

async function isPhoneExist(phone) {
  try {
    let response = await API.get(ISPHONEEXIST_URL, {
      params: {
        phone: phone,
      },
    });
    if (response.status == 200)
      console.log("Response from isPhone Exist API :: ", response.data);
    else console.log("API Request Failed With Status :: ", response.status);
    return response.data.status;
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
  }
}

async function forgetPassword(
  dispatch,
  email,
  phone,
  history,
  setIsLoading,
  setError,
) {
  let RC = false;
  setError(false);
  setIsLoading(true);

  if (!!email || !!phone) {
    let param = {
      email: email,
      phone: phone,
      role: "ADMIN",
    };

    try {
      let response = await API.post(FORGETPASSWORD_URL, param);
      if (response.status == 200) {
        console.log("Response from Forget Password API :: ", response.data);
      } else console.log("API Request Failed With Status :: ", response.status);

      setError(null);
      setIsLoading(false);
      // history.push("/resetPassword");
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      setError(true);
      setIsLoading(false);
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

async function verifyOTPs(
  dispatch,
  email,
  phone,
  emailOTP,
  phoneOTP,
  history,
  setIsLoading,
  setError,
) {
  let RC = false;
  setError(false);
  setIsLoading(true);

  if (!!email || !!phone) {
    let param = {
      user_email_otp: emailOTP,
      phone: phone,
    };

    try {
      let response = await API.post(VERIFY_EMAIL_OTP, param);
      if (response.status == 200) {
        console.log("Response from Forget Password API :: ", response.data);
      } else console.log("API Request Failed With Status :: ", response.status);

      setError(null);
      setIsLoading(false);
      history.push({
        pathname: "/resetpassword",
        state: { email: email, phone: phone, emailOTP: emailOTP },
      });
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      setError(true);
      setIsLoading(false);
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }

  return RC;
}

async function resetPassword(
  dispatch,
  email,
  phone,
  password,
  emailOTP,
  history,
  setIsLoading,
  setError,
) {
  let RC = false;

  try {
    let response = await API.post(RESETPASSWORD_URL, {
      emailOTP: emailOTP,
      email: email,
      password: password,
      confirm_password: password,
    });
    if (response.status == 200)
      console.log("Response from isPhone Exist API :: ", response.data);
    else console.log("API Request Failed With Status :: ", response.status);
    RC = true;
    history.push("/login");
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
  }
}
