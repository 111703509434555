import API from "./../API";

export {
  getNotificationsAPI,
  createNotificationAPI,
  getUsersAPI,
  getNotificationByIdAPI,
  editNotification1API,
  getNotificationByUserIdAPI,
  viewNotificationByIdAPI,
};

async function getNotificationsAPI() {
  let notification = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("notification");
    console.log("The response of notifican api is :", JSON.stringify(response));
    return response.data.response.notifications;
  } catch (error) {
    console.log("Role API Request Failed With Status :: ", error);
  }
}

async function createNotificationAPI(param) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.timeout = 600000;
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("notification", param, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from createNotification API :: ", response.data);

        return response.data;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES

        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getUsersAPI() {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("userslist");
    console.log("The response of users api is :", JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.log("users API Request Failed With Status :: ", error);
  }
}

async function getNotificationByIdAPI(notification_id) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("notification/" + notification_id);
    console.log(
      "The response of getnotification by id api is :",
      JSON.stringify(response),
    );
    return response;
  } catch (error) {
    console.log(
      "getnotification by id API Request Failed With Status :: ",
      error,
    );
  }
}

async function editNotification1API(param) {
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer" + token };
  let id = param.id;
  console.log("params", JSON.stringify(param));
  try {
    API.defaults.headers.common = { Authorization: "Bearer" + token };
    let response = await API.put(
      "notification/" + id,
      {
        title: param.title,
        description: param.description,
        redirect_link: param.redirect_link,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    //  console.log("the response of editnotification api is: ",JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.log("editNotification API failed with stats :: ", error);
  }
}

async function getNotificationByUserIdAPI(user_id) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("notification/user/" + user_id);
    console.log(
      "The response of getnotification by id api is :",
      JSON.stringify(response),
    );
    return response.data;
  } catch (error) {
    console.log(
      "getnotification by id API Request Failed With Status :: ",
      error,
    );
  }
}

async function viewNotificationByIdAPI(user_id,notification_id) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get(
      "notification/seen/" + notification_id + "/" + user_id,
    );
    // console.log("The response of viewNotification is :",JSON.stringify(response));
    return response;
  } catch (error) {
    // console.log("viewNotification API Request Failed With Status :: ", error);
    return false;
  }
}
