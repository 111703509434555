import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";


// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useLayoutState } from "../../context/LayoutContext";

// {/* =============>>>>>  DASHBOARD MODULE  <<<<<============ */}
import Dashboard from "../../pages/dashboard";

// {/* =============>>>>>  CORE MODULE  <<<<<============ */}            
import GeneralSettings from './../../pages/settings/generalsettings/GeneralSettings'
import EditGeneralSettings from './../../pages/settings/generalsettings/editGeneralSettings'
import TeamView from './../../pages/team/view';

// {/* =============>>>>>  BLOG MODULE  <<<<<============ */}
import BlogCategories from "../../pages/blogcategory/blogcategories";
import BlogCategory from "../../pages/blogcategory/blogcategory";
import AddBlogCategory from "../../pages/blogcategory/addblogcategory";
import EditBlogCategory from "../../pages/blogcategory/editblogcategory";

import Blogs from "../../pages/blog/blogs";
import Blog from "../../pages/blog/blog";
import AddBlog from "../../pages/blog/addblog";
import EditBlog from "../../pages/blog/editblog";

import FAQs from "../../pages/FAQs/FAQs";
import FAQ from "../../pages/FAQs/FAQ";
import AddFAQ from "../../pages/FAQs/addFAQ";
import EditFAQ from "../../pages/FAQs/editFAQ";

// {/* =============>>>>>  INVENTORY MODULE  <<<<<============ */}           
import Services from "../../pages/services/services";
import AddService from "../../pages/services/addservice";
import EditService from "../../pages/services/editservice";
import ViewServices from "../../pages/services/viewservices";

// {/* =============>>>>>  ROLE - PERMISSION MODULE  <<<<<============ */}                       
import Roles from './../../pages/settings/roles/Roles'
import EditRoles from './../../pages/settings/roles/editRoles'
import Permissions from './../../pages/settings/permissions/Permissions'
import EditPermissions from './../../pages/settings/permissions/editPermissions'

// {/* =============>>>>>  NOTIFICATION MODULE  <<<<<============ */}                       
import Notifications from "../../pages/notifications";
import Notification from './../../pages/notification/notifications';
import CreateNotification from './../../pages/notification/addNotification';
import EditNotification from './../../pages/notification/editNotification';

// {/* =============>>>>>  PERSONA MODULE  <<<<<============ */}                       
import startups from "../../pages/startups/Startups";
import AddStartup from "../../pages/startups/addstartup";
import EditStartup from "../../pages/startups/editstartup";
import AddStartupDocument from "../../pages/startups/addDocuments";
import EditStartupDocument from "../../pages/startups/editDocument.js";
import AddCoFounder from "../../pages/startups/addCoFounder";
import EditCoFounder from "../../pages/startups/editCoFounder.js";
import ViewStartup from "../../pages/startups/viewStartup";
import Investors from "../../pages/investors/investors";
import Investor from "../../pages/investors/investor";
import AddInvestor from "../../pages/investors/addinvestor";
import EditInvestor from "../../pages/investors/editinvestor";
import ViewStartupsForInvestor from "../../pages/investors/viewstartupsforinvestor";
import ViewStartupForInvestor from "../../pages/investors/viewstartupforinvestor.js";
import Members from "../../pages/members/members";
import Member from "../../pages/members/member";
import AddMember from "../../pages/members/addmember";
import EditMember from "../../pages/members/editmember";
import AllocateService from "../../pages/members/allocateservice";
import Admins from "../../pages/admins/admins";
import Admin from "../../pages/admins/viewAdmin";
import AddAdmin from "../../pages/admins/addAdmin";
import EditAdmin from "../../pages/admins/editAdmin";
import ViewTeam from "../../pages/admins/viewTeam";


// {/* =============>>>>>  CHECKOUT MODULE  <<<<<============ */}                       
// BOOKING MODULE
import Bookings from "./../../pages/bookings/bookings";
import Booking from "./../../pages/bookings/booking";

// PAYMENT MODULE
import Payments from "./../../pages/payments/payments";
import Payment from "./../../pages/payments/payment";


function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div className="cls-content-area">          
          <Switch>

            {/* =============>>>>>  DASHBOARD MODULE  <<<<<============ */}
            <Route path="/app/dashboard" component={Dashboard} />

            {/* =============>>>>>  CORE MODULE  <<<<<============ */}            
            <Route path="/app/teamview" component={TeamView} />
            <Route path="/app/generalsettings" component={GeneralSettings} />
            <Route path="/app/editgeneralsetting/:settingId"component={EditGeneralSettings} />

            {/* =============>>>>>  BLOG MODULE  <<<<<============ */}
            <Route path="/app/blog/categories" component={BlogCategories} />
            <Route path="/app/blog/viewcategory/:blogcategoryId" component={BlogCategory} />
            <Route path="/app/blog/addcategories" component={AddBlogCategory} />
            <Route path="/app/blog/editcategory/:blogcategoryId" component={EditBlogCategory} />

            <Route path="/app/blogs" component={Blogs} />
            <Route path="/app/blog/:blogId" component={Blog} />
            <Route path="/app/addblog" component={AddBlog} />
            <Route path="/app/editblog/:blogId" component={EditBlog} />

            <Route path="/app/FAQs" component={FAQs} />
            <Route path="/app/FAQs/:FAQId" component={FAQ} />
            <Route path="/app/addFAQs" component={AddFAQ} />
            <Route path="/app/editFAQs/:FAQsId" component={EditFAQ} />

            {/* =============>>>>>  INVENTORY MODULE  <<<<<============ */}           
            <Route path="/app/services" component={Services} />
            <Route path="/app/addservice" component={AddService} />
            <Route path="/app/editservice/:serviceId" component={EditService} />
            <Route path="/app/viewservices/:serviceId" component={ViewServices} />
            
            {/* =============>>>>>  ROLE - PERMISSION MODULE  <<<<<============ */}                       
            <Route path="/app/roles" component={Roles} />
            <Route path="/app/editrole/:roleId"component={EditRoles} />
            <Route path="/app/permissions" component={Permissions} />
            <Route path="/app/editpermission/:permissionId" component={EditPermissions} />

            {/* =============>>>>>  NOTIFICATION MODULE  <<<<<============ */}                       
            <Route path="/app/notification" component={Notification} />
            <Route path="/app/createnotification" component={CreateNotification} />
            <Route path="/app/editnotification/:notificationId" component={EditNotification} />
            <Route path="/app/notifications" component={Notifications} />

            {/* =============>>>>>  PERSONA MODULE  <<<<<============ */}                       
            <Route path="/app/investors" component={Investors} />
            <Route path="/app/investor/:investorId" component={Investor} />
            <Route path="/app/addinvestor" component={AddInvestor} />
            <Route path="/app/editinvestor/:investorId" component={EditInvestor} />
            <Route path="/app/viewstartupsforinvestor/:investorId" component={ViewStartupsForInvestor} />
            <Route path="/app/viewstartupforinvestor/:investorId" component={ViewStartupForInvestor} />

            <Route path="/app/startups" component={startups} />
            <Route path="/app/addstartup" component={AddStartup} />
            <Route path="/app/editstartup/:startupId" component={EditStartup} />
            <Route path="/app/viewstartup/:startupId" component={ViewStartup} />
            
            <Route path="/app/addstartupdocument/:startupId" component={AddStartupDocument}/>
            <Route path="/app/editdocument/:startupId/:documentId" component={EditStartupDocument} />

            <Route path="/app/addcofounder/:startupId" component={AddCoFounder} />            
            <Route path="/app/editcofounder/:startupId/:cofounderId" component={EditCoFounder} />
             
            <Route path="/app/members" component={Members} />
            <Route path="/app/member/:memberId" component={Member} />
            <Route path="/app/addmember" component={AddMember} />
            <Route path="/app/editmember/:memberId" component={EditMember} />
            <Route path="/app/allocateservice/:memberId" component={AllocateService} />

            <Route path="/app/admins" component={Admins} />
            <Route path="/app/admin/:adminId" component={Admin} />
            <Route path="/app/addadmin" component={AddAdmin} />
            <Route path="/app/editadmin/:adminId" component={EditAdmin} />
            <Route path="/app/viewteam" component={ViewTeam} />
            
            {/* =============>>>>>  CHECKOUT MODULE  <<<<<============ */}                       
            <Route path="/app/bookings" component={Bookings} />
            <Route path="/app/booking/:bookingId" component={Booking} />            

            <Route path="/app/payments" component={Payments} />
            <Route path="/app/payment/:paymentId" component={Payment} />            

          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
