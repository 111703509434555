import React, { Fragment,useRef, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  LinearProgress,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";

// CUSTOM COMPONENTS
// import  CircularStatic from '../../components/Controls/CircularProgressBar';

import {
  deleteStartupAPI,
  getStartupsAPI,
  getStartupsPaginationAPI,
} from "../../APIs/startupApis";

import { getDashboardDataAPI } from "./../../APIs/dashboardApis";

const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  var [isFormLoading, setIsFormLoading] = useState("false");
  var [dashboardData, setDashboardData] = useState(undefined);
  var [startupsTable, setStartupsTable] = useState(undefined);

  useEffect(() => {
    handleGetDashboard();
    handleGetStartupsPagination();
  }, []);

  async function handleGetDashboard() {
    setIsFormLoading(true); 
    setDashboardData(undefined);

    let data = await getDashboardDataAPI();
    if (data.status == "success") {
      setIsFormLoading(false); 
      setDashboardData(data.response);
      console.log("Dashboard Data Found Successfully", data.response);
    } else {
      console.log("Dashboard Data Not found");
      setIsFormLoading(false); 
    }
  }

  async function handleGetStartupsPagination() {
    let RC = false;    
    setIsFormLoading(false); 
    setStartupsTable([]);
    let page = 1;
    let limit = 10;
    let iParam = {params: {current_status:"PENDING"}};

    let startups = await getStartupsPaginationAPI(limit, page, iParam);
    if (startups != undefined && startups.data.length > 0) {
      console.log("Startups Found Successfully",startups.data );
      setIsFormLoading(false); 
      setStartupsTable(startups);
    } else {
      console.log("Startup Not found");
      setIsFormLoading(false); 
    }
  };

  return (
    <>
    {isFormLoading ? (
      <div className={{ alignContents: "center",  }}>
        {/* <CircularStatic value={25} /> */}
      </div>
    ) : (
      <Fragment>

      <div className="d-flex flex-row justify-content-between" >
        <div>
          <h2 className="primary-color">Dashboard</h2> 
        </div>
        <div>
          <Button variant="contained" size="medium" color="secondary">
            Latest Reports
          </Button>
        </div>
      </div>

      <div className="container cls-dashboard-cards">
        <div className="row">
          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-blue order-card">
              <div className="card-block">
                <h6 className="m-b-20">Startups</h6>
                <h2 className="text-right"><span>{dashboardData?.startupCount}</span><span className="fa fa-cart-plus f-right"></span></h2>
                <p className="my-1">Completed Registration<span className="f-right">{dashboardData?.activeStartupCount}</span></p>
              </div>
            </div>
          </div>
          
          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-green order-card">
              <div className="card-block">
                <h6 className="m-b-20">Member Registered</h6>
                <h2 className="text-right"><span>{dashboardData?.memberCount}</span><span className="fa fa-cart-plus f-right"></span></h2>
                <p className="my-1">Active Members<span className="f-right">{dashboardData?.activeMemberCount}</span></p>
              </div>
            </div>
          </div>
          
          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-yellow order-card">
              <div className="card-block">
                <h6 className="m-b-20">Bookings Done</h6>
                <h2 className="text-right"><span>{dashboardData?.bookingCount}</span><span className="fa fa-cart-plus f-right"></span></h2>
                <p className="my-1">Completed Sessions<span className="f-right">{dashboardData?.completedBookingCount}</span></p>
              </div>
            </div>
          </div>
          
          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-pink order-card">
              <div className="card-block">
                <h6 className="m-b-20">Investors</h6>
                <h2 className="text-right"><span>{dashboardData?.investorCount}</span><span className="fa fa-cart-plus f-right"></span></h2>
                <p className="my-1">Active Investors<span className="f-right">{dashboardData?.activeInvestorCount}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container cls-dashboard-cards">
        <div className="row">

          <div className="col-md-4">
            <div className="card ">
              <div className="card-block ">
                <h6 className="m-b-20">Orders Received</h6>
                <div className="mt-4">
                  <LineChart
                    width={200}
                    height={100}
                    data={[
                      { value: 10 },
                      { value: 15 },
                      { value: 10 },
                      { value: 17 },
                      { value: 18 },
                    ]}
                  >
                    <Line
                      type="natural"
                      dataKey="value"
                      stroke={theme.palette.success.main}
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </div>
                <p className="my-1">Completed Orders<span className="f-right">351</span></p>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card ">
              <div className="card-block">
                <h6 className="m-b-20">Orders Received</h6>
                <div className="row">
                  <div className="col-md-6">
                    <ResponsiveContainer width="100%" height={144}>
                      <PieChart>
                        <Pie
                          data={PieChartData}
                          innerRadius={30}
                          outerRadius={40}
                          dataKey="value"
                        >
                          {PieChartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={theme.palette[entry.color].main}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="col-md-6">
                    <div className={classes.pieChartLegendWrapper}>
                      {PieChartData.map(({ name, value, color }, index) => (
                        <div key={color} className={classes.legendItemContainer}>
                          <i className={"fa fa-circle color-"+color} />
                          <div style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                            &nbsp;{name}&nbsp;
                          </div>
                          <div  className="text-mute">
                            &nbsp;{value}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-md-4 ">
            <div className="card ">
              <div className="card-block">
                <h6 className="m-b-20">Orders Received</h6>  
                <div className="my-3">
                  <div className={classes.performanceLegendWrapper}>
                    <div className={classes.legendElement}>
                      <Dot color="warning" />
                      <Typography
                        color="text"
                        colorBrightness="secondary"
                        className={classes.legendElementText}
                      >
                        Integration
                      </Typography>
                    </div>
                    <div className={classes.legendElement}>
                      <Dot color="primary" />
                      <Typography
                        color="text"
                        colorBrightness="secondary"
                        className={classes.legendElementText}
                      >
                        SDK
                      </Typography>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className={classes.progressSection}>
                      <Typography
                        size="md"
                        color="text"
                        colorBrightness="secondary"
                        className={classes.progressSectionTitle}
                      >
                        Integration
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={77}
                        classes={{ barColorPrimary: classes.progressBarPrimary }}
                        className={classes.progress}
                      />
                    </div>
                    <div className="mt-1">
                      <Typography
                        size="md"
                        color="text"
                        colorBrightness="secondary"
                        className={classes.progressSectionTitle}
                      >
                        SDK
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={73}
                        classes={{ barColorPrimary: classes.progressBarWarning }}
                        className={classes.progress}
                      />
                    </div>  
                  </div>
                </div>                            
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="container cls-dashboard-table">
          <div className="row align-items-center">
              <div className="col-md-6">
                  <div className="mb-3">
                      <h5 className="card-title">
                        Recent Onboarded Startups <span className="text-muted fw-normal ms-2">({startupsTable?.data?.length})</span></h5>
                  </div>
              </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="">
                <div className="table-responsive">
                  <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-4">
                            {/* <div className="form-check font-size-16"> */}
                              {/* <input type="checkbox" className="form-check-input" id="contacusercheck" /><label className="form-check-label" for="contacusercheck"></label></div> */}
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Location</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col" >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {startupsTable?.data?.map( row => {
                        return (
                        <tr>
                        <th scope="row" className="ps-4">
                          <img src={row?.profilethumbnail} alt="" className="avatar-sm rounded-circle me-2" />
                          {/* <div className="form-check font-size-16"><input type="checkbox" className="form-check-input" id="contacusercheck1" /><label className="form-check-label" for="contacusercheck1"></label></div> */}
                        </th>
                        <td>
                          
                          <a href="#" className="text-body">{row?.companyname}</a>
                        </td>
                        <td><span className="badge badge-soft-success mb-0">
                          {row?.operatingin}</span></td>
                        <td>{row?.contactemail}</td>
                        <td>{row?.status}</td>
                        <td>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <Link to={"/app/editstartup/"+row.id} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" className="px-2 text-primary"><i className="fa fa-pencil font-size-18"></i></Link>
                            </li>
                            {/* <li className="list-inline-item">
                              <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" className="px-2 text-danger"><i className="fa fa-trash font-size-18"></i></a>
                            </li>
                            <li className="list-inline-item dropdown">
                              <a className="text-muted dropdown-toggle font-size-18 px-2" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"><i className="fa fa-dots"></i></a>
                              <div className="dropdown-menu dropdown-menu-end">
                                  <a className="dropdown-item" href="#">Action</a><a className="dropdown-item" href="#">Another action</a><a className="dropdown-item" href="#">Something else here</a>
                              </div>
                            </li> */}
                        </ul>
                        </td>
                        </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 align-items-center pb-4">
            <div className="col-sm-6">
              <div><p className="mb-sm-0">Showing {startupsTable?.from} to {startupsTable?.to} of {startupsTable?.total} entries</p></div>
            </div>
            <div className="col-sm-6">
              <div className="float-sm-end">
                <ul className="pagination mb-sm-0">
                  <li className={startupsTable?.prev_page!=null?"page-item":"page-item disabled"}>
                      <a href="#" className="page-link"><i className="fa fa-chevron-left"></i></a>
                  </li>
                  {
                  startupsTable?.links?.forEach(element => {
                    return ( <li className={startupsTable?.current_page==1?"page-item active":"page-item"}><a href="#" className="page-link">1</a></li>);
                  })
                  }
                  {/* <li className="page-item active"><a href="#" className="page-link">1</a></li>
                  <li className="page-item"><a href="#" className="page-link">2</a></li>
                  <li className="page-item"><a href="#" className="page-link">3</a></li>
                  <li className="page-item"><a href="#" className="page-link">4</a></li>
                  <li className="page-item"><a href="#" className="page-link">5</a></li> */}
                  
                  <li className={startupsTable?.next_page!=null?"page-item":"page-item disabled"}>
                      <a href="#" className="page-link"><i className="fa fa-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      </Fragment>    
    )}
    </>
  )
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
