import React, { Component, Fragment } from "react";

import PageTitle from "../../../components/PageTitle/PageTitle";

// FOR APIS
import {
  getGeneralSettingsAPI,
  addGeneralSettingsAPI,
  getGeneralSettingByIdAPI,
  editGeneralSettingAPI,
} from "./../../../APIs/generalsettingsApis";
import { getPermissionsAPI } from "./../../../APIs/permissionsApis";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
  isWidthUp,
} from "@material-ui/core";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

class EditGeneralSettings extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      value: "",
      description: "",
      checked: false,
      errorLoader: true,
      generalsettingLoader: true,
    };
  }

  componentWillMount() {
    let settingId = this.props.match.params.settingId;
    this.getGeneralSettingDetails(settingId);
  }

  getGeneralSettingDetails = async (id) => {
    let details = await getGeneralSettingByIdAPI(id);
    console.log(
      "this is generalsetting by id response status in editgeneralsettings" + details.status,
    );
    if (details.status == "success") {
      let tempArray = [];

      this.setState({
        name: details.response.Setting.name,
        value: details.response.Setting.value,
        description: details.response.Setting.description,
        generalsettingLoader: false,
      });
    } else {
      this.setState({
        errorLoader: false,
        generalsettingLoader: false,
        permissionLoader: false,
      });
    }
    // console.log("getGeneralSettingByID details in edit generalsettings file:"+JSON.stringify(this.state.generalsettingpermissions))
  };



  setname = (e) => {
    this.setState({ name: e.target.value });
    // console.log("the full name is :"+e.target.value);
  };

  setvalue = (e) => {
    this.setState({ value: e.target.value });
    // console.log("the Value is :"+e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    // console.log("the description is :"+e.target.value);
  };

  handleCheckBoxChange = (name) => {
    let curData = this.state.allpermissions;
    let id = curData.findIndex(function (elem) {
      return elem.name == name;
    });
    curData[id].isChecked = !curData[id].isChecked;
    this.setState({ allpermissions: curData });
    console.log(
      "the id and value from checkBox is" +
        JSON.stringify(this.state.allpermissions),
    );
  };

  handleSubmit = async () => {    
    let id = this.props.match.params.settingId;

    if (
      this.state.name.length > 0 &&
      this.state.value.length > 0 &&
      this.state.description.length > 0
    ) {
      let response = await editGeneralSettingAPI(
        id,
        this.state.name,
        this.state.value,
        this.state.description,
      );
      console.log("Response of edit generalsettings is :" + JSON.stringify(response));
      if (response.status == "success") {
        this.setState({
          name: "",
          value: "",
          description: "",
        });
        this.props.history.push("/app/generalsettings");
      }
    }
  };

  render() {
    const data = this.state.allpermissions;
    return (
      <div>
        {/* <p>Hi, This is generalsettings page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Edit GeneralSetting"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/generalsettings");
                  }}
                >
                  Back
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.generalsettingLoader || this.state.permissionLoader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.errorLoader ? (
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            // variant="outlined"
                            label="GeneralSetting Name"
                            id="name"
                            name="name"
                            // placeholder="enter your name"
                            value={this.state.name}
                            // placeholder=""
                            onChange={this.setname}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            // variant="outlined"
                            label="Description"
                            placeholder="Description"
                            id="description"
                            name="description"
                            value={this.state.description}
                            onChange={this.setDescrition}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          {/* <TextField
                           fullWidth
                           variant="outlined" 
                           label="Description"
                           id="description"
                           name="description"
                           value={this.state.description}
                        //    placeholder=""      
                           onChange={this.setDescrition}
                       /> */}

                          <TextareaAutosize
                            //aria-label="minimum height"
                            //  className="border10 "
                            rowsMin={1}
                            label="Value"
                            id="Value"
                            name="Value"
                            placeholder="Value"
                            value={this.state.value}
                            onChange={this.setvalue}
                            style={{ fontSize: "16px", backgroundColor: "transparent" }}
                            />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      align="right"
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <div>
                  <p>Something went wrong,please try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default EditGeneralSettings;
