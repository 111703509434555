import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getDesignationDataArray,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Slide,
} from "@material-ui/core";

// FOR APIS  ====================================================================
import {
  getStartupsAPI,
  getStartupAPI,
  createStartupDocumentAPI,
  editStartupDocumentAPI,
} from "../../APIs/startupApis";


// IMAGES  ====================================================================
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

export default class AddStartupDocument extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      startupInfo: [],
      loader: false,
      deleteDialog: false,
      isSubmitLoading: false,
      isError: false,
    };
  }

  
  render() {
    const components = {
      // ExpandButton: function(props) {
      //   return <DeleteIcon {...props} />;
      // },
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={4}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddStartupDocument.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/editstartup/"+this.props.match.params.startupId);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h5"
                      size="sm"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      Personal Info
                    </Typography>
                  </Grid>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="title"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="description"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="file"                          
                          state={this.state}
                          acceptedFiles={getMediaMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Add Document To Profile
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("title", {checkEmpty: false,minLength: 0,maxLength: 50,});
    this.setStateVariables("description", {checkEmpty: false,minLength: 0,maxLength: 250,});
    this.setStateVariables("file", { checkEmpty: false }, "FileField");

    this.handleGetStartup();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ loader: true, startupInfo: [] });
    let startup_id = this.props.match.params.startupId;

    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      console.log("Startup Found Successfully");
      this.setState({
        startupInfo: startupInfo,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  handleSubmitButton = async (param) => {
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.startupId;
    let rc = await createStartupDocumentAPI(startup_id, param);
    if (rc == true) {
      console.log("Startup Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editstartup/"+this.props.match.params.startupId);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Document  Not Added");
    }
    console.log("createStartupDocument API ENDED");
  };

}
