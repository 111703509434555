import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import {getRadioMacrosNew,  getRadioMacros, getCountryList, getStatusMacros, getImageMIMETypes, getMediaMIMETypes } from "../../helpers";


// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Select,
  Divider,
  Slide,
  CircularProgress,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
} from "@material-ui/core";
// Picker
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MUIDataTable from "mui-datatables";


// FOR APIS  ====================================================================
import {
  getStartupAPI,
  editStartupAPI,
  getCoFoundersAPI,
  deleteCoFounderAPI,
  createStartupDocumentAPI,
  getStartupDocumentAPI,
  editStartupDocumentAPI,
  deleteStartupDocumentAPI,
  getTagsAPI
} from "../../APIs/startupApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
  getCategoriesAPI
} from "../../APIs/ApiUtils";


// IMAGES  ====================================================================
import userimage from "./../../images/userimage.png";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";


var requiredParam = [
  "companyname",
  "companyurl",
  "producturl",
  "demolink",
  "aboutcompany",
  "operatingin",
  "address_id",
  "contactname",
  "contactemail",
  "contactphone",
  "category_id",
  "isproductused",
  "isrevenue",
  "isalreadyparticipate",
  "previouscompany",
  "islegalentity",
  "isalreadyinvestment",
  "companylogo",
  "profileimage",
  "businessplan",
  "ndadoc",
  "contractdoc",
  "incorporationdate",
  "status",
  "isfeatured",
  "is_portfoliocompany",
  'filter',
  'tags',
  "is_corporated",
  // "city",
  // "state",
  // "pincode",
  // "startuplinkdinprofile",
  "noofcofounder",
  "companysize",
  "productoffering",
  "fundingtype",
  'revenuemodaltype',
  'bussinessmodaltype',
  "capitalraised",
  "revenue",
  "financial",
  "currentstage",
  "seeking",
  "ask",
  "keymemberslinkedinprofile",
];

export default class EditStartup extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      catagoriesList: [],
      tagsList: [],
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogStartupDocument: false,

      cofounders: [],
      documents: [],

      category_idLabel: <Trans>CommonField.category_idLabel</Trans>,
      category_idID: "category_id",
      category_idPlaceHolder: (
        <Trans>CommonField.category_idPlaceHolder</Trans>
      ),
      category_idValue: "",
      category_idErrorStatus: false,
      category_idErrorMsg: "Error in Selection",
      category_idFieldType: "SelectField",
      category_idFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: false,
      },

      contactnameLabel: <Trans>CommonField.contactnameLabel</Trans>,
      contactnameID: "contactname",
      contactnamePlaceHolder: <Trans>CommonField.contactnamePlaceHolder</Trans>,
      contactnameValue: "",
      contactnameErrorStatus: false,
      contactnameErrorMsg: "Error in Name",
      contactnameFieldType: "TextField",
      contactnameFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: false,
      },

      contactemailLabel: <Trans>CommonField.contactemailLabel</Trans>,
      contactemailID: "contactemail",
      contactemailPlaceHolder: <Trans>CommonField.contactemailPlaceHolder</Trans>,
      contactemailValue: "",
      contactemailErrorStatus: false,
      contactemailErrorMsg: "Error in Name",
      contactemailFieldType: "TextField",
      contactemailFieldValidation: {
        maxLength: 120,
        minLength: 0,
        checkEmpty: false,
      },

      contactphoneLabel: <Trans>CommonField.contactphoneLabel</Trans>,
      contactphoneID: "contactphone",
      contactphonePlaceHolder: <Trans>CommonField.contactphonePlaceHolder</Trans>,
      contactphoneValue: "",
      contactphoneErrorStatus: false,
      contactphoneErrorMsg: "Error in Phone",
      contactphoneFieldType: "TextField",
      contactphoneFieldValidation: {
        maxLength: 12,
        minLength: 0,
        checkEmpty: false,
      },


      incorporationdateLabel: <Trans>CommonField.incorporationdateLabel</Trans>,
      incorporationdateID: "incorporationdate",
      incorporationdatePlaceHolder: <Trans>CommonField.incorporationdatePlaceHolder</Trans>,
      incorporationdateValue: "",
      incorporationdateErrorStatus: false,
      incorporationdateErrorMsg: "Error in incorporationdate",

      profileimageLabel: <Trans>CommonField.profileimageLabel</Trans>,
      profileimageID: "profileimage",
      profileimagePlaceHolder: (
        <Trans>CommonField.profileimagePlaceHolder</Trans>
      ),
      profileimageValue: "",
      profileimageErrorStatus: false,
      profileimageErrorMsg: "Error in profileimage",

      statusLabel: <Trans>CommonField.statusLabel</Trans>,
      statusID: "status",
      statusPlaceHolder: <Trans>CommonField.statusPlaceHolder</Trans>,
      statusValue: "",
      statusErrorStatus: false,
      statusErrorMsg: "Error in status",

      isproductusedLabel: <Trans>CommonField.isproductusedLabel</Trans>,
      isproductusedID: "isproductused",
      isproductusedPlaceHolder: <Trans>CommonField.isproductusedPlaceHolder</Trans>,
      isproductusedValue: "",
      isproductusedErrorStatus: false,
      isproductusedErrorMsg: "Error in isproductused",

      isfeaturedLabel: <Trans>CommonField.isfeaturedLabel</Trans>,
      isfeaturedID: "isfeatured",
      isfeaturedPlaceHolder: <Trans>CommonField.isfeaturedPlaceHolder</Trans>,
      isfeaturedValue: "",
      isfeaturedErrorStatus: false,
      isfeaturedErrorMsg: "Error in is a featured startup",

      is_portfoliocompanyLabel: <Trans>CommonField.is_portfoliocompanyLabel</Trans>,
      is_portfoliocompanyID: "is_portfoliocompany",
      is_portfoliocompanyPlaceHolder: <Trans>CommonField.is_portfoliocompanyPlaceHolder</Trans>,
      is_portfoliocompanyValue: "",
      is_portfoliocompanyErrorStatus: false,
      is_portfoliocompanyErrorMsg: "Error in is_portfoliocompany",

      previouscompanyLabel: <Trans>CommonField.previouscompanyLabel</Trans>,
      previouscompanyID: "previouscompany",
      previouscompanyPlaceHolder: <Trans>CommonField.previouscompanyPlaceHolder</Trans>,
      previouscompanyValue: "",
      previouscompanyErrorpreviouscompany: false,
      previouscompanyErrorMsg: "Error in previouscompany",

      aboutcompanyLabel: <Trans>CommonField.aboutcompanyLabel</Trans>,
      aboutcompanyID: "aboutcompany",
      aboutcompanyPlaceHolder: <Trans>CommonField.aboutcompanyPlaceHolder</Trans>,
      aboutcompanyValue: "",
      aboutcompanyErroraboutcompany: false,
      aboutcompanyErrorMsg: "Error in aboutcompany",
      aboutcompanyFieldValidation: {
        minLength: 0,
        checkEmpty: true,
      },
      companynameLabel: <Trans>CommonField.companynameLabel</Trans>,
      companynameID: "companyname",
      companynamePlaceHolder: <Trans>CommonField.companynamePlaceHolder</Trans>,
      companynameValue: "",
      companynameErrorStatus: false,
      companynameErrorMsg: "Error in companyname",

      operatinginLabel: <Trans>CommonField.operatinginLabel</Trans>,
      operatinginID: "operatingin",
      operatinginPlaceHolder: <Trans>CommonField.operatinginPlaceHolder</Trans>,
      operatinginValue: "",
      operatinginErrorStatus: false,
      operatinginErrorMsg: "Error in operatingin",

      producturlLabel: <Trans>CommonField.producturlLabel</Trans>,
      producturlID: "producturl",
      producturlPlaceHolder: <Trans>CommonField.producturlPlaceHolder</Trans>,
      producturlValue: "",
      producturlErrorStatus: false,
      producturlErrorMsg: "Error in City",

      isrevenueLabel: <Trans>CommonField.isrevenueLabel</Trans>,
      isrevenueID: "isrevenue",
      isrevenuePlaceHolder: <Trans>CommonField.isrevenuePlaceHolder</Trans>,
      isrevenueValue: "",
      isrevenueErrorStatus: false,
      isrevenueErrorMsg: "Error in City",

      demolinkLabel: <Trans>CommonField.demolinkLabel</Trans>,
      demolinkID: "demolink",
      demolinkPlaceHolder: <Trans>CommonField.demolinkPlaceHolder</Trans>,
      demolinkValue: "",
      demolinkErrorStatus: false,
      demolinkErrorMsg: "Error in City",

      // address_idLabel: <Trans>CommonField.address_idLabel</Trans>,
      // address_idID: "address_id",
      // address_idPlaceHolder: <Trans>CommonField.address_idPlaceHolder</Trans>,
      // address_idValue: "",
      // address_idErrorStatus: false,
      // address_idErrorMsg: "Error in Address ID",

      businessplanLabel: <Trans>CommonField.businessplanLabel</Trans>,
      businessplanID: "businessplan",
      businessplanPlaceHolder: <Trans>CommonField.businessplanPlaceHolder</Trans>,
      businessplanValue: "",
      businessplanErrorbusinessplan: false,
      businessplanErrorMsg: "Error in businessplan",

      ndadocLabel: <Trans>CommonField.ndadocLabel</Trans>,
      ndadocID: "ndadoc",
      ndadocPlaceHolder: (
        <Trans>CommonField.ndadocPlaceHolder</Trans>
      ),
      ndadocValue: "",
      ndadocError: false,
      ndadocErrorMsg: "Error in ndadoc",

      contractdocLabel: <Trans>CommonField.contractdocLabel</Trans>,
      contractdocID: "contractdoc",
      contractdocPlaceHolder: (
        <Trans>CommonField.contractdocPlaceHolder</Trans>
      ),
      contractdocValue: "",
      contractdocErrorcontractdoc: false,
      contractdocErrorMsg: "Error in contractdoc",

      companylogoLabel: <Trans>CommonField.companylogoLabel</Trans>,
      companylogoID: "companylogo",
      companylogoPlaceHolder: <Trans>CommonField.companylogoPlaceHolder</Trans>,
      companylogoValue: "",
      companylogoErrorcompanylogo: false,
      companylogoErrorMsg: "Error in companylogo",

      profilePicture: "",
    };
  }

  render() {
    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditStartup.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/startups");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 16 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                    style={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  >
                    Personal Info
                  </Typography>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        paramValue="category_id"
                        required="required"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={this.state?.catagoriesList}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="contactname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <div>
                        <Typography>Profile Picture</Typography>

                        <img
                          src={
                            this.state.profilePicture == " "
                              ? userimage
                              : this.state.profilePicture
                          }
                          height={150}
                          width={150}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="contactemail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="contactphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="profileimage"
                          fileName={this.state.contactnameValue + "_profileimage"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={getImageMIMETypes()}

                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          id="incorporationdate"
                          label="Enter DOB"
                          format="yyyy-MM-dd"
                          openTo="year"
                          views={["year", "month", "date"]}
                          format="yyyy-MM-dd"
                          minDate={new Date("1920-03-01")}
                          selected={this.state.incorporationdateValue}
                          value={this.state.incorporationdateValue}
                          onChange={(e) => {
                            let value = moment(e).format("yyyy-MM-DD");
                            console.log("Current Date", e, value);
                            this.handleParam(value, "incorporationdate", true);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          error={this.state.incorporationdateErrorStatus}
                          helperText={
                            this.state.incorporationdateErrorStatus
                              ? this.state.incorporationdateErrorMsg
                              : ""
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        paramValue="status"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={getStatusMacros()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="aboutcompany"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="companyname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <div className="col-sm-12 col-md-6">
                      <FormControl
                        fullWidth
                        required={true}
                        error={false}
                      >
                        <InputLabel id="TagsValueID">
                          Select Tags For your Startup
                        </InputLabel>
                        <Select
                          required="required"
                          multiple
                          value={(this.state.tagsValue != undefined) ? this.state.tagsValue.map((item) => item.id) : []}
                          onChange={this.handleChange}
                          input={<Input id="select-multiple" />}
                        >
                          {this.state.tagsList.map(tag => (
                            <MenuItem
                              key={tag.name}
                              value={tag.id}
                            >
                              {tag.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomSelectField
                          paramValue="operatingin"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getCountryList()}
                        />
                      }
                    </Grid>


                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="is_permanent_addres"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: 1,
                            label: "Same as above",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isrevenue"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={getRadioMacros()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isproductused"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={getRadioMacros()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isfeatured"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={getRadioMacrosNew()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="is_portfoliocompany"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={getRadioMacrosNew()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="producturl"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="demolink"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>



                    {/* <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="address_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid> */}
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography
                        color="primary"
                        variant="h5"
                        size="sm"
                        style={{
                          borderBottom: "1px solid #000",
                          marginTop: 30,
                        }}
                      >
                        Documents
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Company Logo
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="companylogo"
                        fileName={this.state.contactnameValue + "_uploadedform"}
                        maxFileNo={2}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={getImageMIMETypes()}

                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        NDA Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="ndadoc"
                          fileName={this.state.contactnameValue + "_ndadoc"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={getMediaMIMETypes()}

                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Business Plan
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="businessplan"
                        fileName={this.state.contactnameValue + "_businessplan"}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={getMediaMIMETypes()}

                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="contractdoc"
                        fileName={this.state.contactnameValue + "_rationcard"}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={getMediaMIMETypes()}

                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>


                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "space-between" }}
                    >
                      {/* START ============================================================================== */}

                      <Divider style={{ margin: "5px 0 15px 0" }} />
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                        className={{ justifyContent: "center" }}
                      >
                        <Typography
                          color="primary"
                          variant="h5"
                          size="sm"
                          style={{
                            borderBottom: "1px solid #000",
                            marginTop: 30,
                            marginBottom: 30,
                          }}
                        >
                          Financial Data
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        alignItems="flex-start"
                        spacing={2}
                        mb={4}
                      >


                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                required="required"
                                paramValue="city"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            <CustomTextField
                              paramValue="state"
                              state={this.state}
                              onChange={this.handleParam}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="pincode"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="startuplinkdinprofile"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="noofcofounder"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="companysize"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="productoffering"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="fundingtype"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="revenuemodaltype"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="bussinessmodaltype"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="capitalraised"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="revenue"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="currentstage"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="ask"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>

                        </>
                      </Grid>

                      {/* END ============================================================================== */}


                    </Grid>
                  </Grid>



                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                    mt={2}
                  >
                    <Grid item style={{ marginTop: 25 }} xs={3}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Update Startup
                          </Button>
                        )}
                      </div>
                    </Grid>

                    <Grid item style={{ marginTop: 25 }} xs={12}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "space-between" }}
                    >
                      <MUIDataTable
                        title="Co-Founders"
                        data={this.state.cofounders}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "name",
                            label: "Name",
                          },
                          {
                            name: "designation",
                            label: "Designation",
                          },
                          {
                            name: "partnershippercentage",
                            label: "Equity",
                          },
                          {
                            name: "aboutcofounder",
                            label: "About",
                          },
                          {
                            name: "Actions",
                            options: {
                              filter: true,
                              sort: false,
                              empty: true,
                              customBodyRender: (
                                value,
                                tableMeta,
                                updateValue,
                              ) => {
                                return this.customActionRender(
                                  value,
                                  tableMeta,
                                  updateValue,
                                );
                              },
                            },
                          },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          fixedHeader: true,
                          filter: false,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                      // components={components}
                      />

                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Button
                          style={{ marginTop: 10 }}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            this.props.history.push("/app/addcofounder/" + this.props.match.params.startupId);
                          }}
                        >
                          Add Co-Founder
                        </Button>
                      </Grid>

                      <MUIDataTable
                        title="Uploaded Documents"
                        data={this.state.documents}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "title",
                            label: "Title",
                          },
                          {
                            name: "description",
                            label: "Description",
                          },

                          {
                            name: "Actions",
                            options: {
                              filter: false,

                              sort: false,
                              empty: true,
                              customBodyRender: (
                                value,
                                tableMeta,
                                updateValue,
                              ) => {
                                return this.customActionRenderDocument(
                                  value,
                                  tableMeta,
                                  updateValue,
                                );
                              },
                            },
                          },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          fixedHeader: true,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                      // components={components}
                      />
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        mt={2}
                      >
                        <Button
                          style={{ marginTop: 10 }}
                          variant="contained"
                          color="primary"
                          size="small"
                          mt={2}
                          onClick={() => {
                            this.props.history.push("/app/addstartupdocument/" + this.props.match.params.startupId);
                          }}
                        >
                          Add Document
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>




                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("is_corporated",
      { checkEmpty: false },
      "RadioField",
      0
    );



    this.setStateVariables("city", {
      checkEmpty: true,
      minLength: 0,
      maxLength: 20,
    });
    this.setStateVariables("state", {
      checkEmpty: true,
      minLength: 0,
      maxLength: 20,
    });
    this.setStateVariables("pincode", {
      checkEmpty: true,
      minLength: 0,
      maxLength: 20,

    });
    this.setStateVariables("startuplinkdinprofile", {
      checkEmpty: true,
      minLength: 0,
      maxLength: 120,
    });

    this.setStateVariables("noofcofounder", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("companysize", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 200,
    });


    this.setStateVariables("productoffering", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("fundingtype", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("revenuemodaltype", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("bussinessmodaltype", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("capitalraised", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("revenue", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("financial", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("currentstage", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("seeking", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("ask", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("keymemberslinkedinprofile", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });


    this.initialize();



  }

  initialize = async () => {
    await this.handleGetAllCategories();
    await this.handleGetStartup();
    await this.handleGetCoFounders();
    await this.handleGetStartupDocument();
    await this.handleGetAllTags();
  };

  handleGetAllCategories = async () => {
    let RC = false;
    let arrCategories = [];

    this.setState({ isFormLoading: true, catagoriesList: {} });
    let categories = await getCategoriesAPI();
    if (categories != undefined) {

      categories.forEach(element => {
        let tmp = {};
        tmp['value'] = element['id'];
        tmp['label'] = element['title'];
        arrCategories.push(tmp);
      });
      console.log("Categories found successfully", arrCategories);

      this.setState({
        catagoriesList: arrCategories,
        isFormLoading: false,
      });

    } else {
      console.log("Categories Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleGetAllTags = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, tagsList: [] });

    let tags = await getTagsAPI();
    if (tags != undefined && tags.length > 0) {
      // console.log("tags Found Successfully ::", tags.length);
      // const result = Object.values(tags);      
      // let result = Object.keys(tags).map((key) => tags[key]);
      // console.log("tags Found Successfully ::", result);
      this.setState({
        tagsList: tags,
        isFormLoading: false,
      });
      console.log("tags Table Data ", tags);
    } else {
      console.log("tags Not found");
      this.setState({ isFormLoading: false });
    }
  }

  handleChange = event => {
    let tmpTagsList = [];
    event.target.value.forEach(tagID => {
      let newelement = this.state.tagsList.find(obj => {
        console.log("Current event target value :: ", obj.id, " :: ", tagID);
        if (obj.id == tagID)
          return obj;
      });
      console.log("newelement ::", newelement);
      tmpTagsList.push(newelement);
    });
    this.setState({ tagsValue: tmpTagsList });

  };

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {} });
    let startup_id = this.props.match.params.startupId;

    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      let tmp = {};
      Object.entries(startupInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Startup Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["profileimageValue"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleSubmitButton = async (param) => {
    console.log("createStartup API Started");

    if (this.state['tagsValue'] != undefined) {
      let tmpTagsArr = [];
      this.state['tagsValue'].forEach(tagElem => {
        if (typeof value === 'object') {
          tmpTagsArr.push(tagElem.id);
        }
      });
      console.log("Current tmpTagsArr :: ", tmpTagsArr);
      param["tags"] = tmpTagsArr;
    }
    else
      param["tags"] = this.state['tagsValue'];

    let startup_id = this.props.match.params.startupId;
    let rc = await editStartupAPI(startup_id, param);
    if (rc == true) {
      console.log("Startup Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/startups");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Not Updated");
    }
    console.log("createStartup API ENDED");
  };

  handleGetCoFounders = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {} });
    let startup_id = this.props.match.params.startupId;

    let cofounders = await getCoFoundersAPI(startup_id);
    if (cofounders != undefined) {
      console.log("Co-Founders Found Successfully", cofounders);

      this.setState({
        cofounders: cofounders,
        isFormLoading: false,
      });
    } else {
      console.log("Co-Founders Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditCoFounder = (value, tableMeta, updateValue) => {
    this.props.history.push("/app/editCoFounder/" + this.props.match.params.startupId + "/" + tableMeta.rowData[0]);
  };

  handleDeleteCoFounder = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseCoFounder();
    let rc = await deleteCoFounderAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("CoFounder Deleted Successfully");
      this.handleGetCoFounders();
      toast.success("CoFounder Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("CoFounder Not Deleted");
  };

  handleCreateStartupDocument = async (param) => {
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.startupId;
    let rc = await createStartupDocumentAPI(startup_id, param);
    if (rc == true) {
      console.log("Startup Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editstartup");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Documfdoent Not Added");
    }
    console.log("createStartup API ENDED");
  };

  handleGetStartupDocument = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, documents: [] });
    let startup_id = this.props.match.params.startupId;

    let documents = await getStartupDocumentAPI(startup_id);
    if (documents != undefined) {
      console.log("Documents Found Successfully", documents);
      this.setState({
        documents: documents,
        isFormLoading: false,
      });
    } else {
      console.log("Startup Document Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditStartupDocument = async (value, tableMeta, updateValue) => {
    this.props.history.push("/app/editdocument/" + this.props.match.params.startupId + "/" + tableMeta.rowData[0]);
  };

  handleDeleteStartupDocument = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseStartupDocument();
    let rc = await deleteStartupDocumentAPI(tableMeta.rowData[0]);
    if (rc == true) {
      this.handleGetStartupDocument();

      console.log("Startup Document Deleted Successfully");
      toast.success("Document Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("Document Not Deleted");
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditCoFounder(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit CoFounder" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Member "' + tableMeta.rowData[1] + '"',
              );
              if (retVal == true) {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          // onClick={() =>
          //   this.handleDeleteDialogMember(value, tableMeta, updateValue)
          // }
          >
            <Tooltip title="Delete Current CoFounder" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogCoFounder}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseCoFounder}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting CoFounder
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCoFounder} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  customActionRenderDocument = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditStartupDocument(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit StartupDocument" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Document "' +
                tableMeta.rowData[1] +
                '"',
              );
              if (retVal == true) {
                this.handleDeleteStartupDocument(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          // onClick={() =>
          //   this.handleDeleteDialogDocument(value, tableMeta, updateValue)
          // }
          >
            <Tooltip title="Delete Current StartupDocument" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogStartupDocument}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseStartupDocument}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting StartupDocument
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStartupDocument} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteStartupDocument(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  handleDeleteDialogDocument = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogStartupDocument: true });
  };

  handleCloseStartupDocument = () => {
    this.setState({ deleteDialogStartupDocument: false });
  };

  handleCloseCoFounder = () => {
    this.setState({ deleteDialogCoFounder: false });
  };

  handleDeleteDialogMember = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };
}
