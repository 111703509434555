import React from "react";
import axios from "axios";
import API, { API_Param } from "./../API";

export { getRolesAPI, addRolesAPI, getRoleByIdAPI, editRoleAPI };

async function getRolesAPI() {
  let roles = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("role");
    if (response.status == 200) {
      console.log("Response from  Role API :: ", response.data);
      return response.data;
    } else {
      console.log("Role API Request Failed With Status :: ", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("Role API Request Failed With Status :: ", error);

    return roles;
  }
}

async function addRolesAPI(fullname, displayname, description) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "role",
      {
        name: fullname,
        display_name: displayname,
        description: description,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from  Role API :: ", response.data);
      return response.data;
    } else {
      console.log("Role API Request Failed With Status :: ", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("Role API Request Failed With Status :: ", error);

    return false;
  }
}

async function getRoleByIdAPI(role_id) {
  let roles = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("role/" + role_id);
    if (response.status == 200) {
      console.log("Response from  getRoleById API :: ", response.data);
      return response.data;
    } else {
      console.log(
        "getRoleById API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log("getRoleById API Request Failed With Status :: ", error);

    return roles;
  }
}

async function editRoleAPI(
  role_id,
  fullname,
  displayname,
  description,
  permissions,
) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.put(
      "role/" + role_id,
      {
        name: fullname,
        display_name: displayname,
        description: description,
        permissions: permissions,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from editRole API :: ", response.data);
      return response.data;
    } else {
      console.log(
        "editRole API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log("editRole API Request Failed With Status :: ", error);

    return false;
  }
}
