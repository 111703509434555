import React, { Component } from "react";

import PageTitle from "../../../components/PageTitle/PageTitle";

// FOR APIS
import {
  getPermissionsAPI,
  addPermissionAPI,
} from "./../../../APIs/permissionsApis";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  CircularProgress,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

class Permissions extends Component {
  constructor() {
    super();
    this.state = {
      fullname: "",
      displayname: "",
      description: "",
      permissions: [],
      loader: true,
      errorLoader: true,
      responseText: "",
      responseFlag: false,
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.PermissionsDetails();
  }

  PermissionsDetails = async () => {
    let details = await getPermissionsAPI();
    if (details.status == "success") {
      this.setState({ permissions: details.response.permissions });
      console.log("Role details in roles file:" + JSON.stringify(details));
      this.setState({ loader: false });
    } else {
      this.setState({ errorLoader: false });
      this.setState({ loader: false });
    }
  };
  setfullName = (e) => {
    this.setState({ fullname: e.target.value });
    console.log("the full name is :" + e.target.value);
  };

  setdisplayName = (e) => {
    this.setState({ displayname: e.target.value });
    console.log("the display name is :" + e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    console.log("the description is :" + e.target.value);
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleSubmit = async () => {
    if (
      this.state.fullname.length > 0 &&
      this.state.displayname.length > 0 &&
      this.state.description.length > 0
    ) {
      let response = await addPermissionAPI(
        this.state.fullname,
        this.state.displayname,
        this.state.description,
      );
      if (response.data.status) {
        this.setState({
          fullname: "",
          displayname: "",
          description: "",
          responseFlag: true,
          responseText: "Permission created successfully !",
        });
        this.PermissionsDetails();
        console.log(
          "Response of add permission is** :" + JSON.stringify(response),
        );
      } else {
        this.setState({
          responseFlag: true,
          responseText: "Something went wrong , please try again !",
        });
      }
    }
  };

  render() {
    return (
      <div>
        {/* <p>Hi, This is roles page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Permissions"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/");
                  }}
                >
                  <HomeIcon /> Home
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.loader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.errorLoader ? (
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            label="Permission Name"
                            id="name"
                            name="name"
                            // placeholder="enter your name"
                            value={this.state.fullname}
                            // placeholder=""
                            onChange={this.setfullName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            label="Display Name"
                            id="display name"
                            name="display name"
                            placeholder="display name"
                            value={this.state.displayname}
                            onChange={this.setdisplayName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextareaAutosize
                            label="Permission Description"
                            placeholder="Permission Description"
                            id="description"
                            name="description"
                            rowsMin={3}
                            value={this.state.description}
                            onChange={this.setDescrition}
                            style={{ fontSize: "20px" }}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{
                        justifyContent: "flex-start",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                      {this.state.responseFlag ? (
                        <Typography
                          color="primary"
                          variant="h5"
                          size="sm"
                          style={{
                            borderBottom: "2px solid #FF7F50",
                            marginTop: 30,
                          }}
                        >
                          {this.state.responseText}
                        </Typography>
                      ) : (
                        <Typography
                          color="primary"
                          variant="h5"
                          size="sm"
                          style={{
                            borderBottom: "2px solid #FF7F50",
                            marginTop: 30,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Permission Name
                              </TableCell>
                              <TableCell align="left">Description</TableCell>
                              <TableCell align="left">Edit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.permissions
                              .slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage,
                              )
                              .map((row) => (
                                <TableRow>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell>{row.description}</TableCell>
                                  <TableCell>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() => {
                                        this.props.history.push({
                                          pathname: "/app/editpermission/" + row.id,
                                          state: [{ id: row.id }], // your data array of objects
                                        });
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={this.state.permissions.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        className="pagination"
                        style={{ float: "right" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <div>
                  <p>Something went wrong,please try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default Permissions;
