import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";
import { displayResponseError } from "./ApiUtils";
// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {
  getAllPaymentsAPI,
  getPaymentsAPI,
  getPaymentAPI,
  addPaymentAPI
};


async function getAllPaymentsAPI() {
  let payments = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("payment");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      // response.data.response.payments.map((payment) => {        
      //   payment['name'] = payment.donor['name'];
      //   payment['user_id'] = payment.donor['user_id'];
      //   payments.push(payment);
      // });
      return response.data.response.payments;      
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return undefined;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return undefined;
  }
}

async function getPaymentsAPI(param) {
  let donor = undefined;
  let token = localStorage.getItem("id_token");
  let id_donor = param.id;

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("donor/payments/" + id_donor);
    if (response.status == 200) {
      console.log("Response from getPayments API :: ", response.data.response);
      return response.data.response.payments;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getPaymentAPI(param) {
  let token = localStorage.getItem("id_token");
  let id_payment = param.id;


  
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("payment/" + id_payment);
    if (response.status == 200) {
      console.log("Response from getPayments API :: ", response.data.response);
      return response.data.response.payment;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function addPaymentAPI(param) {
    let token = localStorage.getItem("id_token");
    let id_payment = param.id;
  
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    try {
      let response = await API.get("payment/" + id_payment);
      if (response.status == 200) {
        console.log("Response from getPayments API :: ", response.data.response);
        return response.data.response.payment;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      toast.error("Error : " + error);
      return false;
    }
  }
