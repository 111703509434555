import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getDesignationDataArray,  getImageMIMETypes, getMediaMIMETypes,  } from "../../helpers";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Slide,
  CircularProgress,
} from "@material-ui/core";

// FOR APIS  ====================================================================
import {
  getStartupsAPI,
  getStartupAPI,
  editStartupDocumentAPI,
  getStartupDocumentByIDAPI,
} from "../../APIs/startupApis";

// IMAGES  ====================================================================
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

const requiredParam = ["title", "description", "file"];

export default class EditStartupDocument extends Component {
  constructor() {
    super();
    this.state = {
      startupInfo: [],
      loader: false,
      deleteDialog: false,
      isSubmitLoading: false,
      isError: false,
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          
          <div>
            <Grid container spacing={4}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditStartupDocument.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/editstartup/"+ this.props.match.params.startupId);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h5"
                      size="sm"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      Personal Info
                    </Typography>
                  </Grid>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="title"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="description"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="file"
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                            "application/pdf",
                            "video/x-flv",
                            "video/mp4",
                            "application/x-mpegURL",
                            "video/MP2T",
                            "video/3gpp",
                            "video/quicktime",
                            "video/x-msvideo",
                            "video/x-ms-wmv",
                            "application/octet-stream",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Update Document
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {/* <Grid item xs={12}>
            <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
              <Table data={mock.table} />
            </Widget>
          </Grid> */}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("title", {checkEmpty: false,minLength: 0,maxLength: 50,});
    this.setStateVariables("description", {checkEmpty: false,minLength: 0,maxLength: 250,});
    this.setStateVariables("file", { checkEmpty: false }, "FileField");
   
    this.handleGetStartup();
    this.handleGetStartupDocument();
  }

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ loader: true, startupInfo: [] });
    let startup_id = this.props.match.params.startupId;

    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      console.log("Startup Found Successfully");
      this.setState({
        startupInfo: startupInfo,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  handleGetStartupDocument = async () => {
    let RC = false;
    this.setState({ loader: true, startupDocumentInfo: [] });
    let document_id = this.props.match.params.documentId;

    let startupDocumentInfo = await getStartupDocumentByIDAPI(document_id);
    if (startupDocumentInfo != undefined) {
      let tmp = {};
      Object.entries(startupDocumentInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log("Startup Found Successfully", tmp);

      this.setState({
        ...tmp,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  handleSubmitButton = async (param) => {
    let document_id = this.props.match.params.documentId;
    console.log("createStartup API Started", document_id);
    let rc = await editStartupDocumentAPI(document_id, param);
    if (rc == true) {
      console.log("Startup Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editstartup/"+ this.props.match.params.startupId);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Document  Not Added");
    }
    console.log("createStartupDocument API ENDED");
  };

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false,
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "AddMember." + param + "Label";
    let transPlaceHolder = "AddMember." + param + "PlaceHolder";
    let transErrorMsg = "AddMember." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };


}
