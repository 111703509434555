import React from "react";
import axios from "axios";
import API, { API_Param } from "./../API";

export { getGeneralSettingsAPI, addGeneralSettingsAPI, getGeneralSettingByIdAPI, editGeneralSettingAPI };

async function getGeneralSettingsAPI() {
  let generalsettings = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("setting");
    if (response.status == 200) {
      console.log("Response from  GeneralSetting API :: ", response.data);
      return response.data;
    } else {
      console.log("GeneralSetting API Request Failed With Status :: ", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("GeneralSetting API Request Failed With Status :: ", error);

    return generalsettings;
  }
}

async function addGeneralSettingsAPI(name, value, description) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "setting",
      {
        name: name,
        value: value,
        description: description,
        type: "ADMIN",        
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from  GeneralSetting API :: ", response.data);
      return response.data;
    } else {
      console.log("GeneralSetting API Request Failed With Status :: ", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("GeneralSetting API Request Failed With Status :: ", error);

    return false;
  }
}

async function getGeneralSettingByIdAPI(generalsetting_id) {
  let generalsettings = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("setting/" + generalsetting_id);
    if (response.status == 200) {
      console.log("Response from  getGeneralSettingById API :: ", response.data);
      return response.data;
    } else {
      console.log(
        "getGeneralSettingById API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log("getGeneralSettingById API Request Failed With Status :: ", error);

    return generalsettings;
  }
}

async function editGeneralSettingAPI(
  generalsetting_id,
  name,
  value,
  description,
  type,
) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.put(
      "setting/" + generalsetting_id,
      {
        name: name,
        value: value,
        description: description,
        type: "ADMIN", 
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from editGeneralSetting API :: ", response.data);
      return response.data;
    } else {
      console.log(
        "editGeneralSetting API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log("editGeneralSetting API Request Failed With Status :: ", error);

    return false;
  }
}
