import React, { Fragment, useState, useEffect, Component } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useHistory, useParams } from "react-router-dom";
import ReactDOM from "react-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getBookingAPI,
} from "../../APIs/bookingApis";

import {
  currencySymbol,
  getLocaleShortDateString,
} from "../../helpers";

function ViewBooking() {

  const history = useHistory();
  const params = useParams();
  const [bookingId, SetBookingId] = useState(undefined);
  const [bookingInfo, SetBookingInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    handleGetBooking();
  }, []);

  return (
    <>
      {
        isFormLoading ? (
          <div className={{ alignContents: "center", }}>
            <CircularProgress variant="indeterminate" value={75} />
            {/* <CircularStatic value={25} /> */}
          </div>
        ) : (
          <div className="container ">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-white">
                  <div className="card-heading clearfix border-bottom my-3 mx-3">
                    <h4 className="card-title">Booking Requests</h4>
                  </div>
                  <div className="card-body">
                    <div className="mt-4">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <th scope="row">Member Name</th>
                            <td>
                              <Link to={"/member/" + (bookingInfo?.appointment['member']['id'])} className="text-tertiary">
                                {bookingInfo?.appointment['member']['membername']}
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Status</th>
                            <td>
                              {bookingInfo?.status}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Amount</th>
                            <td>
                              {currencySymbol() + bookingInfo?.payment?.amount}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Transaction ID</th>
                            <td>
                              {bookingInfo?.payment?.transaction_id}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Session Date</th>
                            <td>{getLocaleShortDateString(bookingInfo?.appointment['date'])}</td>
                          </tr>
                          <tr>
                            <th scope="row">Session Timing</th>
                            <td>{"From " + (bookingInfo?.appointment['starttime'])?.slice(0, 5) + " To " + (bookingInfo?.appointment['endtime'])?.slice(0, 5)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Service name</th>
                            <td>
                              <Link to={"/service/" + (bookingInfo?.service['id'])} className="text-tertiary">
                                {bookingInfo?.service['title']}
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  )


  async function handleGetBooking() {
    let RC = false;

    setIsFormLoading(true);
    let booking_id = params.bookingId;
    let bookingInfo = await getBookingAPI({ id: booking_id });

    if (bookingInfo != undefined) {
      console.log("Booking  Found Successfully", bookingInfo);
      SetBookingInfo(bookingInfo);
      setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };

}

export default ViewBooking;

