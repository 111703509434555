import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  Settings as SettingsIcon,
  ArrowBack as ArrowBackIcon,
  Lock as LockIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    // role: ["ADMIN"],
  },
  {
    id: 1,
    label: "Persona",
    link: "",
    icon: <SettingsIcon />,
    children: [
      { label: "Startups", link: "/app/startups" },      
      { label: "Investors", link: "/app/investors" },      
      { label: "Members", link: "/app/members" },      
      { label: "Vendors", link: "/app/vendors" },
    ],
    // role: ["ADMIN"],
  },
  {
    id: 2,
    label: "Inventory",
    link: "",
    icon: <SettingsIcon />,
    children: [
      { label: "Services", link: "/app/services" },      
    ],
    // role: ["ADMIN"],
  },
  {
    id: 3,
    label: "Content",
    link: "",
    icon: <SettingsIcon />,
    children: [
      { label: "Category", link: "/app/blog/categories" },      
      { label: "Blog", link: "/app/blogs" },      
      { label: "FAQ", link: "/app/faqs" },      

    ],
    // role: ["ADMIN"],
  },
  {
    id: 15,
    label: "Checkout",
    link: "",
    icon: <SettingsIcon />,
    children: [
      { label: "Bookings", link: "/app/bookings" },      
      { label: "Payments", link: "/app/payments" },      
    ],
    // role: ["ADMIN"],
  },
  { id: 12, type: "divider" },
  { id: 5, type: "divider", role: ["ADMIN"] },
  // { id: 6, type: "title", label: "Settings", role: ["ADMIN"] },
  // { id: 12, type: "divider", role: ["ADMIN"] },
  {
    id: 7,
    label: "System Settings",
    link: "",
    icon: <SettingsIcon />,
    children: [
      { label: "Roles", link: "/app/roles" },
      { label: "Permissions", link: "/app/permissions" },
      { label: "General Settings", link: "/app/generalsettings" },      
    ],
    // role: ["ADMIN"],
  },
  {
    id: 8,
    label: "Team",
    link: "",
    icon: <PeopleIcon />,
    children: [ 
                { label: "Team", link: "/app/admins" },
                // { label: "Team", link: "/app/viewteam" }
              ],
    // role: ["ADMIN", "MEMBER"],
  },
  {
    id: 9,
    label: "Notifications",
    link: "/app/notification",
    icon: <NotificationsIcon />,
    // role: ["ADMIN"],
  },
  // {
  //   id: 4,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  //   // role: ["ADMIN", "MEMBER"],
  // },
  {
    id: 10,
    label: "Support",
    link: "",
    icon: <SupportIcon />,
    // role: ["ADMIN"],
  },
  { id: 11, label: "FAQ", link: "", icon: <FAQIcon />, role: ["ADMIN"] },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => {
          let userRole = localStorage.getItem("user_role");
          var userRoleArr = userRole.split(",");
          // console.log("<USER ROLE :: >", userRoleArr);
          // console.log("<LINK ROLE :: >", link["role"]);

          if (link["role"] != undefined) {
            let isFounded = userRoleArr.some((ai) => link["role"].includes(ai));
            // console.log("<LINK ROLE> :: ", isFounded);
            if (isFounded) {
              return (
                <SidebarLink
                  key={link.id}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              );
            }
          } else {
            return (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            );
          }
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
