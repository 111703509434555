import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as mime from "react-native-mime-types";

import { Trans } from "react-i18next";
import API, { API_Param } from "./../API";

export {
  ValidateFields,
  displayResponseError,
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  setStateVariables,
  downloadFileAPI,
  getImageAPI,
  getDashboardDataAPI,
  getCategoriesAPI
};

function displayResponseError(response) {
  if (response.data != undefined) {
    if (response.data.msg != undefined) toast.error(response.data.msg);

    if (response.data.errors != undefined) {
      if (
        Object.prototype.toString.call(response.data.errors) ===
        "[object Object]"
      )
        Object.entries(response.data.errors).map(([key, value]) => {
          toast.error("Error : " + value);
        });
      else toast.error(response.data.errors);
    }
  }
}

function isErrorExist(param) {
  let isErrorExist = false;

  Object.entries(param).map(([key, value]) => {
    if (key.includes("ErrorStatus") && value == true) {
      console.log("<ERROR> Error exist for Field ::", key);
      isErrorExist = true;
    }
  });

  if (isErrorExist) {
    console.log("Error Exist in Form");
    return true;
  } else {
    console.log("Error Not Exist in Array");
    return false;
  }
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function checkTextField(param) {
  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Please enter a value" };
  if (
    param.value != undefined &&
    param.validation.maxLength != undefined &&
    param.value.length > param.validation.maxLength
  )
    return {
      isError: true,
      errorMsg: "Max length allowed is " + param.validation.maxLength,
    };
  if (
    param.value != undefined &&
    param.validation.minLength != undefined &&
    param.value.length < param.validation.minLength
  )
    return {
      isError: true,
      errorMsg: "Min length allowed is " + param.validation.minLength,
    };
  if (param.validation.checkSting && !/[^a-zA-Z]/.test(param.value))
    return { isError: true, errorMsg: "Contain non letter also" };

  return { isError: false, errorMsg: "" };
}

function checkDateField(param) {
  let currentAge = getAge(param.value);

  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Please enter a value" };
  if (
    param.value != undefined &&
    param.validation.maxAge != undefined &&
    currentAge > param.validation.maxAge
  )
    return {
      isError: true,
      errorMsg: "Maximum Age allowed " + param.validation.maxAge,
    };
  if (
    param.value != undefined &&
    param.validation.minAge != undefined &&
    currentAge < param.validation.minAge
  )
    return {
      isError: true,
      errorMsg: "Minimum Age allowed " + param.validation.minAge,
    };

  return { isError: false, errorMsg: "" };
}

function checkNumberField(param) {
  if (
    param.validation.checkEmpty &&
    (param.value == undefined || param.value == "")
  )
    return { isError: true, errorMsg: "Please enter a value" };
  if (
    param.value != undefined &&
    param.validation.max != undefined &&
    param.value > param.validation.max
  )
    return {
      isError: true,
      errorMsg: "Maximum allowed " + param.validation.max,
    };
  if (
    param.value != undefined &&
    param.validation.min != undefined &&
    param.value < param.validation.min
  )
    return {
      isError: true,
      errorMsg: "Minimum allowed " + param.validation.min,
    };

  return { isError: false, errorMsg: "" };
}

function checkRadioField(param) {
  if (
    param.validation.checkEmpty &&
    (param.value == undefined || param.value == "")
  )
    return { isError: true, errorMsg: "Select an option" };

  return { isError: false, errorMsg: "" };
}

function checkSelectField(param) {
  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Select an option" };

  return { isError: false, errorMsg: "" };
}

function checkFileField(param) {
  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Please upload a file" };
  if (
    param.value != undefined &&
    param.validation.maxLength != undefined &&
    param.value.length > param.validation.maxLength
  )
    return {
      isError: true,
      errorMsg: "Max length allowed is " + param.validation.maxLength,
    };
  if (
    param.value != undefined &&
    param.validation.minLength != undefined &&
    param.value.length < param.validation.minLength
  )
    return {
      isError: true,
      errorMsg: "Min length allowed is " + param.validation.minLength,
    };
  if (
    param.value != undefined &&
    param.validation.checkSting &&
    !/[^a-zA-Z]/.test(param.value)
  )
    return { isError: true, errorMsg: "Contain non letter also" };

  return { isError: false, errorMsg: "" };
}

function ValidateFields(thisObject) {
  let errors = false;

  Object.entries(thisObject.state).map(([key, value]) => {
    if (key.includes("FieldType")) {
      let paramName = key.replace("FieldType", "");

      let rc = { isError: false };

      if (thisObject.state[paramName + "FieldType"] == "TextField") {
        rc = checkTextField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "NumberField") {
        rc = checkNumberField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "RadioField") {
        rc = checkRadioField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "SelectField") {
        rc = checkSelectField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "DateField") {
        rc = checkDateField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "FileField") {
        rc = checkFileField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (rc.isError) {
        console.log("<ERROR> Feild Name : ", paramName);

        let errStatus = paramName + "ErrorStatus";
        let errMsg = paramName + "ErrorMsg";
        thisObject.setState({
          isError: true,
          [errStatus]: true,
          [errMsg]: rc.errorMsg,
        });
        errors = true;
      } else {
        let errStatus = paramName + "ErrorStatus";
        let errMsg = paramName + "ErrorMsg";
        thisObject.setState({
          [errStatus]: false,
          [errMsg]: "",
        });
      }
    }
  });
  if (thisObject.state.isError && !errors)
    thisObject.setState({ isError: false });
  return errors;
}

function setStateVariables(
  param,
  validation = { checkEmpty: true },
  type = "TextField",
  value = "",
  error = false,
) {
  let paramLabel = param + "Label";
  let paramID = param + "ID";
  let paramPlaceHolder = param + "PlaceHolder";
  let paramValue = param + "Value";
  let paramErrorStatus = param + "ErrorStatus";
  let paramErrorMsg = param + "ErrorMsg";
  let paramFieldType = param + "FieldType";
  let paramFieldValidation = param + "FieldValidation";

  let transLabel = "CommonField." + param + "Label";
  let transPlaceHolder = "CommonField." + param + "PlaceHolder";
  let transErrorMsg = "CommonField." + param + "ErrorMsg]";

  this.setState({
    [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
    [paramID]: param,
    [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
    [paramValue]: value,
    [paramErrorStatus]: error,
    [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
    [paramFieldType]: type,
    [paramFieldValidation]: validation,
  });
}

async function downloadFileAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, {
      responseType: "blob",
    });
    if (response != undefined) {
      console.log("Response from API :: ", response);
      if (response.status == 200) {
        let blob = response.data;

        if (blob) {
          //  GET FILE EXTENTION FROM MIME
          let ext = mime.extension(response.data.type);
          console.log("<INFO> Extenstion Type ", response.data.type);
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", param.fileName + "." + ext);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
        toast.success("Document downloaded Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getImageAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, {
      responseType: "blob",
    });

    if (response != undefined) {
      console.log("Response from getImage API :: ", response);
      if (response.status == 200) {
        let blob = response.data;

        if (blob) {
          const url = URL.createObjectURL(new Blob([blob]));
          return url;
        }
        // return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getDashboardDataAPI(param) {
  let token = localStorage.getItem("id_token");
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.get("dashboarddata", param);

    if (response != undefined) {
      console.log("Response from dashboarddata API :: ", response);
      if (response.status == 200) {
        return response.data;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        return undefined;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return undefined;
  }
}

async function getCategoriesAPI() {
  let categories = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("category");
    if (response.status == 200) {
      categories = response.data.response.categorys;
      console.log("Response from  API :: ", categories);
      return categories;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return categories;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

