import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getCoFounderDataArray,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  CircularProgress,
  Slide
} from "@material-ui/core";

// FOR APIS  ====================================================================
import {
  getStartupsAPI,
  createStartupAPI,
  getStartupAPI,
  createStartupCoFounderAPI,
  getCoFounderAPI,
  editStartupCoFounderAPI,
} from "../../APIs/startupApis";

// IMAGES  ====================================================================



import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
} from "../../APIs/ApiUtils";

const requiredParam = [
  "name",
  "designation",
  "partnershippercentage",
  "aboutcofounder",
  "profileimage",
  "otherdocument",
];

class EditCoFounder extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      isSubmitLoading: false,
      isError: false,

      designationTable: getCoFounderDataArray(),
    };
  }

  componentDidMount() {
    this.setStateVariables("name", {checkEmpty: true,maxLength: 20,});
    this.setStateVariables("designation",{checkEmpty: true,},"SelectField",);
    this.setStateVariables("partnershippercentage",{ checkEmpty: false },"NumberField",);
    this.setStateVariables("aboutcofounder", { checkEmpty: false });
    this.setStateVariables("profileimage", { checkEmpty: false }, "FileField");
    this.setStateVariables("otherdocument", { checkEmpty: false }, "FileField");

    this.handleGetStartup();
    this.handleGetCoFounder();
  }

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={4}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditFamilyMemeber.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/editstartup");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <Typography>Personal Info</Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="name"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                      required="required"
                        paramValue="designation"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={this.state.designationTable}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="partnershippercentage"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="aboutcofounder"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Documents
                      </Typography>
                      <Divider />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Profile Image
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="profileimage"
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                            "application/pdf",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Other Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="otherdocument"
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Update Co-Founder
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ loader: true, cofounderInfo: [] });
    let startup_id = this.props.match.params.startupId;

    let cofounderInfo = await getStartupAPI(startup_id);
    if (cofounderInfo != undefined) {
      console.log("Startup Found Successfully");
      this.setState({
        cofounderInfo: cofounderInfo,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  handleGetCoFounder = async () => {
    let RC = false;
    this.setState({ loader: true, cofounderInfo: [] });
    let cofounderId = this.props.match.params.cofounderId;

    let cofounderInfo = await getCoFounderAPI(cofounderId);
    if (cofounderInfo != undefined) {
      let tmp = {};
      Object.entries(cofounderInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log("Startup Found Successfully", tmp);

      this.setState({
        ...tmp,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  
  handleSubmitButton = async (param) => {
    console.log("createStartup API Started");
    let cofounderId = this.props.match.params.cofounderId;
    console.log("<MEMBER ID> :: ", cofounderId);

    let rc = await editStartupCoFounderAPI(cofounderId, param);
    if (rc == true) {
      console.log("Startup Co-Founder Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editstartup/"+this.props.match.params.startupId);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Co-Founder  Not Added");
    }
    console.log("createStartupCoFounder API ENDED");
  };

}

export default EditCoFounder;
