import React, { Component, Fragment } from "react";

import PageTitle from "../../../components/PageTitle/PageTitle";

// FOR APIS
import {
  getRolesAPI,
  addRolesAPI,
  getRoleByIdAPI,
  editRoleAPI,
} from "./../../../APIs/rolesApis";
import { getPermissionsAPI } from "./../../../APIs/permissionsApis";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
  isWidthUp,
} from "@material-ui/core";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

class EditRoles extends Component {
  constructor() {
    super();
    this.state = {
      fullname: "",
      displayname: "",
      description: "",
      rolepermissions: [],
      permissions: [],
      checked: false,
      errorLoader: true,
      roleLoader: true,
      permissionLoader: true,
      allpermissions: [],
    };
  }

  componentWillMount() {
    let roleId = this.props.match.params.roleId;
    this.getRoleDetails(roleId);
  }

  getRoleDetails = async (id) => {
    let details = await getRoleByIdAPI(id);
    console.log(
      "this is role by id response status in editroles" + details.status,
    );
    if (details.status == "success") {
      let tempArray = [];
      for (let i = 0; i < details.response.role.permissions.length; i++) {
        tempArray.push(details.response.role.permissions[i].id);
      }

      this.setState({ rolepermissions: tempArray });
      this.setState({
        fullname: details.response.role.name,
        displayname: details.response.role.display_name,
        description: details.response.role.description,
        // rolepermissions:details.role.permissions
        roleLoader: false,
      });
      this.getPermissonsDetails();
    } else {
      this.setState({
        errorLoader: false,
        roleLoader: false,
        permissionLoader: false,
      });
    }
    // console.log("getRoleByID details in edit roles file:"+JSON.stringify(this.state.rolepermissions))
  };

  getPermissonsDetails = async () => {
    let details = await getPermissionsAPI();
    console.log(
      "all permissions details in edit role file is :" +
        JSON.stringify(details),
    );

    if (details.status == "success") {
      this.setState({ permissions: details.response.permissions });
      this.buildPermissionArray();
    } else {
      this.setState({ errorLoader: false });
    }
  };

  buildPermissionArray = async () => {
    console.log(
      "the permissions of specific role is" +
        JSON.stringify(this.state.rolepermissions),
    );
    let tempArray = [];
    for (let i = 0; i < this.state.permissions.length; i++) {
      const tempObj = new Object();
      tempObj.id = this.state.permissions[i].id;
      tempObj.name = this.state.permissions[i].name;
      tempObj.display_name = this.state.permissions[i].display_name;
      let status = this.state.rolepermissions.includes(
        this.state.permissions[i].id,
      );
      tempObj.isChecked = status;
      tempArray.push(tempObj);

      // console.log("status flag in buildpermissionarray is:"+JSON.stringify(tempArray))
    }
    this.setState({ allpermissions: tempArray });

    // console.log("allpermissions in buildpermissionarray is:"+JSON.stringify(this.state.allpermissions))
    this.setState({ permissionLoader: false });
  };

  setfullName = (e) => {
    this.setState({ fullname: e.target.value });
    // console.log("the full name is :"+e.target.value);
  };

  setdisplayName = (e) => {
    this.setState({ displayname: e.target.value });
    // console.log("the display name is :"+e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    // console.log("the description is :"+e.target.value);
  };

  handleCheckBoxChange = (name) => {
    let curData = this.state.allpermissions;
    let id = curData.findIndex(function (elem) {
      return elem.name == name;
    });
    curData[id].isChecked = !curData[id].isChecked;
    this.setState({ allpermissions: curData });
    console.log(
      "the id and value from checkBox is" +
        JSON.stringify(this.state.allpermissions),
    );
  };

  handleSubmit = async () => {
    let roleId = this.props.match.params.roleId;
    let temp = this.state.allpermissions.filter((x) => x.isChecked === true);
    temp.forEach(function (v) {
      delete v.isChecked;
    });
    console.log("the updated selected permissions is:" + JSON.stringify(temp));
    if (
      this.state.fullname.length > 0 &&
      this.state.displayname.length > 0 &&
      this.state.description.length > 0
    ) {
      let response = await editRoleAPI(
        roleId,
        this.state.fullname,
        this.state.displayname,
        this.state.description,
        temp,
      );
      console.log("Response of edit roles is :" + JSON.stringify(response));
      if (response.status == "success") {
        this.setState({
          fullname: "",
          displayname: "",
          description: "",
          allpermissions: [],
        });
        this.props.history.push("/app/roles");
      }
    }
  };

  render() {
    const data = this.state.allpermissions;
    return (
      <div>
        {/* <p>Hi, This is roles page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Edit Role"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/roles");
                  }}
                >
                  Back
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.roleLoader || this.state.permissionLoader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.errorLoader ? (
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Role Name"
                            id="name"
                            name="name"
                            // placeholder="enter your name"
                            value={this.state.fullname}
                            // placeholder=""
                            onChange={this.setfullName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Display Name"
                            id="display name"
                            name="display name"
                            placeholder="display name"
                            value={this.state.displayname}
                            placeholder=""
                            onChange={this.setdisplayName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          {/* <TextField
                           fullWidth
                           variant="outlined" 
                           label="Description"
                           id="description"
                           name="description"
                           value={this.state.description}
                        //    placeholder=""      
                           onChange={this.setDescrition}
                       /> */}

                          <TextareaAutosize
                            //aria-label="minimum height"
                            //  className="border10 "
                            label="Description"
                            placeholder="Description"
                            id="description"
                            name="description"
                            rowsMin={3}
                            value={this.state.description}
                            onChange={this.setDescrition}
                            style={{ fontSize: "20px" }}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{
                        justifyContent: "flex-start",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <Typography
                        color="primary"
                        variant="h5"
                        size="sm"
                        style={{
                          borderBottom: "1px solid #000",
                          marginTop: 30,
                        }}
                      ></Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <Typography
                        variant="h5"
                        size="sm"
                        style={{ marginTop: 30 }}
                      >
                        Add/Delete Permissions
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <FormGroup row>
                        {this.state.allpermissions.map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.isChecked}
                                onChange={() =>
                                  this.handleCheckBoxChange(
                                    item.name,
                                    item.isChecked,
                                  )
                                }
                                name={item.name}
                                color="primary"
                              />
                            }
                            label={item.name}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    <Grid
                      align="right"
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <div>
                  <p>Something went wrong,please try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default EditRoles;
