import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Button,
  CircularProgress,
  Slide,
  Grid,
  Divider,
  Paper
} from "@material-ui/core";
// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// FOR APIS  ====================================================================
import { getBlogCategoriesAPI, createBlogCategoryAPI } from "../../APIs/blogcategoryApis";
import {  getStatusMacros, getRadioMacros, getCountryList, getDocumentMIMETypes } from "../../helpers";
import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getCategoriesAPI
} from "../../APIs/ApiUtils";

// IMAGES  ====================================================================



class AddBlogcategory extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      uploadersTable: [],
      isSubmitLoading: false,
      isError: false,
    };
  }

  componentDidMount() {
    this.setStateVariables("title", {checkEmpty: true,minLength: 5,maxLength: 60,});
    this.setStateVariables("isactive",{checkEmpty: true,},"SelectField",);
    this.setStateVariables("detail", { checkEmpty: false });
    this.setStateVariables("image", { checkEmpty: false }, "FileField");
  }
  

  render() {
 
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddBlogcategory.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/blog/categories");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Personal Info
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="title"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      <CustomSelectField
                        paramValue="isactive"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                            selected: "selected",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>


                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>




                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="detail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        User Profile Image
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      {
                        <CustomUploadButton
                          paramValue="image"
                          state={this.state}
                          acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>


                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          // disabled={
                          //   loginValue.length === 0 ||
                          // }
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Create Blogcategory"}
                            </div>
                          ) : (
                            "Create Blogcategory"
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleSubmitButton = async (param) => {
    console.log("createBlogcategory API Started");
    let rc = await createBlogCategoryAPI(param);
    if (rc == true) {
      console.log("Blogcategory Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/blog/categories");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Blogcategory Not Added");
    }
    console.log("createBlogcategory API ENDED");
  };

}

export default AddBlogcategory;
