import React from "react";
import axios from "axios";
import { Redirect } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import  {BASE_URL}  from "./Macros";

export const API_Param = {
  URL: BASE_URL,
  token: "",
};

var axiosApiInstance = axios.create({
  baseURL: API_Param.URL,
  timeout: 120000,
  headers: {
    "content-type": "application/json",
    // 'content-type': 'multipart/form-data'
  },
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("<ERROR In Interceptor> ", error.message);
    // const originalRequest = error.config;
    // if (error.response != undefined && error.response.status === 403) {
    //   toast.error(error.response.data.msg);
    //   // window.location.reload();
    //   return <Redirect to="/app/dashboard" />;
    // }
    return Promise.reject(error);
  },
);
export default axiosApiInstance;

// API_Param.token = token;

// // const config = {
// //   headers: {
// //               "content-type": "application/json",
// //               "Authorization": 'Bearer ' + token,
// //           }
// //         };

// // const bodyParameters = {
// //   'key': "Himanshu Shukla",
// // };

// console.log("Logout Started --->>>> ");

// // let response = await axios.post(
// //   API_Param.URL + "account/logout",
// //   bodyParameters,
// //   config,
// // );
