import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Button,
  CircularProgress,
  Slide,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select
} from "@material-ui/core";
// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// FOR APIS  ====================================================================
import { getAdminsAPI, createAdminAPI } from "../../APIs/adminApis";
import { getStatusMacros, getRadioMacros, getCountryList, getDocumentMIMETypes } from "../../helpers";

// IMAGES  ====================================================================


class AddAdmin extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      adminsTable: [],
      pageGroup: "Admin",
      pageName: "AddAdmin",
      catagoriesList: [],
      uploadersTable: [],
      isSubmitLoading: false,
      isError: false,
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <div className="container">
              <div className="d-flex flex-row justify-content-between align-items-center" >
                <div><h3 className="cls-page-title"><Trans>AddAdmin.pageTitle</Trans></h3></div>
                <div><Button variant="outlined" color="secondary" onClick={() => { this.props.history.push("/app/admins"); }}>Back</Button></div>
              </div>

              <div className="row">
                <div class="card ">
                  <div class="card-block ">
                    <h4 className="cls-card-title">
                      Personal Info
                    </h4>
                    <hr />

                    <div className="row">
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            required="required"
                            paramValue="name"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="email"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            required="required"
                            paramValue="phone"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomSelectField
                          paramValue="status"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getStatusMacros()}
                        />
                      </div>

                      <div className="col-xs-12 justify-content-start">
                        <CustomTextField
                          required="required"
                          paramValue="designation"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="address"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <FormControl
                          fullWidth
                          required={true}
                          error={false}
                        >
                          <InputLabel id="ProfilesValueID">
                            Select Report Manager
                          </InputLabel>
                          <Select
                            value={this?.state?.parent_idValue}
                            onChange={this.handleReportToChange}
                            input={<Input id="select-multiple" />}
                          >
                            {this?.state?.adminsTable.map(admin => (
                              (admin?.id != this.state?.idValue) ?
                                <MenuItem
                                  key={admin?.name}
                                  value={admin?.id}
                                >
                                  {admin.name}
                                </MenuItem> : ""
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomTextField
                          paramValue="summary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      </div>



                    </div>

                    <div className="row">
                      <div className="col-xs-12 justify-content-start">
                        <h4 className="cls-card-title">Documents Section</h4><hr />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <div>
                          User Profile Image
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6  justify-content-start">
                        {
                          <CustomUploadButton
                            paramValue="file_photo"
                            state={this.state}
                            acceptedFiles={[
                              "image/jpeg",
                              "image/png",
                              "image/bmp",
                              "application/pdf",
                            ]}
                            handleFileOpen={this.handleFileOpen}
                            handleFileClose={this.handleFileClose}
                            handleFileSave={this.handleFileSave}
                          />
                        }
                      </div>
                    </div>

                    <div className="d-flex flew-row justify-contents-end">
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Create Admin"}
                            </div>
                          ) : (
                            "Create Admin"
                          )}
                        </Button>
                      </div>
                      {this.state.isError ? (
                        <div className="text-danger">
                          Error coming, Please check form
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("name", { checkEmpty: true, minLength: 0, maxLength: 120, });
    this.setStateVariables("email", { checkEmpty: false, minLength: 0, maxLength: 120, });
    this.setStateVariables("phone", { checkEmpty: true, minLength: 0, maxLength: 20, });
    this.setStateVariables("summary", { checkEmpty: false, minLength: 0, maxLength: 2000, });
    this.setStateVariables("designation", { checkEmpty: false, minLength: 0, maxLength: 120, });
    this.setStateVariables("status", { checkEmpty: false }, "SelectField", "ACTIVE",);
    this.setStateVariables("address", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("parent_id", { checkEmpty: false }, "SelectField", "",);
    this.setStateVariables("file_photo", { checkEmpty: false }, "FileField");
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetAdmins();
  };

  handleGetAdmins = async () => {
    let RC = false;
    this.setState({ loader: true, adminsTable: [] });

    let admins = await getAdminsAPI();
    if (admins != undefined && admins.length > 0) {
      console.log("Admins Found Successfully");
      this.setState({
        adminsTable: admins,
        loader: false,
        isRecent: false,
      });
    } else {
      console.log("Admin Not found");
      this.setState({ loader: false });
    }
  };


  handleSubmitButton = async (param) => {
    console.log("createAdmin API Started");
    let rc = await createAdminAPI(param);
    if (rc == true) {
      console.log("Admin Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/admins");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Admin Not Added");
    }
    console.log("createAdmin API ENDED");
  };

  handleReportToChange = event => {
    this.setState({ parent_idValue: event.target.value });
  };

}

export default AddAdmin;
