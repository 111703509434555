import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";


// context
import { EmailOutlined } from "@material-ui/icons";

import {
  useUserDispatch,
  loginUser,
  registerUser,
  isEmailExist,
  isPhoneExist,
  forgetPassword,
  verifyOTPs,
} from "../../context/UserContext";

function ForgetPassword(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [email, setEmail] = useState("");
  var [phone, setPhone] = useState("");
  var [emailOTP, setEmailOTP] = useState("123456");
  var [phoneOTP, setPhoneOTP] = useState("");
  var [errorEmail, setErrorEmail] = useState("");
  var [errorPhone, setErrorPhone] = useState("");
  var [errorEmailOTP, setErrorEmailOTP] = useState("");
  var [errorPhoneOTP, setErrorPhoneOTP] = useState("");

  async function checkEmailExist(email) {
    let rc = await isEmailExist(email);
    console.log("<checkEmailExist> :: ", rc);
    if (rc == "success") {
      setErrorEmail("yes");
      console.log("<ERROR> EMAIL exist in database");
    } else {
      setErrorEmail("no");
      console.log("<INFO> EMAIL Id available");
    }
  }

  async function checkPhoneExist(phone) {
    let rc = await isPhoneExist(phone);
    console.log("<checkPhoneExist> :: ", rc);
    if (rc == "success") {
      setErrorPhone("yes");
      console.log("<ERROR> Phone exist in database");
    } else {
      setErrorPhone("no");
      console.log("<INFO> Phone Id available");
    }
  }

  function handleForgetPassword() {
    let RC = forgetPassword(
      userDispatch,
      email,
      phone,
      props.history,
      setIsLoading,
      setError,
    );
    if (RC) setActiveTabId(1);
  }

  function handleCheckOTPs() {
    let RC = verifyOTPs(
      userDispatch,
      email,
      phone,
      emailOTP,
      phoneOTP,
      props.history,
      setIsLoading,
      setError,
    );
    if (RC) setErrorEmailOTP(true);
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>StartupBasket</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h3" className={classes.greeting}>
              Enter Your Email ID OR Phone number to get OTP
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your email or phone :(
              </Typography>
            </Fade>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
            />
            <TextField
              id="phone"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              margin="normal"
              placeholder="Phone Adress"
              type="text"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={!(email.length > 0 || phone.length > 0)}
                  onClick={handleForgetPassword}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Send OTP
                </Button>
              )}
            </div>
          </React.Fragment>
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h3" className={classes.greeting}>
                Enter OTP Send on your email id or phone
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your email or phone OTP
                </Typography>
              </Fade>
              <TextField
                id="emailOTP"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailOTP}
                onChange={(e) => setEmailOTP(e.target.value)}
                margin="normal"
                placeholder="Email OTP"
                type="number"
                fullWidth
              />
              <TextField
                id="phoneOTP"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={phoneOTP}
                onChange={(e) => setPhoneOTP(e.target.value)}
                margin="normal"
                placeholder="Phone OTP"
                type="number"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={!(emailOTP.length == 6 || phoneOTP.length == 6)}
                    onClick={handleCheckOTPs}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Verify OTP
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(ForgetPassword);
