const fr_source = {
    main_menu: {
      sports: "Sports",
      my_store: "Mon magasin",
      my_orders: "Mes ordres",
      sign_in: "Connexion",
      sign_up: "s'enregistrer",
      offices: "Bureaux"
    },
    side_menu: {
      t_shirt: "Bureaux",
      shoes: "Chaussures",
      fleece: "Polaire",
      trousers: "Pantalon",
      shorts: "Courts métrages"
    }
  };
  
  export {fr_source};
  