import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import {getRadioMacros, getCountryList,  getStatusMacros,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";


// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Slide,
  CircularProgress,
} from "@material-ui/core";
// Picker
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MUIDataTable from "mui-datatables";


// FOR APIS  ====================================================================
import {
  getBlogCategoriesAPI,
  getBlogCategoryAPI,
  createBlogCategoryAPI,
  editBlogCategoryAPI,  
} from "../../APIs/blogcategoryApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
  getCategoriesAPI
} from "../../APIs/ApiUtils";


// IMAGES  ====================================================================
import userimage from "./../../images/userimage.png";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

var requiredParam = [
  "blogcategoryname",
  "blogcategoryemail",
  "blogcategoryphone",
  "blogcategoriesummary",
  "blogcategoryactivated",
  "blogcategoryaddress_id",
  "blogcategorycompany",
  "blogcategorylinkedin",
  "blogcategoryfacebook",
  "blogcategoryprofilefile",
  "blogcategorycontractfile",
  "blogcategoryndafile",
];

class Blogcategory extends BaseComponentClass {
  constructor() {
    super();

    this.state = {
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogBlogcategoryDocument: false,
      cofounders: [],
      documents: [],
      profilePicture: "",
    };
  }

  componentDidMount() {

    this.setStateVariables("blogcategoryname", {checkEmpty: true,minLength: 0,maxLength: 120,});    
    this.setStateVariables("blogcategoryemail", { checkEmpty: false,minLength: 0,maxLength: 120,});
    this.setStateVariables("blogcategoryphone", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("blogcategoriesummary", {checkEmpty: false, minLength: 0, maxLength: 200,});
    this.setStateVariables("blogcategoryactivated",{ checkEmpty: false },"SelectField","ACTIVE",);
    this.setStateVariables("blogcategoryaddress_id", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("blogcategorycompany",      { checkEmpty: false,minLength: 0, maxLength: 120 },    );
    this.setStateVariables("blogcategorylinkedin", { checkEmpty: false,minLength: 0, maxLength: 200,});
    this.setStateVariables("blogcategoryfacebook", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("blogcategoryprofilefile", { checkEmpty: false }, "FileField");    
    this.setStateVariables("blogcategorycontractfile",{ checkEmpty: false },"FileField",);
    this.setStateVariables("blogcategoryndafile", { checkEmpty: false }, "FileField");    
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetBlogcategory();
  };

  render() {
    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>ViewBlogcategory.pageTitle</Trans>}
                  button={
                    <div>
                    <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      this.props.history.push("/app/allocateservice");
                    }}
                  >
                    View All Services
                  </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/blog/categories");
                      }}
                    >
                      Back
                    </Button>
                    </div>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Personal Info
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="blogcategoryname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogcategoryemail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="blogcategoryphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      <CustomSelectField
                        paramValue="blogcategoryactivated"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                            selected: "selected",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogcategoryaddress_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }}>
                        <CustomTextField paramValue="blogcategorycompany" state={this.state} onChange={this.handleParam} />                      
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogcategorylinkedin"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogcategoryfacebook"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="blogcategoriesummary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid
                      item
                      mt={4}
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography color="primary" variant="h5" size="sm" mt={4}>
                        Documents Section
                      </Typography>
                      <Divider style={{ margin: "5px 0 15px 0" }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        User Profile Image
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      {
                        <CustomUploadButton
                          paramValue="blogcategoryprofilefile"
                          state={this.state}
                          acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        NDA Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="blogcategoryndafile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="blogcategorycontractfile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    ></Grid>
                  </Grid>


                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>                        
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleGetBlogcategory = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, blogcategoryInfo: {} });

    let blogcategoryInfo = await getBlogCategoryAPI(localStorage.getItem("CURRENT_MEMBER"));
    if (blogcategoryInfo != undefined) {
      let tmp = {};
      Object.entries(blogcategoryInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Blogcategory Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["blogcategoryprofileimageValue"],
        fileName: "blogcategoryprofileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Blogcategory Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleBlogcategory = async (param) => {
    console.log("createBlogcategory API Started");
    let id_blogcategory = this.props.match.params.blogcategoryId;
    let rc = await editBlogCategoryAPI(id_blogcategory, param);
    if (rc == true) {
      console.log("Blogcategory Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/blog/categories");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Blogcategory Not Updated");
    }
    console.log("createBlogcategory API ENDED");
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditCoFounder(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit CoFounder" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Blogcategory "' + tableMeta.rowData[1] + '"',
              );
              if (retVal == true) {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
            // onClick={() =>
            //   this.handleDeleteDialogBlogcategory(value, tableMeta, updateValue)
            // }
          >
            <Tooltip title="Delete Current CoFounder" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

       
      </Grid>
    );
  };

  handleCloseBlogcategoryDocument = () => {
    this.setState({ deleteDialogBlogcategoryDocument: false });
  };

  handleDeleteDialogBlogcategory = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };
}


export default withRouter(Blogcategory);


