import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo.png";
import google from "../../images/google.svg";

// context
import {
  useUserDispatch,
  loginUser,
  registerUser,
  isEmailExist,
  isPhoneExist,
} from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [userType, setUserType] = useState("");
  var [phone, setPhone] = useState("");
  var [errorEmail, setErrorEmail] = useState("");
  var [errorPhone, setErrorPhone] = useState("");

  async function checkEmailExist(email) {
    let rc = await isEmailExist(email);
    console.log("<checkEmailExist> :: ", rc);
    if (rc == "success") {
      setErrorEmail("yes");
      console.log("<ERROR> EMAIL exist in database");
    } else {
      setErrorEmail("no");
      console.log("<INFO> EMAIL Id available");
    }
  }

  async function checkPhoneExist(phone) {
    let rc = await isPhoneExist(phone);
    console.log("<checkPhoneExist> :: ", rc);
    if (rc == "success") {
      setErrorPhone("yes");
      console.log("<ERROR> Phone exist in database");
    } else {
      setErrorPhone("no");
      console.log("<INFO> Phone No available");
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        {/* <Typography className={classes.logotypeText}>StartupBasket</Typography> */}
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Sign In" classes={{ root: classes.tab }} />
            <Tab label="Register" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h2" className={classes.greeting}>
                Login Into StartupBasket
              </Typography>
              {/* <Button size="large" className={classes.googleButton}>
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button> */}
              {/* <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div> */}
              {error != undefined ? (
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    Something is wrong with your login or password
                  </Typography>
                </Fade>
              ) : (
                ""
              )}

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => {
                    props.history.push("/forgetpassword");
                  }}
                >
                  Forget Password
                </Button>
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h3" className={classes.subGreeting}>
                Create your account
              </Typography>
              {error != undefined ? (
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Your credentials are already registered
                </Typography>
              </Fade>
              ):''}
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Full Name"
                type="text"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                onBlur={(e) => checkEmailExist(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
                helperText={
                  errorEmail == "yes"
                    ? "Email Id already exist, Please login with same"
                    : errorEmail == "no"
                    ? "Perfect! Email Available"
                    : ""
                }
                error={errorEmail == "yes" ? 1 : 0}
              />
              <TextField
                id="phone"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={(e) => checkPhoneExist(e.target.value)}
                margin="normal"
                placeholder="Phone Number"
                type="text"
                fullWidth
                helperText={
                  errorPhone == "yes"
                    ? "Phone No already exist, Please login with same"
                    : errorPhone == "no"
                    ? "Perfect! Phone Available"
                    : ""
                }
                error={errorPhone == "yes" ? 1 : 0}
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={() =>
                      registerUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        nameValue,
                        userType,
                        phone,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0 ||
                      nameValue.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Submit
                  </Button>
                )}
              </div>
              {/* <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div> */}
              {/* <Button
                size="large"
                className={classnames(
                  classes.googleButton,
                  classes.googleButtonCreating,
                )}
              >
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button> */}
            </React.Fragment>
          )}
        </div>
      </div>
      <Grid xs={12} justifyContent="center" alignItems="center">
        <Typography
          color="primary"
          alignSelf="flex-end"
          className={classes.copyright}
        >
          © 2020-{new Date().getFullYear()}{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="http://admin.startupbasket.net"
            rel="noopener noreferrer"
            target="_blank"
          >
            StartupBasket
          </a>
          ,. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login);
