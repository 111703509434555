import React, { Fragment, Component } from "react";
import themeAPP from "../../themes";

// import needed components, functions and styles
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { Button, Grid } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import {
  deleteAdminAPI,
  getAdminsAPI,
  getAdminsPaginationAPI,
} from "../../APIs/adminApis";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Trans } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminsTable: [],
      loader: false,
      deleteDialog: false,
      isRecent: true,
      permissions: undefined,
      roles: undefined,
    };
  }

  render() {
    const components = {
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid xs={12} container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>Admins.pageTitle</Trans>}
                  button={
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          this.state.isRecent
                            ? this.handleGetAdmins()
                            : this.handleGetAdminsPagination();
                        }}
                      >
                        {this.state.isRecent ? (
                          <Trans>Get All</Trans>
                        ) : (
                          <Trans>Get Recent</Trans>
                        )}
                      </Button>
                      { /*(this.state.permissions != undefined &&
                        this.state.permissions.includes("admin_create")) &&*/ (
                          <Button
                            style={{ marginLeft: "10px" }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              this.props.history.push("/app/addadmin");
                            }}
                          >
                            <Trans>Admins.submitBtn</Trans>
                          </Button>
                        )}
                    </div>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={{ justifyContent: "space-between" }}
              >
                <MuiThemeProvider theme={themeAPP.default}>
                  <MUIDataTable
                    title={
                      this.state.isRecent
                        ? "Recent Added Admins"
                        : "All Admins"
                    }
                    data={this.state.adminsTable}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "profilethumbnail",
                        label: "Profile",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <Avatar src={tableMeta.rowData[1]}>
                                {/* <AssignmentIcon /> */}
                              </Avatar>
                            );
                          },
                        },
                      },
                      {
                        name: "name",
                        label: "User Name",
                      },
                      {
                        name: "designation",
                        label: "Designation",
                      },
                      {
                        name: "email",
                        label: "Email",
                      },
                      {
                        name: "phone",
                        label: "Phone",
                      },
                      {
                        name: "status",
                        label: "Status",
                      },
                      {
                        name: "Actions",
                        options: {
                          filter: true,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRender(
                              value,
                              tableMeta,
                              updateValue,
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      tableBodyMaxHeight: "400px",
                      selectableRows: false,
                      filter: !this.state.isRecent,
                      print: !this.state.isRecent,
                      download: !this.state.isRecent,
                      elevation: 0,
                      fixedHeader: true,
                      viewColumns: !this.state.isRecent,
                      filterType: "dropdown",
                      responsive: "standard",
                      expandableRows: false,
                    }}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    let userRole = localStorage.getItem("user_role");
    var userRoleArr = userRole.split(",");
    let userPermission = localStorage.getItem("user_permission");
    var userPermissionArr = userPermission.split(",");
    this.setState({ permissions: userPermissionArr, roles: userRoleArr });

    this.handleGetAdminsPagination();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetAdminsPagination = async () => {
    let RC = false;
    this.setState({ loader: true, adminsTable: [] });
    let page = 1;
    let limit = 10;

    let admins = await getAdminsPaginationAPI(limit, page);
    if (admins != undefined && admins.length > 0) {
      console.log("Admins Found Successfully");
      this.setState({
        adminsTable: admins,
        loader: false,
        isRecent: true,
      });
    } else {
      console.log("Admin Not found");
      this.setState({ loader: false });
    }
  };

  handleGetAdmins = async () => {
    let RC = false;
    this.setState({ loader: true, adminsTable: [] });

    let admins = await getAdminsAPI();
    if (admins != undefined && admins.length > 0) {
      console.log("Admins Found Successfully");
      this.setState({
        adminsTable: admins,
        loader: false,
        isRecent: false,
      });
    } else {
      console.log("Admin Not found");
      this.setState({ loader: false });
    }
  };

  deleteAdmin = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleClose();
    let rc = await deleteAdminAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("Admin Deleted Successfully");
      this.handleGetAdmins();
      toast.success("Admin Deleted");
    } else console.log("Admin Not Deleted");
  };

  editAdmin = (value, tableMeta, updateValue) => {

    this.props.history.push("/app/editadmin/"+tableMeta.rowData[0]);
  };

  viewAdmin = (value, tableMeta, updateValue) => {
    this.props.history.push("/app/viewadmin/"+tableMeta.rowData[0]);
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <div style={{ display: "inline-flex", margin: "0px auto" }}>
        { /* this.state.permissions.includes("admin_view") && */ (
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => this.viewAdmin(value, tableMeta, updateValue)}
            style={themeAPP.default.button}
          >
            <Tooltip title="View Admin" aria-label="add">
              <Visibility />
            </Tooltip>
          </IconButton>
        )}
        { /* this.state.permissions.includes("admin_update") && */ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="upload picture"
            onClick={() => this.editAdmin(value, tableMeta, updateValue)}
          >
            <Tooltip title="Edit Admin" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        { /* this.state.permissions.includes("admin_delete") && */ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="Delete Button"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete "' +
                  tableMeta.rowData[2] +
                  '"',
              );
              if (retVal == true) {
                this.deleteAdmin(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          >
            <Tooltip title="Delete Current Admin" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
        <Dialog
          open={this.state.deleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting Admin
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {"Are You Sure to Delete" +
                tableMeta.rowData[1] +
                " " +
                tableMeta.rowData[2]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.deleteAdmin(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}
