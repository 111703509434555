import React, { Fragment, useRef, useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";

// FOR APIS
import {
  getAllBookingsAPI
} from "../../APIs/bookingApis";

import {
  getLocaleShortDateString,
  currencySymbol
} from "../../helpers";

// CUSTOM COMPONENTS
// import  CircularStatic from '../../components/Controls/CircularProgressBar';
import CircularProgress from "@material-ui/core/CircularProgress";


// The "main" component, our actual calendar
const Orders = () => {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();
  const [eventList, setEventList] = useState(undefined);
  const [orderList, setOrderList] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  const example1 = useRef(null);

  useEffect(() => {
    console.log("Date has changed... Let's load some fresh data")
    handleGetBooking();
  }, [])

  async function handleGetBooking() {
    let RC = false;

    let persona_id = params.itemId;
    let param = { startup_id: persona_id, persona_type: "startup" };
    setIsFormLoading(true);
    setOrderList(undefined);


    let orderArrs = await getAllBookingsAPI(param);
    if (orderArrs != undefined) {
      console.log("Booking Found Successfully", orderArrs);
      let eventListTmp = [];
      orderArrs.forEach(booking => {
        eventListTmp.push(
          {
            id: booking.id,
            name: booking?.member?.membername + ", " + booking?.appointment?.starttime.slice(0, 5) + " - " + booking?.appointment?.endtime.slice(0, 5),
            dateFrom: booking?.appointment?.date + 'T' + booking?.appointment?.starttime.slice(0, 5),
            dateTo: booking?.appointment?.date + 'T' + booking?.appointment?.endtime.slice(0, 5),
            meta: booking?.service?.title,
            type: booking?.status,
            // url:"/profile/startup/order/"+row.id
          });

        console.log("This is Event List ", eventListTmp);
      });
      setEventList(eventListTmp);
      setOrderList(orderArrs);
      setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          <CircularProgress variant="indeterminate" value={75} />
          {/* <CircularStatic value={25} /> */}
        </div>
      ) : (
        <div className="cls-booking-table">
          <div className="container ">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-white">
                  <div className="card-heading clearfix border-bottom my-3 mx-3">
                    <h4 className="card-title">Booking Requests</h4>
                  </div>
                  <div className="card-body">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="align-self-center">
                            <th>Member Name</th>
                            <th>Session Date</th>
                            <th>Session Time</th>
                            <th>Booking Date</th>
                            <th>Service</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            orderList?.map((row) => {
                              return (
                                <tr>
                                  <td><img src={row?.member?.profilethumbnail} alt="" class="thumb-sm rounded-circle mx-2" />
                                    {row?.member?.membername}
                                  </td>
                                  <td>{getLocaleShortDateString(row?.appointment?.date)}</td>
                                  <td>{"from " + row?.appointment?.starttime.slice(0, 5) + " to " + row?.appointment?.endtime.slice(0, 5)}</td>
                                  <td>{getLocaleShortDateString(row?.created_at)}</td>
                                  <td>{row?.service?.title}</td>
                                  {/* <td>{currencySymbol() + row?.payment?.amount}</td> */}
                                  <td><span className={(row?.status == "BOOKED") ? "badge badge-boxed badge-soft-success" : "badge badge-boxed badge-soft-warning"}>{row?.status}</span></td>
                                  <td>
                                    <Link to={"/app/booking/" + row.id} className="button button_primary border-bottom">
                                      <span>View Detail</span>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            })}
                          {
                            (orderList?.length == 0) &&
                            <div className="text-secondary">No bookings</div>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}


export default Orders;


