import React from "react";
import axios from "axios";
import { Redirect } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const BASE_URL = "https://server.startupbasket.net/api/";   // WORKING
// export const BASE_URL = "http://127.0.0.1:8000/api/";   // WORKING
export const LOGIN_URL = "/auth/login";                 // WORKING
export const REGISTER_URL = "/auth/register";           // WORKING    
export const LOGOUT_URL = "/auth/logout";               // WORKING    
export const ISEMAILEXIST_URL = "/auth/emailexist";     // ERROR
export const ISPHONEEXIST_URL = "/auth/phoneexist";     // ERROR

export const FORGETPASSWORD_URL = "/auth/forgetpassword"; // WORKING
export const RESETPASSWORD_URL = "/auth/password/reset";   // WORKING

export const SEND_EMAIL_OTP = "auth/email/otp/"; // WORKING
export const SEND_PHONE_OTP = "auth/phone/otp/";   // WORKING

export const VERIFY_EMAIL_OTP = "auth/email/verify/"; // ERROR
export const VERIFY_PHONE_OTP = "auth/phone/verify/";   // ERROR




