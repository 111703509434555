import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Button,
  CircularProgress,
  Slide
} from "@material-ui/core";
// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// FOR APIS  ====================================================================
import { getStartupsAPI, createStartupAPI } from "../../APIs/startupApis";
import {
        getStatusMacros, getRadioMacros, getCountryList, getDocumentMIMETypes, 
        
        } from "../../helpers";
import { getCategoriesAPI } from "../../APIs/ApiUtils";

import {
      isErrorExist,
      getAge,
      checkTextField,
      checkDateField,
      checkNumberField,
      checkRadioField,
      checkSelectField,
    } from "../../APIs/ApiUtils";


class AddStartup extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      pageGroup: "Startup",
      pageName: "AddStartup",
      catagoriesList: [],
      uploadersTable: [],
      isSubmitLoading: false,
      isError: false,
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <div className="container">
              <div className="d-flex flex-row justify-content-between align-items-center" >
                <div><h3 className="cls-page-title"><Trans>AddStartup.pageTitle</Trans></h3></div>
                <div><Button variant="outlined" color="secondary" onClick={() => { this.props.history.push("/app/startups"); }}>Back</Button></div>
              </div>

              <div className="row">
                <div class="card ">
                  <div class="card-block ">
                    <h4 className="cls-card-title">
                      Personal Info
                    </h4>
                    <hr />

                    <div className="row">
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomSelectField
                          required="required"
                          paramValue="category_id"
                          state={this.state}
                        onChange={this.handleParam}
                          ItemValues={this.state?.catagoriesList}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            required="required"
                            paramValue="contactname"
                            state={this.state}
                          onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="contactemail"
                            state={this.state}
                          onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            required="required"
                            paramValue="contactphone"
                            state={this.state}
                          onChange={this.handleParam}
                          />
                        }
                      </div>


                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomSelectField
                          paramValue="status"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getStatusMacros()}
                        />
                      </div>

                      {/* <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="address_id"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-12  justify-content-start">
                        <h4 className="cls-card-title">Company/Startup Details</h4><hr />
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomRadioButton
                          paramValue="isrevenue"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getRadioMacros()}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            id="incorporationdate"
                            label="Enter Incorporation Date"
                            format="yyyy-MM-dd"
                            openTo="year"
                            views={["year", "month", "date"]}
                            minDate={new Date("1920-03-01")}
                            selected={this.state.incorporationdateValue}
                            value={this.state.incorporationdateValue}
                            onChange={(e) => {
                              let value = moment(e).format("yyyy-MM-DD");
                              console.log("Current Date", e, value);
                              this.handleParam(value, "incorporationdate", true);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            error={this.state.incorporationdateErrorStatus}
                            helperText={
                              this.state.incorporationdateErrorStatus
                                ? this.state.incorporationdateErrorMsg
                                : ""
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">

                        <CustomRadioButton
                          paramValue="isproductused"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getRadioMacros()}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomRadioButton
                          paramValue="islegalentity"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getRadioMacros()}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomRadioButton
                          paramValue="isalreadyinvestment"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getRadioMacros()}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <CustomRadioButton
                          paramValue="isalreadyparticipate"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={getRadioMacros()}
                        />
                      </div>
                      <div className="col-12  justify-content-start">
                        {
                          <CustomTextField
                            paramValue="previouscompany"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-12  justify-content-start">
                        {
                          <CustomTextField
                            paramValue="aboutcompany"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="companyname"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomSelectField
                            paramValue="operatingin"
                            state={this.state}
                            onChange={this.handleParam}
                            ItemValues={getCountryList()}
                          />
                        }
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="companyurl"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">

                        <CustomRadioButton
                          paramValue="is_permanent_addres"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={[
                            {
                              value: "1",
                              label: "Same as above",
                            },
                          ]}
                        />
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="producturl"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        {
                          <CustomTextField
                            paramValue="demolink"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                        }
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-xs-12 justify-content-start">
                        <h4 className="cls-card-title">Documents Section</h4><hr />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <div>
                          User Profile Image
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6  justify-content-start">
                        {
                          <CustomUploadButton
                            paramValue="profileimage"
                            state={this.state}
                            acceptedFiles={[
                              "image/jpeg",
                              "image/png",
                              "image/bmp",
                              "application/pdf",
                            ]}
                            handleFileOpen={this.handleFileOpen}
                            handleFileClose={this.handleFileClose}
                            handleFileSave={this.handleFileSave}
                          />
                        }
                      </div>

                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <div>
                          Company logo
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6  justify-content-start">

                        <CustomUploadButton
                          paramValue="companylogo"
                          state={this.state}
                          acceptedFiles={getDocumentMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <div className="">Business Plan</div>
                      </div>
                      <div className="col-xs-12 col-md-6  justify-content-start">

                        <CustomUploadButton
                          paramValue="businessplan"
                          state={this.state}
                          acceptedFiles={getDocumentMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <div className="">NDA Document</div>
                      </div>
                      <div className="col-xs-12 col-md-6  justify-content-start">

                        {
                          <CustomUploadButton
                            paramValue="ndadoc"
                            state={this.state}
                            acceptedFiles={getDocumentMIMETypes()}
                            maxFileLimit={5242880}
                            handleFileOpen={this.handleFileOpen}
                            handleFileClose={this.handleFileClose}
                            handleFileSave={this.handleFileSave}
                          />
                        }
                      </div>
                      <div className="col-xs-12 col-md-6 col-xl-4 justify-content-start">
                        <div className="">Contract Form</div>
                      </div>
                      <div className="col-xs-12 col-md-6  justify-content-start">

                        <CustomUploadButton
                          paramValue="contractdoc"
                          state={this.state}
                          acceptedFiles={getDocumentMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      </div>

                    </div>


                    <div className="d-flex flew-row justify-contents-end">
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Create Startup"}
                            </div>
                          ) : (
                            "Create Startup"
                          )}
                        </Button>
                      </div>
                      {this.state.isError ? (
                        <div className="text-danger">
                          Error coming, Please check form
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("category_id", { checkEmpty: false }, "SelectField",);
    this.setStateVariables("contactname", { checkEmpty: true, minLength: 0, maxLength: 120, });
    this.setStateVariables("contactemail", { checkEmpty: false, minLength: 0, maxLength: 120, });
    this.setStateVariables("contactphone", { checkEmpty: true, minLength: 0, maxLength: 20, });
    this.setStateVariables("incorporationdate", { checkEmpty: false, minAge: 0, maxAge: 100 }, "DateField",);
    this.setStateVariables("profileimage", { checkEmpty: false }, "FileField");
    this.setStateVariables("status", { checkEmpty: false }, "SelectField", "ACTIVE",);
    this.setStateVariables("isproductused", { checkEmpty: false }, "RadioField", "NO");
    this.setStateVariables("is_permanent_addres", { checkEmpty: false }, "RadioField", "NO");
    this.setStateVariables("previouscompany", { checkEmpty: false, minLength: 0, maxLength: 200, });
    this.setStateVariables("aboutcompany", { checkEmpty: false, minLength: 0, maxLength: 200, });
    this.setStateVariables("companyurl", { checkEmpty: false, minLength: 0, maxLength: 200, });
    this.setStateVariables("operatingin", { checkEmpty: false }, "SelectField", "INDIA",);
    this.setStateVariables("companyname", { checkEmpty: false, minLength: 0, maxLength: 120 },);
    this.setStateVariables("producturl", { checkEmpty: false });
    this.setStateVariables("isrevenue", { checkEmpty: false }, "RadioField", "NO");
    this.setStateVariables("isalreadyparticipate", { checkEmpty: false }, "RadioField", "NO");
    this.setStateVariables("islegalentity", { checkEmpty: false }, "RadioField", "NO");
    this.setStateVariables("isalreadyinvestment", { checkEmpty: false }, "RadioField", "NO");
    this.setStateVariables("demolink", { checkEmpty: false });
    // this.setStateVariables("address_id", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("businessplan", { checkEmpty: false }, "FileField");
    this.setStateVariables("ndadoc", { checkEmpty: false }, "FileField");
    this.setStateVariables("contractdoc", { checkEmpty: false }, "FileField",);
    this.setStateVariables("companylogo", { checkEmpty: false }, "FileField");
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetAllCategories();
  };

  handleGetAllCategories = async () => {
    let RC = false;
    let arrCategories = [];

    this.setState({ isFormLoading: true, catagoriesList: [] });
    let categories = await getCategoriesAPI();
    if (categories != undefined) {

      categories.forEach(element => {
        let tmp = {};
        tmp['value'] = element['id'];
        tmp['label'] = element['title'];
        arrCategories.push(tmp);
      });
      console.log("Categories found successfully", arrCategories);

      this.setState({
        catagoriesList: arrCategories,
        isFormLoading: false,
      });

    } else {
      console.log("Categories Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleSubmitButton = async (param) => {
    console.log("createStartup API Started");
    param["user_id"] = localStorage.getItem("user_id");

    let rc = await createStartupAPI(param);
    if (rc == true) {
      console.log("Startup Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/startups");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Not Added");
    }
    console.log("createStartup API ENDED");
  };

}

export default AddStartup;

