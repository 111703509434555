import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getMembersAPI,
  getMemberAPI,
  createMemberAPI,
  editMemberAPI,
  getCoFoundersAPI,
  deleteCoFounderAPI,
  createMemberDocumentAPI,
  getMemberDocumentAPI,
  editMemberDocumentAPI,
  deleteMemberDocumentAPI,
  getProfilesAPI,
  getMemberServicesAPI
} from "../../APIs/memberApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
} from "../../APIs/ApiUtils";
import {getImageBlob, getRadioMacros, getCountryList,  getStatusMacros,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";


import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

import {
  getBlogCategoriesAPI,
} from "../../APIs/blogcategoryApis";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
//const userimage = require('./../../images/userimage.png')
import userimage from "./../../images/userimage.png";
var requiredParam = [
  "membername",
  "memberemail",
  "memberphone",
  "membersummary",
  "memberprofile",
  "memberexcerpt",
  "status",
  "memberaddress_id",
  "membercompany",
  "memberlinkedin",
  "memberfacebook",
  "memberprofilefile",
  "membercontractfile",
  "memberndafile",
  'filter',
  'profiles',    
];

let membertypes = [
  {
    value: "INDIVIDUAL",
    label: "INDIVIDUAL",
  },
  {
    value: "COMPANY",
    label: "COMPANY",
  },
  {
    value: "TEAM",
    label: "TEAM",
  },
];


export default class EditMember extends Component {
  constructor() {
    super();

    this.state = {
      profilesList:[],
      memberServicesList:[],
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogMemberDocument: false,
      cofounders: [],
      documents: [],
      profilePicture: "",
    };
  }

  componentDidMount() {

    
    // this.setStateVariables("memberprofile", { checkEmpty: true }, "SelectField");
    this.setStateVariables("memberprofile", {checkEmpty: true,minLength: 0,maxLength: 120,});    

    this.setStateVariables("membername", {checkEmpty: true,minLength: 0,maxLength: 120,});    
    this.setStateVariables("memberemail", { checkEmpty: false,minLength: 0,maxLength: 120,});
    this.setStateVariables("memberphone", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("memberexcerpt", {checkEmpty: true,minLength: 5,maxLength: 200,});    
    this.setStateVariables("membersummary", {checkEmpty: true, minLength: 5, maxLength: 2000,});
    this.setStateVariables("status",{ checkEmpty: false },"SelectField","ACTIVE",);
    // this.setStateVariables("memberaddress_id", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("membercompany",      { checkEmpty: false,minLength: 0, maxLength: 120 },    );
    this.setStateVariables("memberlinkedin", { checkEmpty: false,minLength: 0, maxLength: 1000,});
    this.setStateVariables("memberfacebook", {checkEmpty: true,minLength: 0,maxLength: 1000,});
    this.setStateVariables("memberprofilefile", { checkEmpty: false }, "FileField");    
    this.setStateVariables("membercontractfile",{ checkEmpty: false },"FileField",);
    this.setStateVariables("memberndafile", { checkEmpty: false }, "FileField");    
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetMember();
    await this.handleGetAllProfiles();
    await this.handleGetAllMemberServices();
    // await this.handleGetMemberDocument();
  };

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditMember.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/members");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Personal Info
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="membername"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="memberemail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      // item
                      // xs={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <div>
                        <Typography>Profile Image</Typography>
                        <img
                          src={
                            this.state.profilePicture == " "
                              ? userimage
                              : this.state.profilePicture
                          }
                          height={150}
                          width={150}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="memberprofile"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="memberphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid
                      item
                      align="center"
                      xs={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="memberprofilefile"
                          fileName={"_memberprofilefile"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={getImageMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      <CustomSelectField
                        paramValue="status"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                            selected: "selected",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberaddress_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid> */}
                    <Grid item xs={12} className={{ justifyContent: "flex-start" }} >
                      {
                        <FormControl
                          fullWidth
                          required={true}
                          error={false}
                        >
                          <InputLabel id="ProfilesValueID">
                            Select Profile/Profiles
                          </InputLabel>
                          <Select
                            multiple
                            value={(this.state.profilesValue!=undefined)?this.state.profilesValue.map( (item) => item.id):[]}
                            onChange={this.handleChange}
                            input={<Input id="select-multiple" />}
                          >
                            {this.state.profilesList.map(profile => (
                              <MenuItem
                                key={profile.name}
                                value={profile.id}                          
                              >
                                {profile.name}
                              </MenuItem>
                            ))}
                          </Select>                    
                        </FormControl>
                      }
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }}>
                        <CustomTextField paramValue="membercompany" state={this.state} onChange={this.handleParam} />                      
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberlinkedin"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="memberfacebook"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>                    
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="memberexcerpt"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="membersummary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid
                      item
                      mt={4}
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography color="primary" variant="h5" size="sm" mt={4}>
                        Documents Section
                      </Typography>
                      <Divider style={{ margin: "5px 0 15px 0" }} />
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        User Profile Image
                      </Typography>
                    </Grid> */}
                    {/* <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      {
                        <CustomUploadButton
                          paramValue="memberprofilefile"
                          state={this.state}
                          acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid> */}

                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        NDA Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="memberndafile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="membercontractfile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    ></Grid>
                  </Grid>

                  <MUIDataTable
                        title="Services"
                        data={this.state.memberServicesList}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "name",
                            label: "Service Name",
                          },
                          {
                            name: "price",
                            label: "Price",
                          },

                          // {
                          //   name: "Actions",
                          //   options: {
                          //     filter: false,

                          //     sort: false,
                          //     empty: true,
                          //     customBodyRender: (
                          //       value,
                          //       tableMeta,
                          //       updateValue,
                          //     ) => {
                          //       return this.customActionRenderDocument(
                          //         value,
                          //         tableMeta,
                          //         updateValue,
                          //       );
                          //     },
                          //   },
                          // },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          fixedHeader: true,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                      />

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          // disabled={
                          //   loginValue.length === 0 ||
                          // }
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Update Member"}
                            </div>
                          ) : (
                            "Update Member"
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleProfileChanges = (e) => {      
    this.setState({
      memberprofileValue: e.target.value
    });
  }
  
  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false,
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "AddMember." + param + "Label";
    let transPlaceHolder = "AddMember." + param + "PlaceHolder";
    let transErrorMsg = "AddMember." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };

  handleGetAllProfiles = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, profilesList: []});

    let profiles = await getProfilesAPI();
    if (profiles != undefined && profiles.length > 0) {
      // console.log("profiles Found Successfully ::", profiles.length);
      // const result = Object.values(profiles);      
      // let result = Object.keys(profiles).map((key) => profiles[key]);
      // console.log("profiles Found Successfully ::", result);
      this.setState({
        profilesList: profiles,
        isFormLoading: false,        
      });
      console.log("profiles Table Data ", profiles);
    } else {
      console.log("profiles Not found");
      this.setState({ isFormLoading: false });
    }
  }

  
  handleChange = event => {
    let tmpProfilesList = [];
    event.target.value.forEach(profileID => {
      let newelement = this.state.profilesList.find(obj =>  { 
          console.log("Current event target value :: " ,obj.id, " :: " , profileID);
          if(obj.id == profileID )
            return obj;
          });      
      console.log("newelement ::" , newelement);
      tmpProfilesList.push(newelement);
    }); 
    this.setState({ profilesValue: tmpProfilesList });

    // let newelement = this.state.profilesValue.find(obj =>  { 
    //           console.log("Current event target value :: " ,obj.id, " :: " , event.target.value);
    //           if(obj.id == event.target.value )
    //             return obj;
    //         });
    // this.setState(prevState => ({
    //   profilesValue: [...prevState.profilesValue, newelement]
    // }))
    // this.setState({ profilesValue:  });
  };

  handleGetMember = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, memberInfo: {} });
    let memberID = this.props.match.params.memberId;

    let memberInfo = await getMemberAPI(memberID);
    if (memberInfo != undefined) {
      let tmp = {};
      Object.entries(memberInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Member Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["memberprofilefileValue"],
        fileName: "memberprofilefile",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Member Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleGetAllMemberServices = async () => {
    let RC = false;
    let tmpServiceMember = [];
    this.setState({ isFormLoading: true, memberServicesList: []});
    let memberID = this.props.match.params.memberId;

    let memberServices = await getMemberServicesAPI({ member_id : memberID });
    if (memberServices != undefined && memberServices.length > 0) {

      memberServices.forEach(memberService => {
        let tmpArr = {service_id:memberService.id,name:memberService.title,price:memberService.pivot.price };
        tmpServiceMember.push(tmpArr);
      });

      this.setState({
        memberServicesList: tmpServiceMember,
        isFormLoading: false,        
      });
      console.log("memberServices Table Data ", tmpServiceMember);

    } else {
      console.log("memberServices Not found");
      this.setState({ isFormLoading: false });
    }
  }


  handleEditMember = async (param) => {
    console.log("createMember API Started");
    let memberID = this.props.match.params.memberId;
    let rc = await editMemberAPI(memberID, param);
    if (rc == true) {
      console.log("Member Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/members");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Member Not Updated");
    }
    console.log("createMember API ENDED");
  };

  handleGetCoFounders = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, memberInfo: {} });
    let memberID = this.props.match.params.memberId;

    let cofounders = await getCoFoundersAPI(memberID);
    if (cofounders != undefined) {
      console.log("Co-Founders Found Successfully", cofounders);

      this.setState({
        cofounders: cofounders,
        isFormLoading: false,
      });
    } else {
      console.log("Co-Founders Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditCoFounder = (value, tableMeta, updateValue) => {    
    this.props.history.push("/app/editCoFounder/"+tableMeta.rowData[0]);
  };

  handleDeleteCoFounder = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseCoFounder();
    let rc = await deleteCoFounderAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("CoFounder Deleted Successfully");
      this.handleGetCoFounders();
      toast.success("CoFounder Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("CoFounder Not Deleted");
  };

  handleCreateMemberDocument = async (param) => {
    console.log("createMember API Started");
    let memberID = this.props.match.params.memberId;
    let rc = await createMemberDocumentAPI(memberID, param);
    if (rc == true) {
      console.log("Member Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editmember");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Member Documfdoent Not Added");
    }
    console.log("createMember API ENDED");
  };

  handleGetMemberDocument = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, documents: [] });

    let memberID = this.props.match.params.memberId;

    let documents = await getMemberDocumentAPI(memberID);
    if (documents != undefined) {
      console.log("Documents Found Successfully", documents);
      this.setState({
        documents: documents,
        isFormLoading: false,
      });
    } else {
      console.log("Member Document Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditMemberDocument = async (value, tableMeta, updateValue) => {    
    this.props.history.push("/app/editdocument/"+tableMeta.rowData[0]);
  };

  handleDeleteMemberDocument = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseMemberDocument();
    let rc = await deleteMemberDocumentAPI(tableMeta.rowData[0]);
    if (rc == true) {
      this.handleGetMemberDocument();

      console.log("Member Document Deleted Successfully");
      toast.success("Document Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("Document Not Deleted");
  };

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    // Object.entries(this.state).map(([key, value]) => {
    //   if (key.includes("Value")) param[key.replace("Value", "")] = value;
    // });
    
    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) {
        if(key == 'profilesValue')
        {     //if(typeof value === 'object'){     
          let tmpProfilesArr = [];
          value.forEach(profileElem => {
            if(typeof value === 'object') {
              tmpProfilesArr.push(profileElem.id);              
            }
            // console.log("Current Element :: ",JSON.stringify(profileElem));
          });     
          console.log("Current tmpProfilesArr :: ",tmpProfilesArr);
          param[key.replace("Value", "")] = tmpProfilesArr;
        }
        else
          param[key.replace("Value", "")] = value;
      }
    });


    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleEditMember(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [param + "MediaOpen"]: false,
    });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditCoFounder(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit CoFounder" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Member "' + tableMeta.rowData[1] + '"',
              );
              if (retVal == true) {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
            // onClick={() =>
            //   this.handleDeleteDialogMember(value, tableMeta, updateValue)
            // }
          >
            <Tooltip title="Delete Current CoFounder" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogCoFounder}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseCoFounder}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting CoFounder
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCoFounder} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  customActionRenderDocument = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditMemberDocument(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit MemberDocument" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Document "' +
                  tableMeta.rowData[1] +
                  '"',
              );
              if (retVal == true) {
                this.handleDeleteMemberDocument(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
            // onClick={() =>
            //   this.handleDeleteDialogDocument(value, tableMeta, updateValue)
            // }
          >
            <Tooltip title="Delete Current MemberDocument" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogMemberDocument}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseMemberDocument}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting MemberDocument
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseMemberDocument} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteMemberDocument(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  handleDeleteDialogDocument = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogMemberDocument: true });
  };

  handleCloseMemberDocument = () => {
    this.setState({ deleteDialogMemberDocument: false });
  };

  handleCloseCoFounder = () => {
    this.setState({ deleteDialogCoFounder: false });
  };

  handleDeleteDialogMember = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };
}
