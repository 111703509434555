import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getStartupsAPI,
  getStartupAPI,
  createStartupAPI,
  editStartupAPI,
  getCoFoundersAPI,
  deleteCoFounderAPI,
  createStartupDocumentAPI,
  getStartupDocumentAPI,
  editStartupDocumentAPI,
  deleteStartupDocumentAPI,
} from "../../APIs/startupApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
} from "../../APIs/ApiUtils";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
//const userimage = require('./../../images/userimage.png')
import userimage from "./../../images/userimage.png";
var requiredParam = [
  "companyname",
  "companyurl",
  "producturl",
  "demolink",
  "aboutcompany",
  "operatingin",
  "address_id",
  "contactname",
  "contactemail",
  "contactphone",
  "category_id",
  "isproductused",
  "isrevenue",
  "isalreadyparticipate",
  "previouscompany",
  "islegalentity",
  "isalreadyinvestment",
  "companylogo",
  "profileimage",
  "businessplan",
  "ndadoc",
  "contractdoc",
  "incorporationdate",
];

let categories = [
  {
    value: 1,
    label: "Agriculture",
  },
  {
    value: 2,
    label: "Aerospace",
  },
];


export default class EditStartup extends Component {
  constructor() {
    super();
    this.state = {
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogStartupDocument: false,

      cofounders: [],
      documents: [],

      category_idLabel: <Trans>CommonField.category_idLabel</Trans>,
      category_idID: "category_id",
      category_idPlaceHolder: (
        <Trans>CommonField.category_idPlaceHolder</Trans>
      ),
      category_idValue: "",
      category_idErrorStatus: false,
      category_idErrorMsg: "Error in Selection",
      category_idFieldType: "SelectField",
      category_idFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: false,
      },

      contactnameLabel: <Trans>CommonField.contactnameLabel</Trans>,
      contactnameID: "contactname",
      contactnamePlaceHolder: <Trans>CommonField.contactnamePlaceHolder</Trans>,
      contactnameValue: "",
      contactnameErrorStatus: false,
      contactnameErrorMsg: "Error in Name",
      contactnameFieldType: "TextField",
      contactnameFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: false,
      },

      contactemailLabel: <Trans>CommonField.contactemailLabel</Trans>,
      contactemailID: "contactemail",
      contactemailPlaceHolder: <Trans>CommonField.contactemailPlaceHolder</Trans>,
      contactemailValue: "",
      contactemailErrorStatus: false,
      contactemailErrorMsg: "Error in Name",
      contactemailFieldType: "TextField",
      contactemailFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: false,
      },

      contactphoneLabel: <Trans>CommonField.contactphoneLabel</Trans>,
      contactphoneID: "contactphone",
      contactphonePlaceHolder: <Trans>CommonField.contactphonePlaceHolder</Trans>,
      contactphoneValue: "",
      contactphoneErrorStatus: false,
      contactphoneErrorMsg: "Error in Phone",
      contactphoneFieldType: "TextField",
      contactphoneFieldValidation: {
        maxLength: 12,
        minLength: 0,
        checkEmpty: false,
      },

      ageLabel: <Trans>CommonField.ageLabel</Trans>,
      ageID: "age",
      agePlaceHolder: <Trans>CommonField.agePlaceHolder</Trans>,
      ageValue: "",
      ageErrorStatus: false,
      ageErrorMsg: "Error in Age",

      incorporationdateLabel: <Trans>CommonField.incorporationdateLabel</Trans>,
      incorporationdateID: "incorporationdate",
      incorporationdatePlaceHolder: <Trans>CommonField.incorporationdatePlaceHolder</Trans>,
      incorporationdateValue: "",
      incorporationdateErrorStatus: false,
      incorporationdateErrorMsg: "Error in incorporationdate",

      profileimageLabel: <Trans>CommonField.profileimageLabel</Trans>,
      profileimageID: "profileimage",
      profileimagePlaceHolder: (
        <Trans>CommonField.profileimagePlaceHolder</Trans>
      ),
      profileimageValue: "",
      profileimageErrorStatus: false,
      profileimageErrorMsg: "Error in profileimage",

      statusLabel: <Trans>CommonField.statusLabel</Trans>,
      statusID: "status",
      statusPlaceHolder: <Trans>CommonField.statusPlaceHolder</Trans>,
      statusValue: "",
      statusErrorStatus: false,
      statusErrorMsg: "Error in status",

      isproductusedLabel: <Trans>CommonField.isproductusedLabel</Trans>,
      isproductusedID: "isproductused",
      isproductusedPlaceHolder: <Trans>CommonField.isproductusedPlaceHolder</Trans>,
      isproductusedValue: "",
      isproductusedErrorStatus: false,
      isproductusedErrorMsg: "Error in isproductused",

      is_permanent_addresLabel: (
        <Trans>CommonField.is_permanent_addresLabel</Trans>
      ),
      is_permanent_addresID: "is_permanent",
      is_permaneant_addresPlaceHolder: (
        <Trans>CommonField.is_permanent_addresPlaceHolder</Trans>
      ),
      is_permanent_addresValue: "",
      is_permanent_addresErrorStatus: false,
      is_permanent_addresErrorMsg: "Error in Is_permanent_addres Name",

      previouscompanyLabel: <Trans>CommonField.previouscompanyLabel</Trans>,
      previouscompanyID: "previouscompany",
      previouscompanyPlaceHolder: <Trans>CommonField.previouscompanyPlaceHolder</Trans>,
      previouscompanyValue: "",
      previouscompanyErrorpreviouscompany: false,
      previouscompanyErrorMsg: "Error in previouscompany",

      aboutcompanyLabel: <Trans>CommonField.aboutcompanyLabel</Trans>,
      aboutcompanyID: "aboutcompany",
      aboutcompanyPlaceHolder: <Trans>CommonField.aboutcompanyPlaceHolder</Trans>,
      aboutcompanyValue: "",
      aboutcompanyErroraboutcompany: false,
      aboutcompanyErrorMsg: "Error in aboutcompany",

      companynameLabel: <Trans>CommonField.companynameLabel</Trans>,
      companynameID: "companyname",
      companynamePlaceHolder: <Trans>CommonField.companynamePlaceHolder</Trans>,
      companynameValue: "",
      companynameErrorStatus: false,
      companynameErrorMsg: "Error in companyname",

      operatinginLabel: <Trans>CommonField.operatinginLabel</Trans>,
      operatinginID: "operatingin",
      operatinginPlaceHolder: <Trans>CommonField.operatinginPlaceHolder</Trans>,
      operatinginValue: "",
      operatinginErrorStatus: false,
      operatinginErrorMsg: "Error in operatingin",

      stateLabel: <Trans>CommonField.stateLabel</Trans>,
      stateID: "state",
      statePlaceHolder: <Trans>CommonField.statePlaceHolder</Trans>,
      stateValue: "",
      stateErrorStatus: false,
      stateErrorMsg: "Error in state",

      cityLabel: <Trans>CommonField.cityLabel</Trans>,
      cityID: "city",
      cityPlaceHolder: <Trans>CommonField.cityPlaceHolder</Trans>,
      cityValue: "",
      cityErrorStatus: false,
      cityErrorMsg: "Error in City",

      producturlLabel: <Trans>CommonField.producturlLabel</Trans>,
      producturlID: "producturl",
      producturlPlaceHolder: <Trans>CommonField.producturlPlaceHolder</Trans>,
      producturlValue: "",
      producturlErrorStatus: false,
      producturlErrorMsg: "Error in City",

      isrevenueLabel: <Trans>CommonField.isrevenueLabel</Trans>,
      isrevenueID: "isrevenue",
      isrevenuePlaceHolder: <Trans>CommonField.isrevenuePlaceHolder</Trans>,
      isrevenueValue: "",
      isrevenueErrorStatus: false,
      isrevenueErrorMsg: "Error in City",

      demolinkLabel: <Trans>CommonField.demolinkLabel</Trans>,
      demolinkID: "demolink",
      demolinkPlaceHolder: <Trans>CommonField.demolinkPlaceHolder</Trans>,
      demolinkValue: "",
      demolinkErrorStatus: false,
      demolinkErrorMsg: "Error in City",

      address_idLabel: <Trans>CommonField.address_idLabel</Trans>,
      address_idID: "address_id",
      address_idPlaceHolder: <Trans>CommonField.address_idPlaceHolder</Trans>,
      address_idValue: "",
      address_idErrorStatus: false,
      address_idErrorMsg: "Error in Family ID",

      businessplanLabel: <Trans>CommonField.businessplanLabel</Trans>,
      businessplanID: "businessplan",
      businessplanPlaceHolder: <Trans>CommonField.businessplanPlaceHolder</Trans>,
      businessplanValue: "",
      businessplanErrorbusinessplan: false,
      businessplanErrorMsg: "Error in businessplan",

      ndadocLabel: <Trans>CommonField.ndadocLabel</Trans>,
      ndadocID: "ndadoc",
      ndadocPlaceHolder: (
        <Trans>CommonField.ndadocPlaceHolder</Trans>
      ),
      ndadocValue: "",
      ndadocError: false,
      ndadocErrorMsg: "Error in ndadoc",

      contractdocLabel: <Trans>CommonField.contractdocLabel</Trans>,
      contractdocID: "contractdoc",
      contractdocPlaceHolder: (
        <Trans>CommonField.contractdocPlaceHolder</Trans>
      ),
      contractdocValue: "",
      contractdocErrorcontractdoc: false,
      contractdocErrorMsg: "Error in contractdoc",

      companylogoLabel: <Trans>CommonField.companylogoLabel</Trans>,
      companylogoID: "companylogo",
      companylogoPlaceHolder: <Trans>CommonField.companylogoPlaceHolder</Trans>,
      companylogoValue: "",
      companylogoErrorcompanylogo: false,
      companylogoErrorMsg: "Error in companylogo",

      profilePicture: "",
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetStartup();
    await this.handleGetCoFounders();
    await this.handleGetStartupDocument();
  };

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>ViewStartup.pageTitle</Trans>}
                  button={
                    <div>
                    { /*(this.state.permissions != undefined &&
                        this.state.permissions.includes("startup_create")) &&*/ (
                          <Button
                            style={{ marginLeft: "10px" }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              this.props.history.push("/app/viewservices");
                            }}
                          >
                            <Trans>ViewStartup.viewServices</Trans>
                          </Button>
                        )}
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/startups");
                      }}
                    >
                      Back
                    </Button>
                    </div>
                  }
                      />            
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 16 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                    style={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  >
                    Personal Info
                  </Typography>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        paramValue="category_id"
                        required="required"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={categories}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="contactname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <div>
                        <Typography>Profile Picture</Typography>

                        <img
                          src={
                            this.state.profilePicture == " "
                              ? userimage
                              : this.state.profilePicture
                          }
                          height={150}
                          width={150}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="contactemail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="profileimage"
                          fileName={this.state.contactnameValue + "_profileimage"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                            "application/pdf",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="contactphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          id="incorporationdate"
                          label="Enter DOB"
                          format="yyyy-MM-dd"
                          openTo="year"
                          views={["year", "month", "date"]}
                          label="Enter Incorporation Date"
                          format="yyyy-MM-dd"
                          minDate={new Date("1920-03-01")}
                          selected={this.state.incorporationdateValue}
                          value={this.state.incorporationdateValue}
                          onChange={(e) => {
                            let value = moment(e).format("yyyy-MM-DD");
                            console.log("Current Date", e, value);
                            this.handleParam(value, "incorporationdate", true);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          error={this.state.incorporationdateErrorStatus}
                          helperText={
                            this.state.incorporationdateErrorStatus
                              ? this.state.incorporationdateErrorMsg
                              : ""
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        paramValue="status"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                            selected: "selected",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="aboutcompany"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="companyname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomSelectField
                          paramValue="operatingin"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={[
                            {
                              value: "INDIA",
                              label: "India",
                              selected: "selected",
                            },
                            {
                              value: "USA",
                              label: "USA",
                            },
                          ]}
                        />
                      }
                    </Grid>


                     <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="is_permanent_addres"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: 1,
                            label: "Same as above",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isrevenue"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isproductused"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="producturl"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="demolink"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>



                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="address_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography
                        color="primary"
                        variant="h5"
                        size="sm"
                        style={{
                          borderBottom: "1px solid #000",
                          marginTop: 30,
                        }}
                      >
                        Documents
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Company Logo
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="companylogo"
                        fileName={this.state.contactnameValue + "_uploadedform"}
                        maxFileNo={2}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        NDA Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="ndadoc"
                          fileName={this.state.contactnameValue + "_ndadoc"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                            "application/pdf",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Business Plan 
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="businessplan"
                        fileName={this.state.contactnameValue + "_businessplan"}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="contractdoc"
                        fileName={this.state.contactnameValue + "_rationcard"}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "space-between" }}
                    >
                      <MUIDataTable
                        title="Co-Founders"
                        data={this.state.cofounders}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "name",
                            label: "Name",
                          },
                          {
                            name: "designation",
                            label: "Designation",
                          },
                          {
                            name: "partnershippercentage",
                            label: "Equity",
                          },
                          {
                            name: "aboutcofounder",
                            label: "About",
                          },
                          {
                            name: "Actions",
                            options: {
                              filter: true,
                              sort: false,
                              empty: true,
                              customBodyRender: (
                                value,
                                tableMeta,
                                updateValue,
                              ) => {
                                return this.customActionRender(
                                  value,
                                  tableMeta,
                                  updateValue,
                                );
                              },
                            },
                          },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          fixedHeader: true,
                          filter: false,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                        // components={components}
                      />


                      <MUIDataTable
                        title="Uploaded Documents"
                        data={this.state.documents}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "title",
                            label: "Title",
                          },
                          {
                            name: "description",
                            label: "Description",
                          },

                          {
                            name: "Actions",
                            options: {
                              filter: false,

                              sort: false,
                              empty: true,
                              customBodyRender: (
                                value,
                                tableMeta,
                                updateValue,
                              ) => {
                                return this.customActionRenderDocument(
                                  value,
                                  tableMeta,
                                  updateValue,
                                );
                              },
                            },
                          },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          fixedHeader: true,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                        // components={components}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {} });
    let startup_id = this.props.match.params.startupId;

    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      let tmp = {};
      Object.entries(startupInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Startup Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["profileimageValue"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditStartup = async (param) => {
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.startupId;
    let rc = await editStartupAPI(startup_id, param);
    if (rc == true) {
      console.log("Startup Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/startups");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Not Updated");
    }
    console.log("createStartup API ENDED");
  };

  handleGetCoFounders = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {} });
    let startup_id = this.props.match.params.startupId;

    let cofounders = await getCoFoundersAPI(startup_id);
    if (cofounders != undefined) {
      console.log("Co-Founders Found Successfully", cofounders);

      this.setState({
        cofounders: cofounders,
        isFormLoading: false,
      });
    } else {
      console.log("Co-Founders Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditCoFounder = (value, tableMeta, updateValue) => {
    this.props.history.push("/app/editCoFounder/"+ this.props.match.params.startupId +"/"+tableMeta.rowData[0]);

  };

  handleDeleteCoFounder = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseCoFounder();
    let rc = await deleteCoFounderAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("CoFounder Deleted Successfully");
      this.handleGetCoFounders();
      toast.success("CoFounder Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("CoFounder Not Deleted");
  };

  handleCreateStartupDocument = async (param) => {
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.startupId;
    let rc = await createStartupDocumentAPI(startup_id, param);
    if (rc == true) {
      console.log("Startup Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editstartup");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Documfdoent Not Added");
    }
    console.log("createStartup API ENDED");
  };

  handleGetStartupDocument = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, documents: [] });
    let startup_id = this.props.match.params.startupId;

    let documents = await getStartupDocumentAPI(startup_id);
    if (documents != undefined) {
      console.log("Documents Found Successfully", documents);
      this.setState({
        documents: documents,
        isFormLoading: false,
      });
    } else {
      console.log("Startup Document Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditStartupDocument = async (value, tableMeta, updateValue) => {
    this.props.history.push("/app/editdocument/"+this.props.match.params.startupId+"/"+tableMeta.rowData[0]); 
  };

  handleDeleteStartupDocument = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseStartupDocument();
    let rc = await deleteStartupDocumentAPI(tableMeta.rowData[0]);
    if (rc == true) {
      this.handleGetStartupDocument();

      console.log("Startup Document Deleted Successfully");
      toast.success("Document Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("Document Not Deleted");
  };

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleEditStartup(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [param + "MediaOpen"]: false,
    });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditCoFounder(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit CoFounder" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Member "' + tableMeta.rowData[1] + '"',
              );
              if (retVal == true) {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
            // onClick={() =>
            //   this.handleDeleteDialogMember(value, tableMeta, updateValue)
            // }
          >
            <Tooltip title="Delete Current CoFounder" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogCoFounder}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseCoFounder}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting CoFounder
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCoFounder} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  customActionRenderDocument = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditStartupDocument(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit StartupDocument" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Document "' +
                  tableMeta.rowData[1] +
                  '"',
              );
              if (retVal == true) {
                this.handleDeleteStartupDocument(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
            // onClick={() =>
            //   this.handleDeleteDialogDocument(value, tableMeta, updateValue)
            // }
          >
            <Tooltip title="Delete Current StartupDocument" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogStartupDocument}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseStartupDocument}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting StartupDocument
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStartupDocument} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteStartupDocument(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  handleDeleteDialogDocument = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogStartupDocument: true });
  };

  handleCloseStartupDocument = () => {
    this.setState({ deleteDialogStartupDocument: false });
  };

  handleCloseCoFounder = () => {
    this.setState({ deleteDialogCoFounder: false });
  };

  handleDeleteDialogMember = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };
}
