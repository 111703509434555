import React, { Fragment, useState,  useEffect, Component } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useHistory, useParams  } from "react-router-dom";
import ReactDOM from "react-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
    getPaymentAPI,
} from "../../APIs/paymentApis";

import {
  currencySymbol,
  getLocaleShortDateString,
} from "../../helpers";

function ViewPayment() {
  const history = useHistory();
  const params = useParams();
  const [paymentId, SetPaymentId] = useState(undefined);
  const [paymentInfo, SetPaymentInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    handleGetPayment();
  }, []);

  return (
    <>
      {
      isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-white">
              <div className="card-heading clearfix border-bottom my-3 mx-3">
                  <h4 className="card-title">Payment Detail</h4>
              </div>
              <div className="card-body">
                <div className="mt-4">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th scope="row">Done By</th>
                      <td>
                        <Link to={ "/member/" + (paymentInfo?.user?.id) }  className="text-tertiary">
                          { paymentInfo?.user?.name }
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td>
                        {paymentInfo?.status}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Amount</th>
                      <td>
                        {currencySymbol() + paymentInfo?.amount}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Transaction ID</th>
                      <td>
                        {paymentInfo?.transaction_id}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Created At</th>
                      <td>{getLocaleShortDateString(paymentInfo?.created_at)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Taxes</th>
                      <td>{currencySymbol() + paymentInfo?.taxes}</td>
                    </tr>                            
                    <tr>
                      <th scope="row">Other Chages</th>
                      <td>
                        {currencySymbol() + paymentInfo?.charges}
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
  </>
  )

  async function handleGetPayment () {
    let RC = false;

    setIsFormLoading(true);
    let payment_id = params.paymentId;
    let paymentInfo = await getPaymentAPI({id: payment_id});

    if (paymentInfo!=undefined) {
      console.log("Payment  Found Successfully", paymentInfo);
      SetPaymentInfo(paymentInfo);
      setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };

}

export default ViewPayment;

