import React, { Component, Fragment } from "react";

import PageTitle from "../../../components/PageTitle/PageTitle";

// FOR APIS
import { getGeneralSettingsAPI, addGeneralSettingsAPI } from "./../../../APIs/generalsettingsApis";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

class GeneralSettings extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      value: "",
      description: "",
      generalsettings: [],
      loader: true,
      errorLoader: true,
      responseFlag: false,
      responseText: "",
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.getGeneralSettingDetails();
  }

  getGeneralSettingDetails = async () => {
    let details = await getGeneralSettingsAPI();
    if (details.status == "success") {
      this.setState({ generalsettings: details.response.Settings });
      this.setState({ loader: false });
    } else {
      this.setState({ errorLoader: false });
      this.setState({ loader: false });
    }
    console.log(
      "All General Settings with its permissions details in generalsettings file:" +
        JSON.stringify(details),
    );
  };
  setname = (e) => {
    this.setState({ name: e.target.value });
    console.log("the full name is :" + e.target.value);
  };

  setvalue = (e) => {
    this.setState({ value: e.target.value });
    console.log("the value is :" + e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    console.log("the description is :" + e.target.value);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleSubmit = async () => {
    if (
      this.state.name.length > 0 &&
      this.state.value.length > 0 &&
      this.state.description.length > 0
    ) {
      let response = await addGeneralSettingsAPI(
        this.state.name,
        this.state.value,
        this.state.description,
      );
      console.log("Response of add generalsettings is :" + JSON.stringify(response));
      if (response.status === "success") {
        this.setState({
          name: "",
          value: "",
          description: "",
          responseFlag: true,
          responseText: "General-Setting created successfully !",
        });
        this.getGeneralSettingDetails();
      } else {
        this.setState({
          responseFlag: true,
          responseText: "Something went wrong , please try again !",
        });
      }
    }
  };

  render() {
    return (
      <div>
        {/* <p>Hi, This is generalsettings page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="General Settings"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/");
                  }}
                >
                  <HomeIcon /> Home
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.loader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.errorLoader ? (
                <div>
                  <div className="row my-4">
                    <div className="col-12 my-2">
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label="Name"
                          id="name"
                          name="name"
                          // placeholder="enter your name"
                          value={this.state.name}
                          // placeholder=""
                          onChange={this.setname}
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 my-2">
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label="Description"
                          placeholder="Description"
                          id="description"
                          name="description"

                          value={this.state.description}
                          onChange={this.setDescrition}
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 my-2">
                      <FormControl fullWidth>
                        <TextareaAutosize
                          //aria-label="minimum height"
                          //  className="border10 "
                          label="Value"
                          id="value"
                          name="value"
                          placeholder="Value"
                          rowsMin={1}
                          value={this.state.value}
                          onChange={this.setvalue}
                          style={{ fontSize: "16px", backgroundColor: "transparent" }}
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 my-2">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                        {this.state.responseFlag ? (
                          <Typography
                            color="primary"
                            variant="h5"
                            size="sm"
                            style={{
                              borderBottom: "2px solid #FF7F50",
                              marginTop: 30,
                            }}
                          >
                            {this.state.responseText}
                          </Typography>
                        ) : (
                          <Typography
                            color="primary"
                            variant="h5"
                            size="sm"
                            style={{
                              borderBottom: "2px solid #FF7F50",
                              marginTop: 30,
                            }}
                          ></Typography>
                        )}
                    </div>
                    <div className="col-12 my-2">
                    <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Setting Name</TableCell>
                                <TableCell align="left">Value</TableCell>
                                <TableCell align="left">Edit</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state?.generalsettings
                                .slice(
                                  this.state?.page * this.state?.rowsPerPage,
                                  this.state?.page * this.state?.rowsPerPage +
                                    this.state?.rowsPerPage,
                                )
                                .map((row) => (
                                  <Fragment>
                                    {/* <TableRow>
                              
                                  <TableCell rowSpan = {row.permissions.length + 1}>
                                  <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() => {                                      
                                        this.props.history.push({
                                            pathname: '/app/editgeneralsetting',
                                            state: [{id:row.id}] // your data array of objects
                                        });
                                      }}
                                  >
                                      Edit
                                  </Button>
                                  </TableCell>
                                  
                                  <TableCell rowSpan = {row.permissions.length + 1}>
                                    {row.name}
                                  </TableCell>
                              </TableRow> */}
                                    {/* {row.permissions.map(detail => (
                                  <TableRow>
                                    <TableCell>{detail.name}</TableCell>
                                  </TableRow>  
                                  ))} */}

                                    <TableRow>
                                      <TableCell>{row.name}</TableCell>

                                      <TableCell>{row.value}</TableCell>

                                      <TableCell>
                                        <Button
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() => {
                                            this.props.history.push({
                                              pathname: "/app/editgeneralsetting/"+row.id,
                                              state: [{ id: row.id }], // your data array of objects
                                            });
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </Fragment>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 15]}
                          component="div"
                          count={this.state.generalsettings.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          className="pagination"
                          style={{ float: "right" }}
                        />
                    </div>

                  </div>

{/* 
                  <Paper style={{ padding: 16 }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className={{
                          justifyContent: "flex-start",
                          borderBottom: "1px solid #000",
                        }}
                      >

                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={{ justifyContent: "flex-start" }}
                      >

                      </Grid>
                    </Grid>
                  </Paper> */}
                </div>
              ) : (
                <div>
                  <p>Something went wrong,please try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default GeneralSettings;
