import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  CircularProgress,
} from "@material-ui/core";

// FOR APIS  ====================================================================
import {
  getStartupsAPI,
  createStartupAPI,
  getStartupAPI,
  createStartupCoFounderAPI,
} from "../../APIs/startupApis";

import { getDesignationDataArray,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";

// IMAGES  ====================================================================


class AddCoFounder extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      isSubmitLoading: false,
      isError: false,
      designationTable: getDesignationDataArray(),
    };
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={4}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddFamilyMemeber.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/editstartup/"+this.props.match.params.startupId);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <Typography>Personal Info</Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                        required='required'

                          paramValue="name"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        required='required'
                        paramValue="designation"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={this.state.designationTable}
                      />
                    </Grid>


                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="partnershippercentage"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="aboutcofounder"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                  </Grid>


                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                      mt={4}

                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Documents
                      </Typography>
                      <Divider />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Profile Image
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="profileimage"
                          state={this.state}
                          acceptedFiles={getImageMIMETypes()}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Other Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="otherdocument"
                        state={this.state}
                        acceptedFiles={getMediaMIMETypes()}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Create Co-Founder
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.setStateVariables("name", {checkEmpty: true,minLength: 5,maxLength: 60,});
    this.setStateVariables("designation",{checkEmpty: true,},"SelectField",);
    this.setStateVariables("partnershippercentage",{ checkEmpty: false },"NumberField",);
    this.setStateVariables("aboutcofounder", { checkEmpty: false });
    this.setStateVariables("profileimage", { checkEmpty: false }, "FileField");
    this.setStateVariables("otherdocument", { checkEmpty: false }, "FileField");

    this.handleGetStartup();
  }

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ loader: true, startupInfo: [] });
    let startupId = this.props.match.params.startupId;
    let startupInfo = await getStartupAPI(startupId);
    if (startupInfo != undefined) {
      console.log("CoFounder Found Successfully");
      this.setState({
        startupInfo: startupInfo,
        loader: false,
      });
    } else {
      console.log("CoFounder Not found");
      this.setState({ loader: false });
    }
  };

  handleSubmitButton = async (param) => {
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.startupId;

    let rc = await createStartupCoFounderAPI(startup_id, param);
    if (rc == true) {
      console.log("CoFounder Co-Founder Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/editstartup/"+this.props.match.params.startupId);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("CoFounder Co-Founder  Not Added");
    }
    console.log("createStartupCoFounder API ENDED");
  };

}

export default AddCoFounder;
