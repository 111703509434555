import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
  getInvestorsPaginationAPI,
  createInvestorAPI,
  createInvestorDocumentAPI,
  createInvestorCoFounderAPI,
  deleteCoFounderAPI,
  deleteInvestorAPI,
  deleteInvestorDocumentAPI,  
  editInvestorAPI,
  editInvestorDocumentAPI,
  editInvestorCoFounderAPI,
  getCoFounderAPI,
  getCoFoundersAPI,
  getInvestorAPI,
  getInvestorDocumentAPI,
  getInvestorDocumentByIDAPI,
  getInvestorsAPI,
};

async function getInvestorsPaginationAPI(limit, page) {
  let investors = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("investor/pagination/" + limit + "/" + page);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.investors.map((investor) => {
        let tmp = {};
        Object.entries(investor).map(([key, value]) => {
          tmp[key] = value;
        });
        investors.push(tmp);
      });
      return investors;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investors;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getInvestorsAPI() {
  let investors = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("investor");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.investors.map((investor) => {
        let tmp = {};
        Object.entries(investor).map(([key, value]) => {
          tmp[key] = value;
        });
        investors.push(tmp);
      });
      return investors;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investors;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function getInvestorAPI(investor_id) {
  let investor = undefined;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("investor/" + investor_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      investor = response.data.response.investor;
      return investor;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investor;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createInvestorAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("investor", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.investoractivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Investor Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editInvestorAPI(investor_id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("investor/" + investor_id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.investoractivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Investor Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteInvestorAPI(investor_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("investor/" + investor_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getCoFoundersAPI(investor_id) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("investor/member/" + investor_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.members.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getCoFounderAPI(member_id) {
  let investor = undefined;

  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("cofounder/" + member_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      investor = response.data.response.cofounder;
      return investor;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investor;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createInvestorCoFounderAPI(investor_id, param) {
  let token = localStorage.getItem("id_token");

  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  formData.append("cofounderable_type", "App\\Models\\Persona\\investor");
  formData.append("cofounderable_id", investor_id);

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("cofounder", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.investoractivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Co-Founder Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editInvestorCoFounderAPI(member_id, param) {
  let token = localStorage.getItem("id_token");
  console.log("<MEMBER ID> :: ", member_id);
  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "cofounder/" + member_id + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.investoractivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Co-Founder Updated Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteCoFounderAPI(member_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("cofounder/" + member_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getInvestorDocumentAPI(investor_id) {
  let investors = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("investor/document/" + investor_id);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    response.data.response.documents.map((investor) => {
      let tmp = {};
      Object.entries(investor).map(([key, value]) => {
        tmp[key] = value;
      });
      investors.push(tmp);
    });
    return investors;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return investors;
  }
}

async function getInvestorDocumentByIDAPI(document_id) {
  let document = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("document/" + document_id);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    document = response.data.response.document;
    return document;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return document;
  }
}

async function createInvestorDocumentAPI(investor_id,param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  // Object.entries(prices).map(([key, value]) => [key, value * 2])
  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("documentable_type", "App\\Models\\Persona\\Investor");
  formData.append("documentable_id", investor_id);

  console.log(formData);

  try {
    API.defaults.timeout = 600000;
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("document", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.investoractivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Investor Document Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editInvestorDocumentAPI(document_id , param) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("formData", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "document/" + document_id + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.investoractivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Document edited successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteInvestorDocumentAPI(document_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("document/" + document_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}
