import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import themeAPP from "../../themes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getDesignationDataArray,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Grid,
  Button,
  CircularProgress,
  Slide
} from "@material-ui/core";
// data
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// MATERIAL UI DEPENDENCIES
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS  ====================================================================
import {
  deleteBlogCategoryAPI,
  getBlogCategoriesAPI,
} from "../../APIs/blogcategoryApis";


// IMAGES  ====================================================================


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class BlogCategories extends BaseComponentClass {
  constructor(props) {
    super(props);
    this.state = {
      blogcategoriesTable: [],
      loader: false,
      deleteDialog: false,
      isRecent: true,
      permissions: undefined,
      roles: undefined,
    };
  }

  render() {
    const components = {
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid xs={12} container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>BlogCategories.pageTitle</Trans>}
                  button={
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          this.state.isRecent
                            ? this.handleGetBlogCategories()
                            : this.handleGetBlogCategoriesPagination();
                        }}
                      >
                        {this.state.isRecent ? (
                          <Trans>Get All</Trans>
                        ) : (
                          <Trans>Get Recent</Trans>
                        )}
                      </Button>
                      { /*(this.state.permissions != undefined &&
                        this.state.permissions.includes("blogcategory_create")) &&*/ (
                          <Button
                            style={{ marginLeft: "10px" }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              this.props.history.push("/app/blog/addcategories");
                            }}
                          >
                            <Trans>BlogCategories.submitBtn</Trans>
                          </Button>
                        )}
                    </div>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={{ justifyContent: "space-between" }}
              >
                <MuiThemeProvider theme={themeAPP.default}>
                  <MUIDataTable
                    title={
                      this.state.isRecent
                        ? "Recent Added BlogCategories"
                        : "All BlogCategories"
                    }
                    data={this.state.blogcategoriesTable}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "profilethumbnail",
                        label: "Profile",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <Avatar src={tableMeta.rowData[1]}>
                                {/* <AssignmentIcon /> */}
                              </Avatar>
                            );
                          },
                        },
                      },
                      {
                        name: "title",
                        label: "Title",
                      },
                      {
                        name: "detail",
                        label: "Detail",
                      },
                      {
                        name: "isactive",
                        label: "Status",
                      },
                      {
                        name: "Actions",
                        options: {
                          filter: true,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRender(
                              value,
                              tableMeta,
                              updateValue,
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      tableBodyMaxHeight: "400px",
                      selectableRows: false,
                      filter: !this.state.isRecent,
                      print: !this.state.isRecent,
                      download: !this.state.isRecent,
                      elevation: 0,
                      fixedHeader: true,
                      viewColumns: !this.state.isRecent,
                      filterType: "dropdown",
                      responsive: "standard",
                      expandableRows: false,
                    }}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    let userRole = localStorage.getItem("user_role");
    var userRoleArr = userRole.split(",");
    let userPermission = localStorage.getItem("user_permission");
    var userPermissionArr = userPermission.split(",");
    this.setState({ permissions: userPermissionArr, roles: userRoleArr });

    this.handleGetBlogCategories();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };


  handleGetBlogCategories = async () => {
    let RC = false;
    this.setState({ loader: true, blogcategoriesTable: [] });

    let blogcategories = await getBlogCategoriesAPI();
    if (blogcategories != undefined && blogcategories.length > 0) {
      console.log("BlogCategories Found Successfully");
      this.setState({
        blogcategoriesTable: blogcategories,
        loader: false,
        isRecent: false,
      });
    } else {
      console.log("Blogcategory Not found");
      this.setState({ loader: false });
    }
  };

  deleteBlogcategory = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleClose();
    let rc = await deleteBlogCategoryAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("Blogcategory Deleted Successfully");
      this.handleGetBlogCategories();
      toast.success("Blogcategory Deleted");
    } else console.log("Blogcategory Not Deleted");
  };

  editBlogcategory = (value, tableMeta, updateValue) => {
    this.props.history.push("/app/blog/editcategory/"+tableMeta.rowData[0]);
  };

  viewBlogcategory = (value, tableMeta, updateValue) => {
    
    this.props.history.push("/app/blog/categories/"+tableMeta.rowData[0]);
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <div style={{ display: "inline-flex", margin: "0px auto" }}>
        { /* this.state.permissions.includes("blogcategory_view") && */ (
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => this.viewBlogcategory(value, tableMeta, updateValue)}
            style={themeAPP.default.button}
          >
            <Tooltip title="View Blogcategory" aria-label="add">
              <Visibility />
            </Tooltip>
          </IconButton>
        )}
        { /* this.state.permissions.includes("blogcategory_update") && */ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="upload picture"
            onClick={() => this.editBlogcategory(value, tableMeta, updateValue)}
          >
            <Tooltip title="Edit Blogcategory" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        { /* this.state.permissions.includes("blogcategory_delete") && */ (
          <IconButton
            style={themeAPP.default.button}
            color="primary"
            aria-label="Delete Button"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete "' +
                  tableMeta.rowData[1] +
                  " " +
                  tableMeta.rowData[2] +
                  '"',
              );
              if (retVal == true) {
                this.deleteBlogcategory(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          >
            <Tooltip title="Delete Current Blogcategory" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
        <Dialog
          open={this.state.deleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting Blogcategory
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {"Are You Sure to Delete" +
                tableMeta.rowData[1] +
                " " +
                tableMeta.rowData[2]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.deleteBlogcategory(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}
