import React, { Component } from "react";

import PageTitle from "../../../components/PageTitle/PageTitle";

// FOR APIS
import {
  getPermissionByIdAPI,
  addPermissionAPI,
  editPermissionAPI
} from "./../../../APIs/permissionsApis";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  CircularProgress,
} from "@material-ui/core";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

class EditPermissions extends Component {
  constructor() {
    super();
    this.state = {
      fullname: "",
      displayname: "",
      description: "",
      permissions: [],
      error: false,
      loader: true,
      erroLoader: true,
    };
  }

  componentDidMount() {
    // const {state} = this.props.location;
    // if(state != undefined){
    let permissionID = this.props.match.params.permissionId;

    this.PermissionsDetails(permissionID);
    // }
  }

  PermissionsDetails = async (id) => {
    let details = await getPermissionByIdAPI(id);
    if (details.status == "success") {
      this.setState({
        permissions: details.response.permission,
        fullname: details.response.permission.name,
        displayname: details.response.permission.display_name,
        description: details.response.permission.description,
        loader: false,
      });
    } else {
      this.setState({ erroLoader: false, loader: false });
    }
    console.log("Role details in roles file:" + JSON.stringify(details));
  };
  setfullName = (e) => {
    this.setState({ fullname: e.target.value });
    console.log("the full name is :" + e.target.value);
  };

  setdisplayName = (e) => {
    this.setState({ displayname: e.target.value });
    console.log("the display name is :" + e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    console.log("the description is :" + e.target.value);
  };

  handleSubmit = async () => {
        if(this.state.fullname.length > 0
             && this.state.displayname.length > 0
              && this.state.description.length > 0){
        let response = await editPermissionAPI(this.props.match.params.permissionId, this.state.fullname,this.state.displayname,this.state.description)
        if(response.status == 200){
            this.setState({
                fullname:'',
                displayname:'',
                description:''
            })
            this.props.history.push("/app/permissions");

        }
        else{
           this.setState({error:true})
        }
        console.log("Response of add permission is :"+JSON.stringify(response));
    }
  };

  render() {
    return (
      <div>
        {/* <p>Hi, This is roles page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Edit Permission"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/");
                  }}
                >
                  <HomeIcon /> Home
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.loader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.erroLoader ? (
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Full Name"
                            id="name"
                            name="name"
                            // placeholder="enter your name"
                            value={this.state.fullname}
                            // placeholder=""
                            onChange={this.setfullName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Display Name"
                            id="display name"
                            name="display name"
                            placeholder="display name"
                            value={this.state.displayname}
                            placeholder=""
                            onChange={this.setdisplayName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextareaAutosize
                            label="Description"
                            placeholder="Description"
                            id="description"
                            name="description"
                            rowsMin={3}
                            value={this.state.description}
                            onChange={this.setDescrition}
                            style={{ fontSize: "20px" }}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{
                        justifyContent: "flex-start",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                      <Typography
                        color="secondary"
                        variant="h5"
                        size="sm"
                        style={{
                          borderBottom: "2px solid #FF7F50",
                          marginTop: 30,
                        }}
                      >
                        {this.state.error ? (
                          <p>Error In editing permission </p>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <div>
                  <p>Something went wrong,please try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default EditPermissions;
