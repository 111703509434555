
import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Trans } from "react-i18next";

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import {
    isErrorExist,
    getAge,
    checkTextField,
    checkDateField,
    checkNumberField,
    checkRadioField,
    checkSelectField,
    ValidateFields,
    getImageAPI,
  } from "../APIs/ApiUtils";
  


  export default class BaseComponentClass extends Component {
    constructor() {
      super();    
    }
  
    setStateVariables = (
      param,
      validation = { checkEmpty: false },
      type = "TextField",
      value = "",
      error = false,
    ) => {
      let paramLabel = param + "Label";
      let paramID = param + "ID";
      let paramPlaceHolder = param + "PlaceHolder";
      let paramValue = param + "Value";
      let paramErrorStatus = param + "ErrorStatus";
      let paramErrorMsg = param + "ErrorMsg";
      let paramFieldType = param + "FieldType";
      let paramFieldValidation = param + "FieldValidation";
  
      let transLabel = "CommonField." + param + "Label";
      let transPlaceHolder = "CommonField." + param + "PlaceHolder";
      let transErrorMsg = "CommonField." + param + "ErrorMsg]";
  
      this.setState({
        [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
        [paramID]: param,
        [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
        [paramValue]: value,
        [paramErrorStatus]: error,
        [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
        [paramFieldType]: type,
        [paramFieldValidation]: validation,
      });
    };
  
  
    handleParam = (e, paramKey, directValue = false) => {
      let value = e;
      if (!directValue)
        if (e.target != null && e.target.value != undefined)
          value = e.target.value;
  
      let paramKeyError = paramKey + "ErrorStatus";
  
      this.setState((prevState, props) => ({
        ...prevState,
        [paramKey + "Value"]: value,
        [paramKeyError]: false,
      }));
  
      let isExist = isErrorExist(this.state);
    };
  
    handleDob = (e, paramKey, directValue = false) => {
      let value = e;
      if (!directValue)
        if (e.target != null && e.target.value != undefined)
          value = e.target.value;
  
      value = moment(value).format("YYYY-MM-DD");
  
      console.log("Moment Date Value ::", value);
      let paramKeyError = paramKey + "ErrorStatus";
  
      this.setState((prevState, props) => ({
        ...prevState,
        [paramKey + "Value"]: value,
        [paramKeyError]: false,
      }));
  
      let isExist = isErrorExist(this.state);
      if (this.state.isError && !isExist) this.setState({ isError: false });
    };
  
    handleincorporationdate = (e, paramKey, directValue = false) => {
      let value = e;
      if (!directValue)
        if (e.target != null && e.target.value != undefined)
          value = e.target.value;
  
      value = moment(value).format("YYYY-MM-DD");
  
      console.log("Moment Date Value ::", value);
      let paramKeyError = paramKey + "ErrorStatus";
  
      this.setState((prevState, props) => ({
        ...prevState,
        [paramKey + "Value"]: value,
        [paramKeyError]: false,
      }));
  
      let isExist = isErrorExist(this.state);
      if (this.state.isError && !isExist) this.setState({ isError: false });
    };
  

    handleFileClose = (param) => {
      this.setState({
        ...this.state,
        [param + "MediaOpen"]: false,
      });
    };
  
    handleFileSave = (param, files) => {
      console.log("Current File List :: ", param, files[0]);
      let paramKeyError = param + "ErrorStatus";
  
      this.setState({
        ...this.state,
        [param + "Value"]: files[0],
        [paramKeyError]: false,
        [param + "MediaOpen"]: false,
      });
      let isExist = isErrorExist(this.state);
      // if (this.state.isError && !isExist) this.setState({ isError: false });
    };
  
    handleFileOpen = (param) => {
      this.setState({
        ...this.state,
        [param + "MediaOpen"]: true,
      });
    };
  
    handleSubmit = () => {
      let param = {};
  
      // GET THE VALUES ONLY
      Object.entries(this.state).map(([key, value]) => {
        if (key.includes("Value")) param[key.replace("Value", "")] = value;
      });
  
      console.log("<INFO> Param Value ", param);
      let errors = ValidateFields(this);
      console.log("Error exist of Not ", errors);
  
      if (errors) {
        return false;
      }
      this.setState({ isSubmitLoading: true });
  
      this.handleSubmitButton(param);
    };


    handleHTMLEditor = (iData) => {      
      if(iData?.length!=0){
        const contentBlock = htmlToDraft(iData);
        var editorStateStr = "";
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          editorStateStr = EditorState.createWithContent(contentState);
        }
        return editorStateStr;
      }
    }

    onHtmlEditorStateChange = (iEditorState) => {
      this.setState({
        editorState:iEditorState,
        descriptionValue : draftToHtml(convertToRaw(iEditorState.getCurrentContent()))
      });
    };
  
  };
 