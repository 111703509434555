import API from "./../API";

export { 
    getTeamsAPI, 
    setParentUser,
  };

async function getTeamsAPI() {
  let id = 1;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("team/tree/" + id);
    return response.data;
  } catch (error) {
    console.log("Role API Request Failed With Status :: ", error);
    return [];
  }
}

async function setParentUser(iParam) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post("team/setparent/",
                                  iParam,
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  });
    return response.data;
  } catch (error) {
    console.log("Parent Update Failed With Status :: ", error);
    return [];
  }
}


