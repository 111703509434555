import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
  getMembersPaginationAPI,
  createMemberAPI,
  createMemberDocumentAPI,
  createMemberCoFounderAPI,
  deleteCoFounderAPI,
  deleteMemberAPI,
  deleteMemberDocumentAPI,  
  editMemberAPI,
  editMemberDocumentAPI,
  editMemberCoFounderAPI,
  getCoFounderAPI,
  getCoFoundersAPI,
  getMemberAPI,
  getMemberDocumentAPI,
  getMemberDocumentByIDAPI,
  getMembersAPI,
  getMemberServicesAPI,
  setMemberServicesAPI, 
  getProfilesAPI
};


async function getProfilesAPI() {
  let profiles = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("profile");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);    
      response.data.response.Profiles.map((profile) => {
        let tmp = profile;        
        tmp['isChecked'] = false;   
        // tmp['label'] = profile["name"];   
        profiles.push(tmp);
      });      
      return profiles;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return profiles;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}



async function getMemberServicesAPI(iParams) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("member/services/" + iParams.member_id);
    if (response.status == 200 && response.data.status!="failed" ) {
      console.log("Response from  API :: ", response.data);
      response.data.response.services.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);

    return false;
  }
}

async function setMemberServicesAPI(iParams) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.post("member/services/",iParams);
    if (response.status == 200 && response.data.status!="failed" ) {
        console.log("Response from API :: ", response.data);
        toast.success("Member Services Added Successfully");
        return true;
    } else {
        displayResponseError(response);
        return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);

    return false;
  }
}


async function getMembersPaginationAPI(limit, page) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("member/pagination/" + limit + "/" + page);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      // response.data.response.members.map((member) => {
      //   let tmp = {};
      //   Object.entries(member).map(([key, value]) => {
      //     tmp[key] = value;
      //   });
      //   members.push(tmp);
      // });
      return response.data.response.members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getMembersAPI() {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("member");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      let data = Array.from(response.data.response.members);
      data.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return response.data.response.members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getMemberAPI(member_id) {
  let member = undefined;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("member/" + member_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      member = response.data.response.member;
      return member;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return member;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createMemberAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("member", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.memberactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Member Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editMemberAPI(member_id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("member/" + member_id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.memberactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Member Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteMemberAPI(member_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("member/" + member_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getCoFoundersAPI(member_id) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("member/member/" + member_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.members.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getCoFounderAPI(member_id) {
  let member = undefined;

  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("cofounder/" + member_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      member = response.data.response.cofounder;
      return member;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return member;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createMemberCoFounderAPI(member_id, param) {
  let token = localStorage.getItem("id_token");

  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  formData.append("cofounderable_type", "App\\Models\\Persona\\member");
  formData.append("cofounderable_id", member_id);

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("cofounder", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.memberactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Co-Founder Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editMemberCoFounderAPI(member_id, param) {
  let token = localStorage.getItem("id_token");
  console.log("<MEMBER ID> :: ", member_id);
  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "cofounder/" + member_id + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.memberactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Co-Founder Updated Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteCoFounderAPI(member_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("cofounder/" + member_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getMemberDocumentAPI(member_id) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("member/document/" + member_id);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    response.data.response.documents.map((member) => {
      let tmp = {};
      Object.entries(member).map(([key, value]) => {
        tmp[key] = value;
      });
      members.push(tmp);
    });
    return members;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return members;
  }
}

async function getMemberDocumentByIDAPI(document_id) {
  let document = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("document/" + document_id);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    document = response.data.response.document;
    return document;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return document;
  }
}

async function createMemberDocumentAPI(member_id,param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  // Object.entries(prices).map(([key, value]) => [key, value * 2])
  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("documentable_type", "App\\Models\\Persona\\Member");
  formData.append("documentable_id", member_id);

  console.log(formData);

  try {
    API.defaults.timeout = 600000;
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("document", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.memberactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Member Document Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editMemberDocumentAPI(document_id , param) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("formData", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "document/" + document_id + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.memberactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Document edited successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteMemberDocumentAPI(document_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("document/" + document_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}
