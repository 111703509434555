import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
  getAdminsPaginationAPI,
  createAdminAPI,
  deleteAdminAPI,
  editAdminAPI,
  getAdminAPI,
  getAdminsAPI,
  getProfilesAPI
};


async function getProfilesAPI() {
  let profiles = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("profile");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);    
      response.data.response.Profiles.map((profile) => {
        let tmp = profile;        
        tmp['isChecked'] = false;   
        // tmp['label'] = profile["name"];   
        profiles.push(tmp);
      });      
      return profiles;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return profiles;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getAdminsPaginationAPI(limit, page) {
  let admins = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("admin/pagination/" + limit + "/" + page);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      return response.data.response.admins;      
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return admins;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getAdminsAPI() {
  let admins = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("admin");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.admins.map((admin) => {
        let tmp = {};
        Object.entries(admin).map(([key, value]) => {
          tmp[key] = value;
        });
        admins.push(tmp);
      });
      return admins;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return admins;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getAdminAPI(admin_id) {
  let admin = undefined;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("admin/" + admin_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      admin = response.data.response.admin;
      return admin;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return admin;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createAdminAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("admin", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.adminactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Admin Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editAdminAPI(admin_id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("admin/" + admin_id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.adminactivated == "success") {
        console.log("Response from API :: ", response.data);
        toast.success("Admin Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteAdminAPI(admin_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("admin/" + admin_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

