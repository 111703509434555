// import React , {Component} from 'react';

// class notification extends Component {

//     render(){
//         return(
//             <div>
//                 <p>Hi,This is Notification page.!</p>
//             </div>
//         );
//     }
// }

// export default notification;


import React from "react";
import { Component } from "react";

import themeAPP from "./../../themes";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { Button, Grid } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from '@material-ui/core/Avatar';

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import {
 getNotificationsAPI
} from "./../../APIs/notificationsApis";

// COMPONENTS

import PageTitle from "../../components/PageTitle/PageTitle";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Trans } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default class Notifications extends Component {
  constructor() {
    super();
    this.state = {
        notificationsTable: [],
        loader: false,
        deleteDialog: false,
    };
  }

  render() {
    const components = {
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid xs={12} container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>Notifications</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/createnotification");
                      }}
                    >
                      <Trans>Create Notification</Trans>
                    </Button>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={{ justifyContent: "space-between" }}
              >
                <MuiThemeProvider theme={themeAPP.default}>
                  <MUIDataTable
                    title="Notifications List"
                    data={this.state.notificationsTable}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "title",
                        label: "Title",
                      },
                      {
                        name: "description",
                        label: "Description",
                      },
                      {
                        name: "redirect_link",
                        label: "Redirect_link",
                      },
                      {
                        name: "Actions",
                        options: {
                          align:"right",
                          filter: true,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRender(
                              value,
                              tableMeta,
                              updateValue,
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      selectableRows: false,
                      fixedHeader: true,
                      filter: true,
                      filterType: "dropdown",
                      responsive: "standard",
                      tableBodyHeight: "400px",
                      tableBodyMaxHeight: "400px",
                    }}
                    // components={components}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.handleGetNotifications();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetNotifications = async () => {
    let RC = false;
    this.setState({ loader: true, notificationsTable: [] });
    let notifcations = await getNotificationsAPI();
    if (notifcations != undefined && notifcations.length > 0) {
      console.log("Notifications Found Successfully");
      this.setState({
        notificationsTable: notifcations,
        loader: false,
      });
    } else {
      console.log("Notifications Not found");
      this.setState({ loader: false });
    }
  };

  editNotifications = (value, tableMeta, updateValue) => {
    this.props.history.push({
        pathname: '/app/editnotification',
        search: '?',
        state: {notification_id: tableMeta.rowData[0]}
      });
  };


  handleWorkerExpend = (rowData, rowMeta) => {
    const colSpan = rowData.length;
    let rowDataStr = JSON.stringify(rowData);
    return (
      <div>
        <TableRow>
          <TableCell colSpan={colSpan}>Name : {rowData[0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colSpan}>Name : {rowData[1]}</TableCell>
        </TableRow>
      </div>
    );
  };

  handleClickOpen = () => {
    this.setState({ ...this.state, deleteDialog: true });
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <div style={{marginLeft:10}}>
        {/* <IconButton
          color="primary"
          aria-label="upload picture"
        //   onClick={() => this.viewWorker(value, tableMeta, updateValue)}
          style={themeAPP.default.button}
        >
          <Tooltip title="View Beneficiary" aria-label="add">
            <Visibility />
          </Tooltip>
        </IconButton> */}

        <IconButton
          style={themeAPP.default.button}
          color="primary"
          aria-label="upload picture"
           onClick={() => this.editNotifications(value, tableMeta, updateValue)}
        >
          <Tooltip title="Edit Notification" aria-label="add">
            <EditIcon />
          </Tooltip>
        </IconButton>

        <IconButton
          style={themeAPP.default.button}
          color="primary"
          aria-label="Delete Button"

        >
          <Tooltip title="Delete Current Beneficiary" aria-label="add">
            <DeleteIcon />
          </Tooltip>
        </IconButton>

        <Dialog
          open={this.state.deleteDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
              Deleting notification
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {"Are You Sure to Delete" +
                tableMeta.rowData[1] +
                " " +
                tableMeta.rowData[2]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}
