const de_source = {
    main_menu: {
      sports: "Sport",
      my_store: "Mein Geschäft",
      my_orders: "Meine Bestellungen",
      sign_in: "Anmelden",
      sign_up: "Anmelden",
      offices: "Büros"
    },
    side_menu: {
      t_shirt: "Schuhe",
      shoes: "Schuhe",
      fleece: "Fleece",
      trousers: "Hose",
      shorts: "kurze Hose"
    }
  };
  
  export {de_source};

