import React from "react";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";

export default function CustomTextField(props) {
  return (
    <FormControl fullWidth>
      <TextField
        fullWidth     
        required={props.required}
        label={props.state[props.paramValue + "Label"]}
        id={props.state[props.paramValue + "ID"]}
        name={props.state[props.paramValue + "ID"]}
        value={props.state[props.paramValue + "Value"]}
        placeholder={props.state[props.paramValue + "PlaceHolder"]}
        onChange={(e) => {
          props.onChange(e, props.paramValue);
        }}
        error={props.state[props.paramValue + "ErrorStatus"]}
        helperText={
          props.state[props.paramValue + "ErrorStatus"]
            ? props.state[props.paramValue + "ErrorMsg"]
            : ""
        }
      />
    </FormControl>
  );
}
