import React, { Component } from "react";

import PageTitle from "../../components/PageTitle/PageTitle";

// FOR APIS
import {
  createNotificationAPI,
  getUsersAPI,
} from "./../../APIs/notificationsApis";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
class CreateNotification extends Component {
  constructor() {
    super();
    this.state = {
      type: "INFO",
      title: "",
      redirectlink: "",
      description: "",
      type: "",
      groupname: "",
      users: "",
      username: [],
      usersidlist: [],
      userlist: [],
      loader: true,
      error: false,
      responseText: "",
    };
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList = async () => {
    let userdetails = await getUsersAPI();

    for (let i = 0; i < userdetails.response.users.length; i++) {
      let temp = {};
      temp.id = userdetails.response.users[i].id;
      temp.name = userdetails.response.users[i].name;
      this.state.userlist.push(temp);
    }
    console.log(
      "The updated userlist response in Createnotification is :" +
        JSON.stringify(this.state.userlist),
    );
    if (userdetails.status === "success") {
      this.setState({ loader: false });
    }
  };

  setTitle = (e) => {
    this.setState({ title: e.target.value });
    console.log("the title is :" + e.target.value);
  };

  setType = (e) => {
    this.setState({ type: e.target.value });
    console.log("the type is :" + e.target.value);
  };

  setRedirectLink = (e) => {
    this.setState({ redirectlink: e.target.value });
    console.log("the redirect link is :" + e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    console.log("the description is :" + e.target.value);
  };

  setType = (e) => {
    this.setState({ type: e.target.value });
    console.log("the type is :" + e.target.value);
  };

  setGroupName = (e) => {
    this.setState({ groupname: e.target.value });
    console.log("the group is :" + e.target.value);
  };

  setUsers = (e) => {
    this.setState({ users: e.target.value });
    console.log("the users is :" + e.target.value);
  };

  handleUsersChange = (event) => {
    console.log("event in handlemultiple is:" + JSON.stringify(event.target));
    this.setState({ username: event.target.value });
  };

  handleSubmit = async () => {
    let id = localStorage.getItem("user_id");

    let param = {
      user_id: id,
      type: this.state.type,
      title: this.state.title,
      description: this.state.description,
      redirect_link: this.state.redirectlink,
      type: this.state.type,
      groupname: this.state.groupname,
      users: this.state.username.join(),
    };
    let response = await createNotificationAPI(param);
    if (response.status === "success") {
      this.setState({
        title: "",
        description: "",
        redirect_link: "",
        type: "",
        groupname: "",
        users: "",
        error: true,
        responseText: "Notification created successfully",
      });
    } else {
      this.setState({
        error: true,
        responseText: "Error in Notification creation",
      });
    }
    console.log(
      "Response of crate notification in add notification page is :" +
        JSON.stringify(response),
    );
  };

  render() {
    return (
      <div>
        {/* <p>Hi, This is roles page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Create Notification"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/notification");
                  }}
                >
                  Back
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.loader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <Paper style={{ padding: 16 }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  <FormControl fullWidth required="required">
                    <InputLabel id="typeLabel">Type</InputLabel>
                    <Select
                      labelId={"typeLabel"}
                      id={"type"}
                      value={this.state.type}
                      onChange={this.setType}
                    >
                      <MenuItem value="INFO">Info</MenuItem>
                      <MenuItem value="WARNING">Warning</MenuItem>
                      <MenuItem value="URGENT">Urgent</MenuItem>
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  {
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Title"
                        id="title"
                        name="title"
                        // placeholder="enter your name"
                        value={this.state.title}
                        // placeholder=""
                        onChange={this.setTitle}
                      />
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  <FormControl
                    variant="outlined"
                    className={useStyles.formControl}
                  >
                    <InputLabel htmlFor="outlined-groupname-native-simple">
                      Group Name
                    </InputLabel>
                    <Select
                      native
                      style={{ width: 800 }}
                      value={this.state.groupname}
                      onChange={this.setGroupName}
                      label="Group Name"
                      inputProps={{
                        name: "groupname",
                        id: "outlined-type-native-simple",
                      }}
                    >
                      <option value=""></option>
                      <option value="ADMIN">Admin</option>
                      <option value="MEMBER">Member</option>
                      <option value="STARTUP">Startup</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  {
                    <FormControl fullWidth>
                      <TextareaAutosize
                        label="Description"
                        placeholder="Description"
                        id="description"
                        name="description"
                        rowsMin={2}
                        value={this.state.description}
                        onChange={this.setDescrition}
                        style={{ fontSize: "20px" }}
                      />
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  {/* { <FormControl fullWidth>
                        <TextField
                            fullWidth
                            variant="outlined" 
                            label="Type"
                            id="type"
                            name="type"
                            value={this.state.type}
                            placeholder=""      
                            onChange={this.setType}
                        />
                        </FormControl>
                      } */}
                  <FormControl
                    variant="outlined"
                    className={useStyles.formControl}
                  >
                    <InputLabel htmlFor="outlined-type-native-simple">
                      Type
                    </InputLabel>
                    <Select
                      native
                      style={{ width: 800 }}
                      value={this.state.type}
                      onChange={this.setType}
                      label="Type"
                      inputProps={{
                        name: "type",
                        id: "outlined-type-native-simple",
                      }}
                    >
                      <option value=""></option>
                      <option value="USER">User</option>
                      <option value="ROLE">Role</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  {/* { <FormControl fullWidth>
                        <TextField
                            fullWidth
                            variant="outlined" 
                            label="Group Name"
                            id="group name"
                            name="group name"
                            value={this.state.groupname}
                            placeholder=""      
                            onChange={this.setGroupName}
                        />
                        </FormControl>
                      } */}
                  {
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Redirect Link"
                        id="redirect link"
                        name="redirect link"
                        value={this.state.redirectlink}
                        placeholder=""
                        onChange={this.setRedirectLink}
                      />
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={6} className={{ justifyContent: "flex-start" }}>
                  {/* { <FormControl fullWidth>
                        <TextField
                            fullWidth
                            variant="outlined" 
                            label="Users"
                            id="users"
                            name="users"
                            value={this.state.users}
                            placeholder=""      
                            onChange={this.setUsers}
                        />
                        </FormControl>
                      } */}

                  <FormControl
                    variant="outlined"
                    className={useStyles.formControl}
                  >
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      id="demo-mutiple-chip-label"
                    >
                      Users
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      label="Users"
                      style={{ width: 800, border: "1px" }}
                      value={this.state.username}
                      onChange={this.handleUsersChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={useStyles.chips}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                    >
                      {this.state.userlist.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={{
                    justifyContent: "flex-start",
                    borderBottom: "1px solid #000",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>
                  <Typography
                    color="secondary"
                    variant="h5"
                    size="sm"
                    style={{ borderBottom: "2px solid #FF7F50", marginTop: 30 }}
                  >
                    {this.state.error ? (
                      <p>{this.state.responseText}</p>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
      </div>
    );
  }
}

export default CreateNotification;
