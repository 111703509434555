import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
    getDashboardDataAPI,
};


async function getDashboardDataAPI() {
  let blogs = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("dashboard");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      return response.data;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return blogs;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


