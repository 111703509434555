import { Component } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import { getTeamsAPI } from "../../APIs/teamsApis";
import PageTitle from "../../components/PageTitle/PageTitle";

import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Home as HomeIcon, Person as PersonIcon } from "@material-ui/icons";

const Icons = {
  MEMBER: <PersonIcon />,
  STARTUP: <HomeIcon />,
};

class TeamView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [
                { title: 'src/', children: [ { title: 'index.js' } ] }, 
                { title: 'src2/', children: [ { title: 'index2.js' } ] }
              ],
      teamsDetails: [],
      loader: true,
      errorFlag: true,
      open: false,
      id: "",
      name: "",
      email: "",
      phone: "",
      user_type: "",
    };

    this.updateTreeData = this.updateTreeData.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
  }

  componentDidMount() {
    this.getTeamsData();
  }

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  expand(expanded) {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded,
      }),
    });
  }

  expandAll() {
    this.expand(true);
  }

  collapseAll() {
    this.expand(false);
  }

  getTeamsData = async () => {
    let details = await getTeamsAPI();
    console.log("the details of teams is :" + JSON.stringify(details));
    this.setState({ teamsDetails: details.tree, loader: false });
  };

  handleClickOpen = (nodeId, name, email, phone, user_type) => {
    console.log("node id is" + nodeId);
    this.setState({
      id: nodeId,
      name: name,
      email: email,
      phone: phone,
      user_type: user_type,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleEdit = () => {
    if (this.state.user_type == "STARTUP") {
      this.props.history.push({
        pathname: "/app/editstartup",
        state: [{ id: this.state.id }], // your data array of objects
      });
    } else if (this.state.user_type == "MEMBER") {
      this.props.history.push({
        pathname: "/app/editworker",
        state: [{ id: this.state.id }], // your data array of objects
      });
    }
  };

  renderTree = (nodes) => (
    <div>
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <div
            onClick={() =>
              this.handleClickOpen(
                nodes.id,
                nodes.name,
                nodes.email,
                nodes.phone,
                nodes.user_type,
              )
            }
          >
            <Typography variant="body2">
              <PersonIcon />
              &nbsp;&nbsp;
              {nodes.name}
            </Typography>
          </div>
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => this.renderTree(node))
          : null}
      </TreeItem>
    </div>
  );

  render() {
    return (
      <div>
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Teams"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/");
                  }}
                >
                  <HomeIcon /> Home
                </Button>
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={6} className={{ justifyContent: "center" }}>
          {this.state.loader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.errorFlag ? (
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={6}
                      mt={3}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <TreeView
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpanded={[1]}
                          defaultExpandIcon={<ChevronRightIcon />}
                        >
                          {this.renderTree(this.state.teamsDetails)}
                        </TreeView>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      mt={3}
                      className={{ justifyContent: "center" }}
                    >
                      {this.state.open ? (
                        <div>
                          <Card>
                            <CardHeader title="User Information" />
                            <CardContent>
                              {/* <Typography gutterBottom>
                                Id : {this.state.id}
                              </Typography> */}
                              <Typography gutterBottom>
                                Name : {this.state.name}
                              </Typography>
                              <Typography gutterBottom>
                                Email : {this.state.email}
                              </Typography>
                              <Typography>
                                Phone : {this.state.phone}
                              </Typography>
                              <Typography>
                                User Type : {this.state.user_type}
                              </Typography>
                            </CardContent>
                            {/* <CardActions>
                              <Button size="small" onClick={this.handleClose}>
                                cancel
                              </Button>
                              <Button size="small" onClick={this.handleEdit}>
                                Edit
                              </Button>
                            </CardActions> */}
                          </Card>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <div>
                  <p>Something went wrong, plese try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default TeamView;
