import React, { Component, Fragment } from "react";

import PageTitle from "../../../components/PageTitle/PageTitle";

// FOR APIS
import { getRolesAPI, addRolesAPI } from "./../../../APIs/rolesApis";

//MATERIAL CORE

import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  TextareaAutosize,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

// ICONS
import HomeIcon from "@material-ui/icons/Home";

class Roles extends Component {
  constructor() {
    super();
    this.state = {
      fullname: "",
      displayname: "",
      description: "",
      roles: [],
      loader: true,
      errorLoader: true,
      responseFlag: false,
      responseText: "",
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.getRoleDetails();
  }

  getRoleDetails = async () => {
    let details = await getRolesAPI();
    if (details.status == "success") {
      this.setState({ roles: details.response.roles });
      this.setState({ loader: false });
    } else {
      this.setState({ errorLoader: false });
      this.setState({ loader: false });
    }
    console.log(
      "all Roles with its permissions details in roles file:" +
        JSON.stringify(details),
    );
  };
  setfullName = (e) => {
    this.setState({ fullname: e.target.value });
    console.log("the full name is :" + e.target.value);
  };

  setdisplayName = (e) => {
    this.setState({ displayname: e.target.value });
    console.log("the display name is :" + e.target.value);
  };

  setDescrition = (e) => {
    this.setState({ description: e.target.value });
    console.log("the description is :" + e.target.value);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleSubmit = async () => {
    if (
      this.state.fullname.length > 0 &&
      this.state.displayname.length > 0 &&
      this.state.description.length > 0
    ) {
      let response = await addRolesAPI(
        this.state.fullname,
        this.state.displayname,
        this.state.description,
      );
      console.log("Response of add roles is :" + JSON.stringify(response));
      if (response.status === "success") {
        this.setState({
          fullname: "",
          displayname: "",
          description: "",
          responseFlag: true,
          responseText: "Role created successfully !",
        });
        this.getRoleDetails();
      } else {
        this.setState({
          responseFlag: true,
          responseText: "Something went wrong , please try again !",
        });
      }
    }
  };

  render() {
    return (
      <div>
        {/* <p>Hi, This is roles page</p> */}
        <Grid container spacing={16}>
          {/* ========= TITLE BAR ==========  */}
          <Grid item xs={12} padding={0}>
            <PageTitle
              title="Roles"
              button={
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push("/app/");
                  }}
                >
                  <HomeIcon /> Home
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} className={{ justifyContent: "center" }}>
          {this.state.loader ? (
            <div className={{ alignContents: "center" }}>
              <CircularProgress variant="indeterminate" value={75} />
            </div>
          ) : (
            <div>
              {this.state.errorLoader ? (
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            label="Role Name"
                            id="name"
                            name="name"
                            // placeholder="enter your name"
                            value={this.state.fullname}
                            // placeholder=""
                            onChange={this.setfullName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            label="Display Name"
                            id="display name"
                            name="display name"
                            placeholder="display name"
                            value={this.state.displayname}
                            onChange={this.setdisplayName}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <FormControl fullWidth>
                          <TextareaAutosize
                            //aria-label="minimum height"
                            //  className="border10 "
                            label="Role Description"
                            placeholder="Description"
                            id="description"
                            name="description"
                            rowsMin={3}
                            value={this.state.description}
                            onChange={this.setDescrition}
                            style={{ fontSize: "20px" }}
                          />
                        </FormControl>
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{
                        justifyContent: "flex-start",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                      {this.state.responseFlag ? (
                        <Typography
                          color="primary"
                          variant="h5"
                          size="sm"
                          style={{
                            borderBottom: "2px solid #FF7F50",
                            marginTop: 30,
                          }}
                        >
                          {this.state.responseText}
                        </Typography>
                      ) : (
                        <Typography
                          color="primary"
                          variant="h5"
                          size="sm"
                          style={{
                            borderBottom: "2px solid #FF7F50",
                            marginTop: 30,
                          }}
                        ></Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Role Name</TableCell>
                              <TableCell align="left">Permissions</TableCell>
                              <TableCell align="left">Edit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.roles
                              .slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage,
                              )
                              .map((row) => (
                                <Fragment>
                                  {/* <TableRow>
                            
                                <TableCell rowSpan = {row.permissions.length + 1}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                      this.props.history.push({
                                          pathname: '/app/editrole',
                                          state: [{id:row.id}] // your data array of objects
                                      });
                                    }}
                                >
                                    Edit
                                </Button>
                                </TableCell>
                                
                                <TableCell rowSpan = {row.permissions.length + 1}>
                                  {row.name}
                                </TableCell>
                            </TableRow> */}
                                  {/* {row.permissions.map(detail => (
                                <TableRow>
                                  <TableCell>{detail.name}</TableCell>
                                </TableRow>  
                                ))} */}

                                  <TableRow>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                      {row.permissions.map((detail) => (
                                        <FormControl>
                                          <FormLabel>
                                            {" "}
                                            {detail.name} &nbsp;
                                          </FormLabel>
                                        </FormControl>
                                      ))}
                                    </TableCell>

                                    <TableCell>
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: "/app/editrole/" + row.id,
                                            state: [{ id: row.id }], // your data array of objects
                                          });
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                </Fragment>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={this.state.roles.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        className="pagination"
                        style={{ float: "right" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ) : (
                <div>
                  <p>Something went wrong,please try again</p>
                </div>
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default Roles;
