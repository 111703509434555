import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import {getRadioMacros, getCountryList,  getStatusMacros,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";


// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Slide,
  CircularProgress,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Select
} from "@material-ui/core";
// Picker
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MUIDataTable from "mui-datatables";


// FOR APIS  ====================================================================
import {
  getAdminsAPI,
  getAdminAPI,
  createAdminAPI,
  editAdminAPI,  
} from "../../APIs/adminApis";

import {
  setParentUser,
} from "../../APIs/teamsApis";


import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
  getCategoriesAPI
} from "../../APIs/ApiUtils";


// IMAGES  ====================================================================
import userimage from "./../../images/userimage.png";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

var requiredParam = [
  "status",
  "name",
  "designation",
  "profile",
  "email",
  "phone",
  "summary",  
  "address",
  "file_photo",
  "parent_id",
  "user_id"
];

export default class EditAdmin extends BaseComponentClass {
  constructor() {
    super();

    this.state = {
      profilesList:[],
      adminsTable:[],
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      profilePicture: "",
      adminprofileValue: undefined
    };
  }

  componentDidMount() {

    
    this.setStateVariables("name", {checkEmpty: true,minLength: 0,maxLength: 120,});    
    this.setStateVariables("email", { checkEmpty: false,minLength: 0,maxLength: 120,});
    this.setStateVariables("phone", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("summary", {checkEmpty: false, minLength: 0, maxLength: 2000,});
    this.setStateVariables("designation", {checkEmpty: false, minLength: 0, maxLength: 120,});    
    this.setStateVariables("status",{ checkEmpty: false },"SelectField","ACTIVE",);
    this.setStateVariables("address", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("parent_id",{ checkEmpty: false },"SelectField","",);
    this.setStateVariables("file_photo", { checkEmpty: false }, "FileField");    
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetAdmin();
    await this.handleGetAdmins();
  };

  render() {

    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditAdmin.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/admins");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Personal Info
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="name"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="email"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="designation"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="phone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      <CustomSelectField
                        paramValue="status"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                            selected: "selected",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="address"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} className={{ justifyContent: "flex-start" }} >
                      {
                        <FormControl
                          fullWidth
                          required={true}
                          error={false}
                        >
                          <InputLabel id="ProfilesValueID">
                            Select Report Manager {this?.state?.parent_id}
                          </InputLabel>
                          <Select
                            value={this?.state?.parent_idValue }
                            onChange={this.handleReportToChange}
                            input={<Input id="select-multiple" />}
                          >
                             {this.state?.adminsTable.map(admin => (
                              (admin?.id != this.state?.idValue)?
                              <MenuItem
                                key={admin?.name}
                                value={admin?.id}                          
                              >
                                {admin.name}
                              </MenuItem>:""
                            ))}
                          </Select>                    
                        </FormControl>
                      }
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="summary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid
                      item
                      mt={4}
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography color="primary" variant="h5" size="sm" mt={4}>
                        Documents Section
                      </Typography>
                      <Divider style={{ margin: "5px 0 15px 0" }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        User Profile Image
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      {
                        <CustomUploadButton
                          paramValue="file_photo"
                          state={this.state}
                          acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>



                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          // disabled={
                          //   loginValue.length === 0 ||
                          // }
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Update Admin"}
                            </div>
                          ) : (
                            "Update Admin"
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleProfileChanges = (e) => {      
    this.setState({
      adminprofileValue: e.target.value
    });
  }
  
 
  handleReportToChange = event => {
    this.setState({ parent_idValue: event.target.value });
   
  };

  handleGetAdmin = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, adminInfo: {} });
    let adminID = this.props.match.params.adminId;

    let adminInfo = await getAdminAPI(adminID);
    if (adminInfo != undefined) {
      let tmp = {};
      Object.entries(adminInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Admin Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["adminprofileimageValue"],
        fileName: "adminprofileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Admin Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleGetAdmins = async () => {
    let RC = false;
    this.setState({ loader: true, adminsTable: [] });

    let admins = await getAdminsAPI();
    if (admins != undefined && admins.length > 0) {
      console.log("Admins Found Successfully");
      this.setState({
        adminsTable: admins,
        loader: false,
        isRecent: false,
      });
    } else {
      console.log("Admin Not found");
      this.setState({ loader: false });
    }
  };

  handleSubmitButton = async (param) => {
    console.log("createAdmin API Started with following param:: ", param);
    let adminID = this.props.match.params.adminId;    
    let rc = await editAdminAPI(adminID, param);
    if (rc == true) {

      var teamparam = {
                        child_id: this.state.user_idValue, 
                        param_id: param.parent_id
                      };      
      let rc = await setParentUser(teamparam);

      console.log("Admin Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/admins");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Admin Not Updated");
    }
    console.log("createAdmin API ENDED");
  };

  handleDeleteDialogAdmin = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };
}
