import API, { API_Param } from "../API";
import {handleAPIError, handleAPISuccess} from "../helpers";

export {
  deleteServiceAPI,
  getServicesAPI,
  createServiceAPI,
  editServiceAPI,
  getServiceAPI,
};

async function deleteServiceAPI(service_id) {
  let token = localStorage.getItem("id_token");
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("service/" + service_id);
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function createServiceAPI(param) {
  let token = localStorage.getItem("id_token");
  let formData = new FormData();
  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("service", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function getServicesAPI() {
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("service");
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function editServiceAPI(service_id, param) {
  let token = localStorage.getItem("id_token");
  let formData = new FormData();
  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "service/" + service_id + "?_method=PUT", formData, {headers: {"Content-Type": "multipart/form-data",},},
    );
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function getServiceAPI(service_id) {
  let service = undefined;
  let token = localStorage.getItem("id_token");
  console.log("Get Service For ID :: ", service_id);

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("service/" + service_id);
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}
