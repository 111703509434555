import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";

// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Button,
  CircularProgress,
  Slide,
  Grid,
  Divider,
  Paper
} from "@material-ui/core";
// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// FOR APIS  ====================================================================
import { createFAQAPI } from "../../APIs/faqsApis";
import {  getStatusMacros, getRadioMacros, getCountryList, getDocumentMIMETypes } from "../../helpers";
import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getCategoriesAPI
} from "../../APIs/ApiUtils";

// IMAGES  ====================================================================



class AddFAQs extends BaseComponentClass {
  constructor() {
    super();
    this.state = {
      uploadersTable: [],
      isSubmitLoading: false,
      isError: false,
    };
  }

  componentDidMount() {
    this.setStateVariables("question", {checkEmpty: true,minLength: 5});
    this.setStateVariables("answer", { checkEmpty: true,minLength: 5 });

  }
  

  render() {
 
    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  question={<Trans>AddFAQs.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/faqs");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Create FAQ 
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="question"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>



                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>

                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="answer"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    
                  </Grid>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        <Button
                          onClick={this.handleSubmit}
                          // disabled={
                          //   loginValue.length === 0 ||
                          // }
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{
                            justifySelf: "center",
                            alignSelf: "center",
                          }}
                        >
                          {this.state.isSubmitLoading ? (
                            <div>
                              <CircularProgress size={16} color="secondary" />
                              {"Create FAQs"}
                            </div>
                          ) : (
                            "Create FAQs"
                          )}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleSubmitButton = async (param) => {
    console.log("createFAQs API Started");
    let rc = await createFAQAPI(param);
    if (rc == true) {
      console.log("FAQs Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/faqs");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("FAQs Not Added");
    }
    console.log("createFAQs API ENDED");
  };

}

export default AddFAQs;
