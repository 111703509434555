import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';

// LOCALE  ====================================================================
import { Trans } from "react-i18next";

// CUSTOM COMPONENTS ====================================================================
import BaseComponentClass from "./../../Interfaces/BaseComponentClass"
import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getDesignationDataArray,  getImageMIMETypes, getMediaMIMETypes } from "../../helpers";


// MATERIAL UI COMPONENTS  ====================================================================
import {
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Slide,
  CircularProgress,
} from "@material-ui/core";


// IMAGES  ====================================================================

// FOR APIS  ====================================================================
import {
  getBlogsAPI,
  getBlogAPI,
  createBlogAPI,
  editBlogAPI,  
} from "../../APIs/blogApis";

import {
  getImageAPI,
} from "../../APIs/ApiUtils";

var requiredParam = [
  "blogname",
  "blogemail",
  "blogphone",
  "blogsummary",
  "blogactivated",
  "blogaddress_id",
  "blogcompany",
  "bloglinkedin",
  "blogfacebook",
  "blogprofilefile",
  "blogcontractfile",
  "blogndafile",
];

class Blog extends BaseComponentClass {
  constructor(props) {
    super(props);

    this.state = {
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogBlogDocument: false,
      cofounders: [],
      documents: [],
      profilePicture: "",
    };
  }

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>ViewBlog.pageTitle</Trans>}
                  button={
                    <div>
                    <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      this.props.history.push("/app/allocateservice");
                    }}
                  >
                    View All Services
                  </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/app/blogs");
                      }}
                    >
                      Back
                    </Button>
                    </div>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 8 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                  // style={{ borderBottom: "1px solid #000" }}
                  >
                    Personal Info
                  </Typography>
                  <Divider style={{ margin: "5px 0 15px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="blogname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogemail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="blogphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      <CustomSelectField
                        paramValue="blogactivated"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                            selected: "selected",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogaddress_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-start" spacing={2} mt={4}>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }}>
                        <CustomTextField paramValue="blogcompany" state={this.state} onChange={this.handleParam} />                      
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="bloglinkedin"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={{ justifyContent: "flex-start" }} >
                      {
                        <CustomTextField
                          paramValue="blogfacebook"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="blogsummary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    mt={4}
                  >
                    <Grid
                      item
                      mt={4}
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography color="primary" variant="h5" size="sm" mt={4}>
                        Documents Section
                      </Typography>
                      <Divider style={{ margin: "5px 0 15px 0" }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Header Image
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      {
                        <CustomUploadButton
                          paramValue="blogprofilefile"
                          state={this.state}
                          acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>

                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        NDA Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="blogndafile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Form
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} alignItems="center" className={{ justifyContent: "center" }} >
                      <CustomUploadButton
                        paramValue="blogcontractfile"
                        state={this.state}
                        acceptedFiles={["image/jpeg","image/png","image/bmp","application/pdf",]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    ></Grid>
                  </Grid>


                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>                        
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {

    this.setStateVariables("blogname", {checkEmpty: true,minLength: 0,maxLength: 120,});    
    this.setStateVariables("blogemail", { checkEmpty: false,minLength: 0,maxLength: 120,});
    this.setStateVariables("blogphone", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("blogsummary", {checkEmpty: false, minLength: 0, maxLength: 200,});
    this.setStateVariables("blogactivated",{ checkEmpty: false },"SelectField","ACTIVE",);
    this.setStateVariables("blogaddress_id", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("blogcompany",      { checkEmpty: false,minLength: 0, maxLength: 120 },    );
    this.setStateVariables("bloglinkedin", { checkEmpty: false,minLength: 0, maxLength: 200,});
    this.setStateVariables("blogfacebook", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("blogprofilefile", { checkEmpty: false }, "FileField");    
    this.setStateVariables("blogcontractfile",{ checkEmpty: false },"FileField",);
    this.setStateVariables("blogndafile", { checkEmpty: false }, "FileField");    
    this.initialize();
  }

  initialize = async () => {
    await this.handleGetBlog();
  };

  handleGetBlog = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, blogInfo: {} });
    alert(this.props.match.params.blogId);    
    let blog_id = this.props.match.params.blogId;

    let blogInfo = await getBlogAPI(blog_id);
    if (blogInfo != undefined) {
      let tmp = {};
      Object.entries(blogInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Blog Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["blogprofileimageValue"],
        fileName: "blogprofileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Blog Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleBlog = async (param) => {
    console.log("createBlog API Started");
    let id_blog = localStorage.getItem("CURRENT_MEMBER");
    let rc = await editBlogAPI(id_blog, param);
    if (rc == true) {
      console.log("Blog Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/blogs");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Blog Not Updated");
    }
    console.log("createBlog API ENDED");
  };

}


export default withRouter(Blog);
