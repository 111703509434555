import React, { Fragment, Component } from "react";
import themeAPP from "../../themes";

import {
  Link, } from "react-router-dom";

// import needed components, functions and styles
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { Button, Grid, CardActionArea, CardActions } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import {
  getStartupsAPI,   getStartupsPaginationAPI, } from "../../APIs/startupApis";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Trans } from "react-i18next";
// import beneficiaryImage from "../assets/images/beneficiary-1.jpg";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",   }, }));

export default class Investors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startupsTable: [],       loader: false,       deleteDialog: false,       isRecent: true,       permissions: undefined,       roles: undefined,     };
  }

  render() {
    const components = {
      icons: {
        SearchIcon,         PrintIcon,         DownloadIcon,         ViewColumnIcon,         FilterIcon,       },     };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-12">
                  <Fragment>

                    <Grid container spacing={2}>
                      <Grid item xs={12} padding={0}>
                        <PageTitle
                          title={<Trans>Startups.pageTitle</Trans>}
                          button={
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                this.props.history.push("/app/investors");
                              }}
                            >
                              Back
                            </Button>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ margin: "5px 0 15px 0" }} />
                    <Grid container alignItems="flex-start" spacing={2} mb={4}>


                          {this.state.startupsTable != undefined &&
                            this.state.startupsTable.map((row) => {
                              // if (
                              // row.profilethumbnail != undefined &&
                              // row.profilethumbnail != ""
                              // )
                              {
                                return (

                                  <Grid
                                    item xs={12} sm={6} md={4}
                                    className={{ justifyContent: "flex-start" }}
                                  >
                                    <Card sx={{ maxWidth: 200 }}>
                                      <CardActionArea>
                                        <CardMedia
                                          component="img"
                                          height="200"
                                          width="200"
                                          image={
                                            row.profilethumbnail != undefined
                                            ? row.profilethumbnail
                                            : undefined
                                          }
                                          alt="green iguana"
                                        />
                                        <CardContent>
                                          <Typography gutterBottom variant="h5" component="div">
                                          { row.companyname}
                                          </Typography>
                                          <Typography variant="body2" color="text.secondary">
                                          { row.aboutcompany }
                                          </Typography>
                                        </CardContent>
                                      </CardActionArea>
                                      <CardActions>
                                        <Button onClick={() => { this.viewStartup(row.id) }} size="small" color="primary">
                                          View
                                        </Button>
                                      </CardActions>
                                    </Card>
                                    
                                  </Grid>
                                );
                              }
                            })}
                    </Grid>

                  </Fragment>{" "}
                </div>
                {/* <div class="col-12 col-lg-3 pt-3">
            <PageSideBar />
              </div> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    let userRole = localStorage.getItem("user_role");
    var userRoleArr = userRole.split(",");
    let userPermission = localStorage.getItem("user_permission");
    var userPermissionArr = userPermission.split(",");
    this.setState({ permissions: userPermissionArr, roles: userRoleArr });

    this.handleGetStartupsPagination();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetStartupsPagination = async () => {
    let RC = false;
    this.setState({ loader: true, startupsTable: [] });
    let page = 1;
    let limit = 10;

    let investors = await getStartupsPaginationAPI(limit, page);
    if (investors != undefined && investors.length > 0) {
      console.log("Startups Found Successfully");
      this.setState({
        startupsTable: investors, 		loader: false, 		isRecent: true, 		

      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  handleGetStartups = async () => {
    let RC = false;
    this.setState({ loader: true, startupsTable: [] });

    let investors = await getStartupsAPI();
    if (investors != undefined && investors.length > 0) {
      console.log("Startups Found Successfully");
      this.setState({
        startupsTable: investors,         loader: false,         isRecent: false,       });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  viewStartup = (ID) => {
    this.props.history.push("/app/editstartup/"+ID);
  };


}
